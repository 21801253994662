import { Stack } from '@chakra-ui/layout';
import type { FunctionComponent } from 'react';
import { useQuotationState } from '../../hooks/useQuotationState';
import { useAuthentication } from '../../providers/AuthenticationProvider';
import { useAuthorization } from '../../providers/AuthorizationProvider';
import { UserRole } from '../../types';
import { routes } from '../../utils/routes';
import {
  BoatIcon,
  HomeIcon,
  LCLIcon,
  LocalIcon,
  MasterDataIcon,
  OrganizationIcon,
  PlaneIcon,
  ProfileIcon,
  QuotationIcon,
  ThirdPartyIcon,
  TruckIcon,
  UserIcon,
} from '../Icons';
import { NavDivider } from './NavDivider';
import { NavLink } from './NavLink';

export const NavItems: FunctionComponent = () => {
  const { user } = useAuthentication();
  const { hasRole } = useAuthorization();

  const { initQuotationPage } = useQuotationState();

  return (
    <>
      <Stack spacing="0.5rem">
        <NavDivider />
        <NavLink
          title="Home"
          to={routes.base}
          icon={<HomeIcon w="6" h="6" />}
        />
        <NavLink
          title="Internal Quotation"
          to={routes.quotations}
          icon={<QuotationIcon w="6" h="6" />}
          onClick={() => {
            initQuotationPage();
          }}
        />
        {hasRole([
          UserRole.ORGANIZATION_MANAGER,
          UserRole.REGION_MANAGER,
          UserRole.RATE_MANAGER,
          UserRole.STANDARD_USER,
        ]) && (
          <>
            <NavDivider label="Rates" />
            <Stack>
              <NavLink
                title="Local"
                to={routes.charges.local}
                icon={<LocalIcon w="6" h="6" />}
              />
              <NavLink
                title="Trucking"
                to={routes.charges.trucking}
                icon={<TruckIcon w="6" h="6" />}
              />
              {/*<NavLink*/}
              {/*  title="Trucking (Old)"*/}
              {/*  to={routes.charges.truckingOld}*/}
              {/*  icon={<TruckIcon w="6" h="6" />}*/}
              {/*/>*/}
              <NavLink
                title="Air"
                to={routes.charges.air}
                icon={<PlaneIcon w="6" h="6" />}
              />
              <NavLink
                title="LCL"
                to={routes.charges.lcl}
                icon={<LCLIcon w="6" h="6" />}
              />
              <NavLink
                title="FCL"
                to={routes.charges.fcl}
                icon={<BoatIcon w="6" h="6" />}
              />
            </Stack>
          </>
        )}
        <NavDivider label="Administration" />
        <Stack>
          <NavLink
            title="Your Account"
            to={routes.account}
            icon={<ProfileIcon w="6" h="6" />}
          />
          {hasRole([
            UserRole.GLOBAL_ADMINISTRATOR,
            UserRole.ORGANIZATION_MANAGER,
            UserRole.REGION_MANAGER,
          ]) && (
            <NavLink
              title="User Management"
              to={routes.userManagement.base}
              icon={<UserIcon w="6" h="6" />}
            />
          )}
          {user?.organization?.organizationID != null &&
            user.organization.organizationID !== '' && (
              <NavLink
                title="Your Organization"
                to={routes.organizations.details(
                  user.organization.organizationID,
                )}
                icon={<OrganizationIcon w="6" h="6" />}
              />
            )}
          {hasRole([UserRole.GLOBAL_ADMINISTRATOR]) && (
            <>
              <NavLink
                title="Organizations"
                to={routes.organizations.base}
                icon={<OrganizationIcon w="6" h="6" />}
              />
              <NavLink
                title="Master Data"
                to={routes.masterData}
                icon={<MasterDataIcon w="6" h="6" />}
              />
            </>
          )}
          {hasRole([UserRole.ORGANIZATION_MANAGER]) && (
            <NavLink
              title="Third Party API"
              to={routes.thirdPartyApi.base}
              icon={<ThirdPartyIcon w="6" h="6" />}
            />
          )}
        </Stack>
      </Stack>
    </>
  );
};
