import { CheckIcon } from '@chakra-ui/icons';
import { VStack } from '@chakra-ui/layout';
import { Button, Flex, FormLabel, Heading, HStack } from '@chakra-ui/react';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import type { UseQueryResult } from 'react-query';
import type { Column, FilterProps } from 'react-table';
import { DataTable } from '../../../components/DataTable';
import { BoolColumnFilter } from '../../../components/DataTable/BoolColumnFilter';
import { ResetAllColumnsFilter } from '../../../components/DataTable/ResetAllColumnsFilter';
import { SelectColumnFilter } from '../../../components/DataTable/SelectColumnFilter';
import { EditIcon } from '../../../components/Icons';
import { SelectInputOption } from '../../../components/Input';
import { MultiSelect } from '../../../components/Input/MultiSelect';
import { TableActionButtonProps } from '../../../theme/components/Button';
import type { ChargeCode, Country, Currency, Port, System } from '../../../types';
import { MasterData, MasterDataType, SelectedSystem } from '../../../types/MasterData';

interface MasterDataTableProps {
  masterDataType: MasterDataType,
  onEditMasterData: (masterData: MasterDataType, item: MasterData) => void,
  ports: UseQueryResult<Port[]>,
  currencies: UseQueryResult<Currency[]>,
  countries: UseQueryResult<Country[]>,
  chargeCodes: UseQueryResult<ChargeCode[]>,
  systems: UseQueryResult<System[]>,
  onChargeCodesSelectedSystemChanged: (system: SelectedSystem) => void
}

const mapPortType = (portType: number) => {
  switch (portType) {
    case 1:
      return 'Sea';
    case 4:
      return 'Air';
    default:
      return 'Undefined';
  }
};

export const MasterDataTable: FunctionComponent<MasterDataTableProps> = ({
                                                                           masterDataType,
                                                                           onEditMasterData,
                                                                           ports,
                                                                           currencies,
                                                                           countries,
                                                                           chargeCodes,
                                                                           systems,
                                                                           onChargeCodesSelectedSystemChanged,
                                                                         }) => {
  const actionColumnAccessor = (onClick: CallableFunction) => (
    <Flex justifyContent="flex-end" w="100%">
      <Button
        {...TableActionButtonProps}
        onClick={() => onClick()}
        rightIcon={<EditIcon w="4" h="4" />}
      >
        Edit
      </Button>
    </Flex>
  );

  const countryColumns = useMemo<Column<Country>[]>(
    () => [
      {
        Header: 'Name',
        accessor: (item) => item.name,
      },
      { Header: 'Code', accessor: (item) => item.code },
      {
        Header: 'Region',
        accessor: (item) => item.region?.name,
        Filter: (columns: FilterProps<Country>) => (
          <SelectColumnFilter
            column={columns.column}
            alternativeDataSource={countries.data ?? []}
            alternativeDataSourceAccessor="region"
            alternativeDataSourceTransformer={(data) => data?.name}
          />
        ),
      },
      {
        Header: 'Active',
        accessor: (item) => (item.isActive ? <CheckIcon /> : null),
        Filter: BoolColumnFilter,
      },
      {
        id: 'Action',
        accessor: (item) =>
          actionColumnAccessor(() =>
            onEditMasterData(MasterDataType.COUNTRIES, item),
          ),
        Filter: ResetAllColumnsFilter,
      },
    ],
    [countries.data, onEditMasterData],
  );

  const currencyColumns = useMemo<Column<Currency>[]>(
    () => [
      { Header: 'Name', accessor: (item) => item.name },
      { Header: 'Code', accessor: (item) => item.code },
      {
        Header: 'Convertible',
        accessor: (item) => (item.isConvertible ? <CheckIcon /> : null),
        Filter: BoolColumnFilter,
      },
      {
        id: 'Action',
        accessor: (item) =>
          actionColumnAccessor(() =>
            onEditMasterData(MasterDataType.CURRENCIES, item),
          ),
        Filter: ResetAllColumnsFilter,
      },
    ],
    [onEditMasterData],
  );

  const portsColumns = useMemo<Column<Port>[]>(
    () => [
      {
        Header: 'Name',
        accessor: (item) => item.name,
      },
      { Header: 'IATA Code', accessor: (item) => item.iataCode },
      { Header: 'UNLO Code', accessor: (item) => item.unloCode },
      { Header: 'Location Code', accessor: (item) => item.locationCode },
      {
        Header: 'Type',
        accessor: (item) => mapPortType(item.portType),
        Filter: (columns: FilterProps<Port>) => (
          <SelectColumnFilter
            column={columns.column}
            alternativeDataSource={ports.data ?? []}
            alternativeDataSourceAccessor="portType"
            alternativeDataSourceTransformer={mapPortType}
          />
        ),
      },
      { Header: 'City', accessor: (item) => item.city },
      {
        Header: 'Active',
        accessor: (item) => (item.isActive ? <CheckIcon /> : null),
        Filter: BoolColumnFilter,
      },
      {
        id: 'Action',
        accessor: (item) =>
          actionColumnAccessor(() =>
            onEditMasterData(MasterDataType.PORTS, item),
          ),
        Filter: ResetAllColumnsFilter,
      },
    ],
    [onEditMasterData, ports.data],
  );

  const [currentSystem, setCurrentSystem] = useState<SelectedSystem|undefined>();

  const defaultSystemCode = useMemo(() => systems.data?.[0], [systems]);

  useEffect(() => {
    onChargeCodesSelectedSystemChanged({
      name: defaultSystemCode?.name!,
      code: defaultSystemCode?.code!
    })

    setCurrentSystem(defaultSystemCode)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSystemCode]); // eslint disable safe because onChargeCodesSelectedSystemChanged doesn't use other props

  const chargeCodesColumns = useMemo<Column<ChargeCode>[]>(
    () => [
      { Header: 'Code', accessor: (item) => item.code },
      { Header: 'Description', accessor: (item) => item.name },
      {
        Header: `${currentSystem?.name} Code`,
        accessor: (item) =>
          // eslint-disable-next-line
          item.chargeCodeMappings?.find(
            (mapping) => mapping.system.code === currentSystem?.code,
          )?.systemChargeCode.code ?? '-',
      },
      {
        Header: 'Active',
        accessor: (item) => (item.isActive ? <CheckIcon /> : null),
        Filter: BoolColumnFilter,
      },
      {
        id: 'Action',
        accessor: (item) =>
          actionColumnAccessor(() =>
            onEditMasterData(MasterDataType.CHARGE_CODES, item),
          ),
        Filter: ResetAllColumnsFilter,
      },
    ],
    [onEditMasterData, currentSystem],
  );

  /**
   * Helper function to assign initial length of the individual Tables
   * @param array objects that fill the table
   */
  const assign11or10 = (array: object[] | undefined): number => array?.length === 11 ? 11 : 10;

  /**
   * Keeps track of pageSize of individual Tables
   */
  const [pageSizes, setPageSizes] = useState<(number | undefined)[]>(() => [
    assign11or10(countries.data),
    assign11or10(currencies.data),
    assign11or10(ports.data),
    assign11or10(chargeCodes.data),
  ]);

  const setPageSize = (ordinal: MasterDataType): (size: number) => void => (size: number) => {
    const curentStateCopy = [...pageSizes];
    curentStateCopy[ordinal] = size;
    setPageSizes(curentStateCopy);
  };

  //Needed to pass down the value as react-hook-Tables internal lifecicle has some weird optimization
  //that breaks the normal one way binding we are used to so state has to be retreived from the parent component
  //this way
  const getPageSize = (ordinal: MasterDataType): () => number | undefined => () => pageSizes[ordinal];


  switch (masterDataType) {
    case MasterDataType.COUNTRIES:
      return (
        <>
          <HStack mt="4" justifyContent="space-between">
            <Heading fontSize="md">{`${
              countries.data?.length ?? 0
            } Countries`}</Heading>
          </HStack>
          <DataTable<Country>
            data={countries.data ?? []}
            isLoading={countries.isLoading}
            columns={countryColumns}
            fetchFailed={false}
            variant="sticky"
            enableFilters={true}
            showLoadAllButton={true}
            updatePageSize={setPageSize(MasterDataType.COUNTRIES)}
            sizeOfPage={getPageSize(MasterDataType.COUNTRIES)}
          />
        </>
      );
    case MasterDataType.CURRENCIES:
      return (
        <>
          <HStack mt="4" justifyContent="space-between">
            <Heading fontSize="md">{`${
              currencies.data?.length ?? 0
            } Currencies`}</Heading>
          </HStack>
          <DataTable<Currency>
            data={currencies.data ?? []}
            isLoading={currencies.isLoading}
            columns={currencyColumns}
            fetchFailed={false}
            variant="sticky"
            enableFilters={true}
            showLoadAllButton={true}
            updatePageSize={setPageSize(MasterDataType.CURRENCIES)}
            sizeOfPage={getPageSize(MasterDataType.CURRENCIES)}
          />
        </>
      );
    case MasterDataType.PORTS:
      return (
        <>
          <HStack mt="4" justifyContent="space-between">
            <Heading fontSize="md">{`${
              ports.data?.length ?? 0
            } Ports`}</Heading>
          </HStack>
          <DataTable<Port>
            data={ports.data ?? []}
            isLoading={ports.isLoading}
            columns={portsColumns}
            fetchFailed={false}
            variant="sticky"
            enableFilters={true}
            showLoadAllButton={true}
            updatePageSize={setPageSize(MasterDataType.PORTS)}
            sizeOfPage={getPageSize(MasterDataType.PORTS)}
          />
        </>
      );
    case MasterDataType.CHARGE_CODES:
      return (
        <>
          <HStack mt="4" justifyContent="space-between">
            <Heading fontSize="md">{`${
              chargeCodes.data?.length ?? 0
            } Charge Codes`}</Heading>
          </HStack>
          <VStack alignItems="flex-start">
            <FormLabel>System</FormLabel>
            <MultiSelect
              currentValue={false}
              isMulti={false}
              isClearable={false}
              isDisabled={false}
              onChange={(value) => {
                const selectedOption = value as SelectInputOption;
                setCurrentSystem({ name: selectedOption.label, code: selectedOption.value });
                onChargeCodesSelectedSystemChanged({ name: selectedOption.label, code: selectedOption.value });
              }}
              error={false}
              aria-label={'currentSystem'}
              value={{
                label: currentSystem ? systems.data?.find(s => s.code === currentSystem?.code)?.name : defaultSystemCode?.name,
                value: currentSystem ? systems.data?.find(s => s.code === currentSystem?.name)?.code : defaultSystemCode?.code,
              }}
              options={systems.data?.map((sys) => ({
                label: sys.name,
                value: sys.code,
              }))}
            />
          </VStack>
          <DataTable<ChargeCode>
            data={chargeCodes.data ?? []}
            isLoading={chargeCodes.isLoading}
            columns={chargeCodesColumns}
            fetchFailed={false}
            variant="sticky"
            enableFilters={true}
            showLoadAllButton={true}
            updatePageSize={setPageSize(MasterDataType.CHARGE_CODES)}
            sizeOfPage={getPageSize(MasterDataType.CHARGE_CODES)}
          />
        </>
      );
  }
};
