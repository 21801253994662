import { chakra, HTMLChakraProps } from '@chakra-ui/system';

export const PlaneIcon = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg viewBox="0 0 24 24" {...props}>
    <g
      id="Icons/Plane"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M21.5,16 L21.5,14 L13.5,9 L13.5,3.5 C13.5,2.67 12.83,2 12,2 C11.17,2 10.5,2.67 10.5,3.5 L10.5,9 L2.5,14 L2.5,16 L10.5,13.5 L10.5,19 L8.5,20.5 L8.5,22 L12,21 L15.5,22 L15.5,20.5 L13.5,19 L13.5,13.5 L21.5,16 Z"
        id="Path"
        fill="currentColor"
        fillRule="nonzero"
      ></path>
    </g>
  </chakra.svg>
);
