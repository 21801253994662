import { extendTheme, theme as baseTheme } from '@chakra-ui/react';
import { colors } from './colors';
import {
  Button,
  Heading,
  Table,
  Text,
  Input,
  Radio,
  Select,
  Form,
  FormLabel,
  ListHeading,
  Link,
  Tabs,
  Modal,
} from './components';
import { fonts } from './fonts';

export const theme = extendTheme(
  {
    fonts,
    colors,
    styles: {
      global: {
        body: {
          color: 'grey.100',
        },
        a: {
          color: 'blue',
        },
      },
    },
    components: {
      Button,
      Heading,
      Text,
      Table,
      Tabs,
      Input,
      Modal,
      Radio,
      Select,
      Form,
      FormLabel,
      ListHeading,
      Link,
    },
  },
  baseTheme,
);
