import { useMemo } from 'react';
import ReactSelect from 'react-select';
import { ColumnInstance } from 'react-table';
import { useReactSelectStylings } from '../../hooks/useReactSelectStylings';

interface BoolColumnFilterProps<T extends object> {
  column: ColumnInstance<T>;
}

interface SelectOption {
  value: string;
  label: string;
}

export const BoolColumnFilter = <T extends object>({
  column,
}: BoolColumnFilterProps<T>) => {
  const styles = useReactSelectStylings('100%', column.filterValue);

  const options: SelectOption[] = useMemo(
    () => [
      { label: 'True', value: 'object' },
      { label: 'False', value: 'null' },
    ],
    [],
  );

  return (
    <ReactSelect
      id={column.id}
      styles={styles}
      options={options}
      value={options.filter((option) => option.value === column.filterValue)}
      onChange={(value) => {
        column.setFilter((value as SelectOption)?.value ?? undefined);
      }}
      isClearable={true}
      placeholder="Filter"
    />
  );
};
