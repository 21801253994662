import { FunctionComponent } from 'react';
import { ActionModal } from '../ActionModal';

interface CancelModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onAction: () => void;
}

export const CancelModal: FunctionComponent<CancelModalProps> = ({
  isOpen,
  onCancel,
  onAction,
}) => {
  const handleOnCancel = () => {
    onCancel();
  };
  const handleActionCancel = () => {
    onAction();
  };

  return (
    <ActionModal
      onCancel={handleOnCancel}
      onActionClick={handleActionCancel}
      isOpen={isOpen}
      modalHeader="Cancel edit mode"
      modalText="Are you sure you want to cancel the edit mode? All changes will be discarded."
      actionButtonText="Yes"
      actionButtonColor="red"
    />
  );
};
