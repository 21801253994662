import { ComponentStyleConfig } from '@chakra-ui/react';

export const Text: ComponentStyleConfig = {
  baseStyle: {
    fontSize: '0.875rem',
  },
  variants: {
    navlink: {
      fontWeight: 'bold',
    },
    tableContent: {
      fontSize: '0.75rem',
    },
    warningBox: {
      color: 'white',
    },
  },
};
