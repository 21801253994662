import { InfoIcon } from '@chakra-ui/icons';
import { Box, HStack } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

export const WarningBox: FunctionComponent = ({ children }) => (
  <Box>
    <HStack
      width="full"
      borderColor="orange !important"
      borderRadius="8"
      border="1px"
      padding="4"
      fontSize="small"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <InfoIcon mt="1" width="4" height="4" color="orange" />
      {children}
    </HStack>
  </Box>
);
