import { Heading } from './Heading';

export const FormLabel = {
  baseStyle: {
    fontSize: 'xs',
    color: 'grey.300',
  },
  variants: {
    heading: {
      fontSize: 'md',
      ...Heading.baseStyle,
    },
  },
};
