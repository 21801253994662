import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';
import type { FormLabelProps, RadioProps } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import { Control, Controller } from 'react-hook-form';
import type { FormInputProps } from './FormInput';

interface RadioOption {
  label: string;
  value: string | number;
}

interface RadioFormInputProps
  extends Omit<FormInputProps, 'register' | 'errors'>,
    RadioProps {
  control: Control<any>;
  labelProps?: FormLabelProps;
  options: RadioOption[];
  setValueAs?: (value: string | number) => string | number;
}

export const RadioFormInput: FunctionComponent<RadioFormInputProps> = ({
  control,
  label,
  accessor,
  isRequired,
  labelProps,
  options,
  setValueAs,
}) => (
  <Controller
    control={control}
    name={accessor}
    render={({ field: { onChange, ...restField }, fieldState: { error } }) => (
      <FormControl isInvalid={error ? true : false} isRequired={isRequired}>
        <FormLabel htmlFor={accessor} {...labelProps}>
          {label}
        </FormLabel>
        <RadioGroup
          id={accessor}
          {...restField}
          onChange={(value) => onChange(setValueAs ? setValueAs(value) : value)}
        >
          <Stack direction="row" h="2.5rem" spacing={{ base: '4', xl: '8' }}>
            {options.map((option) => (
              <Radio
                key={option.value}
                value={option.value}
                colorScheme="blue"
                size="sm"
              >
                {option.label}
              </Radio>
            ))}
          </Stack>
        </RadioGroup>
        <FormErrorMessage>{error && error.message}</FormErrorMessage>
      </FormControl>
    )}
  />
);
