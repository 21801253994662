import { Country, UserRole } from '../../../types';

export const checkCanMutateRates = (
  userRole: UserRole,
  userCountries: Country[],
  countriesToCheck: (string | undefined)[],
) =>
  userRole !== UserRole.STANDARD_USER &&
  userCountries.some((country) =>
    countriesToCheck.some((id) => id != null && id === country.countryID),
  );
