import { TariffRateDto } from '../../../../types/api/TariffRateDto';
import { EMPTY_BRACKET } from '../constants/emptyBracket';
import { EMPTY_CELL } from '../constants/emptyCell';
import { ZIP_CODE_SEPARATOR } from '../constants/tariffConstants';

// TODO: There is an unhandled error, for when `tariffRatesSorted` come as 'null' from BackEnd.
// They should never come as null, but if that happens, it should be prevented from being used as defaultValue for the table
export const getEmptyRows = (
  columnCount: number,
  rowCount: number,
  savedForm?: Record<string, unknown>,
): TariffRateDto[][] => {
  const tempRows = [];
  for (let i = 0; i < rowCount; i += 1) {
    const tempCells = [];
    const adjustedColumns = columnCount;
    let prefillRangeFrom: string = '';
    let prefillRangeTo: string = '';
    if (savedForm) {
      const formMatch = savedForm[`zone-${i}`] as string;
      if (formMatch) {
        const zoneValues = formMatch
          .split(ZIP_CODE_SEPARATOR)
          .map((item) => item.trim());
        prefillRangeFrom = zoneValues[0];
        prefillRangeTo = zoneValues[1];
      }
    }

    for (let j = 0; j < adjustedColumns; j += 1) {
      let prefillValue: number = 0;
      if (savedForm) {
        const formMatch = savedForm[`${j}-${i}`];
        prefillValue = formMatch as number;
      }
      tempCells.push({
        ...EMPTY_CELL,
        bracketWeight: {
          ...EMPTY_BRACKET,
          order: j,
          isMinValue: j === 0,
          value: prefillValue,
        },
        bracketTransportMethod: {
          ...EMPTY_CELL.bracketTransportMethod,
          rangeTo: prefillRangeTo,
          rangeFrom: prefillRangeFrom,
        },
        value: 0,
      });
    }
    tempRows.push(tempCells);
  }
  return tempRows;
};
