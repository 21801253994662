import {ChevronLeftIcon} from '@chakra-ui/icons';
import {Heading, StackProps} from '@chakra-ui/layout';
import {Button, HStack} from '@chakra-ui/react';
import type {FunctionComponent, ReactNode} from 'react';
import {Card} from './Card';

interface HeadingCardProps extends StackProps {
    heading: string | ReactNode;
    onBack?: () => void;
}

export const HeadingCard: FunctionComponent<HeadingCardProps> = ({
                                                                     children,
                                                                     heading,
                                                                     onBack,
                                                                     ...props
                                                                 }) => (
    <Card py="1.625rem" {...props}>
            <Heading as="h1" variant="h1" flexGrow={1}>
                {typeof onBack !== 'undefined' && (
                    <Button
                        onClick={onBack}
                        variant="outline"
                        size="xs"
                        mr="1rem"
                        leftIcon={<ChevronLeftIcon h="4" w="4"/>}
                    >
                        Back
                    </Button>
                )}
                {heading}
            </Heading>
            <HStack justifyContent="flex-end">
                {children}
            </HStack>
    </Card>
);
