import { chakra, HTMLChakraProps } from '@chakra-ui/system';

export const OrganizationIcon = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg viewBox="0 0 24 24" {...props}>
    <g
      id="Icons/Customer"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M12,7 L12,5 C12,3.9 11.1,3 10,3 L4,3 C2.9,3 2,3.9 2,5 L2,19 C2,20.1 2.9,21 4,21 L20,21 C21.1,21 22,20.1 22,19 L22,9 C22,7.9 21.1,7 20,7 L12,7 Z M6,19 L4,19 L4,17 L6,17 L6,19 Z M6,15 L4,15 L4,13 L6,13 L6,15 Z M6,11 L4,11 L4,9 L6,9 L6,11 Z M6,7 L4,7 L4,5 L6,5 L6,7 Z M10,19 L8,19 L8,17 L10,17 L10,19 Z M10,15 L8,15 L8,13 L10,13 L10,15 Z M10,11 L8,11 L8,9 L10,9 L10,11 Z M10,7 L8,7 L8,5 L10,5 L10,7 Z M19,19 L12,19 L12,17 L14,17 L14,15 L12,15 L12,13 L14,13 L14,11 L12,11 L12,9 L19,9 C19.55,9 20,9.45 20,10 L20,18 C20,18.55 19.55,19 19,19 Z M18,11 L16,11 L16,13 L18,13 L18,11 Z M18,15 L16,15 L16,17 L18,17 L18,15 Z"
        id="Shape"
        fill="currentColor"
      ></path>
    </g>
  </chakra.svg>
);
