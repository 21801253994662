import { generateUniqueID } from 'web-vitals/dist/lib/generateUniqueID';
import { TariffRateDto } from '../../../../types/api/TariffRateDto';
import { ZIP_CODE_SEPARATOR } from '../constants/tariffConstants';

export const getSortedRateRows = (
  ratesSorted: TariffRateDto[][],
  displayMode = true,
) => {
  const tempRows: Record<string, string>[] = [];
  ratesSorted.forEach((cellArray) => {
    const dynamicRow: Record<string, string> = {
      zone: '',
    };
    cellArray.forEach((cell) => {
      if (!dynamicRow.zone && cell?.bracketTransportMethod) {
        dynamicRow.zone = `${cell?.bracketTransportMethod.rangeFrom}${
          displayMode ? ' - ' : ZIP_CODE_SEPARATOR
        }${cell?.bracketTransportMethod.rangeTo}`;
      }
      const cellKey =
        cell?.bracketWeight?.bracketWeightID || generateUniqueID();
      dynamicRow[cellKey] = cell?.value?.toString() || '';
    });
    tempRows.push(dynamicRow);
  });
  return tempRows;
};
