import { Divider, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import React, { FunctionComponent, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
  DateFormInput,
  FormInput,
  MultiSelectFormInput,
  NumberFormInput,
  SelectFormInput,
} from '../../../../components/Input';
import { Card } from '../../../../components/Layout';
import { Country, Port, TransportMode } from '../../../../types';
import { CalculatorTariff } from '../../../../types/CalculatorTariff';
import { MethodTariff } from '../../../../types/MethodTariff';
import { MethodTariffAbbreviation } from '../../../../types/MethodTariffAbbreviation';
import { MultiselectOption } from '../../../../types/MultiSelectOption';
import { ApiTransportDirection } from '../../../../types/TransportDirection';
import { CreateTariffMetaValues } from '../../../../types/forms/CreateTariffMetaValues';
import { getKeyByStringValue } from '../../../../utils/getKeyByStringValue';
import { findPortsByIdList } from '../../../../utils/model/findPortByID';
import { formatAirportsToOptions } from '../../../../utils/model/formatAirportsToOptions';
import { formatPortsToOptions } from '../../../../utils/model/formatPortsToOptions';

interface TariffMetaFormProps {
  ports: MultiselectOption[];
  portsList: Port[] | undefined;
  airports: MultiselectOption[];
  airportsList: Port[] | undefined;
  currencies: MultiselectOption[];
  transportType: TransportMode;
  selectedCountry: Country | undefined;
  formInstance: UseFormReturn<CreateTariffMetaValues, any>;
  initialFormValues: Partial<CreateTariffMetaValues>;
  lockRatio?: boolean;
  lockCalculatedMethods?: boolean;
}

export const TariffMetaEditForm: FunctionComponent<TariffMetaFormProps> = ({
  ports,
  airports,
  currencies,
  transportType,
  selectedCountry,
  formInstance,
  portsList,
  airportsList,
  initialFormValues,
  lockRatio = false,
  lockCalculatedMethods = false,
}) => {
  const [canAssignAirports, setCanAssignAirports] = useState(
    transportType === TransportMode.Air,
  );
  const [canAssignPorts, setCanAssignPorts] = useState(
    transportType === TransportMode.LCL,
  );

  const methodOptions = () => {
    const availableMethodOptions: MultiselectOption[] = [
      {
        label: 'DIS',
        value: MethodTariff.DistanceZones,
      },
    ];
    if (selectedCountry?.supportedTruckingMethods) {
      selectedCountry?.supportedTruckingMethods.forEach((countryMethod) => {
        if (countryMethod === MethodTariff['2']) {
          availableMethodOptions.push({
            label: 'ZIP',
            value: MethodTariff.ZipCodes,
          });
        }
      });
    }
    return availableMethodOptions;
  };

  const initialPortOptions = formatPortsToOptions(
    findPortsByIdList(portsList, initialFormValues.assignedPorts || []),
  );
  const initialAirportOptions = formatAirportsToOptions(
    findPortsByIdList(airportsList, initialFormValues.assignedAirports || []),
  );

  const handleTransportChange = (value: TransportMode[]) => {
    setCanAssignAirports(value[0] === TransportMode.Air);
    setCanAssignPorts(value[0] === TransportMode.LCL);
    if (value[0] === TransportMode.Air) {
      formInstance.setValue('assignedPorts', []);
    }
    if (value[0] === TransportMode.LCL) {
      formInstance.setValue('assignedAirports', []);
    }
  };

  const watchedTransportType = formInstance.watch(['transportType']);

  React.useEffect(() => {
    handleTransportChange(watchedTransportType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedTransportType]);

  const getEnumOption = (initialValue: string | number, targetEnum: any) =>
    initialValue
      ? {
          label: getKeyByStringValue(targetEnum, initialValue),
          value: initialValue,
        }
      : undefined;

  const defaultTransportType = getEnumOption(
    initialFormValues?.transportType || 0,
    TransportMode,
  );
  const defaultMethod = initialFormValues.method
    ? {
        label:
          MethodTariffAbbreviation[
            getKeyByStringValue(
              MethodTariff,
              initialFormValues.method,
            ) as keyof typeof MethodTariffAbbreviation
          ],
        value: initialFormValues.method,
      }
    : undefined;

  const defaultCurrency = {
    label: initialFormValues?.currency,
    value: initialFormValues?.currency,
  };

  const defaultDirections = initialFormValues.directions
    ? initialFormValues.directions.map((type) => ({
        label: getKeyByStringValue(ApiTransportDirection, type),
        value:
          ApiTransportDirection[
            (getKeyByStringValue(ApiTransportDirection, type) || 0) as any
          ],
      }))
    : undefined;

  return (
    <Card>
      <form>
        <Grid
          gap={{ base: 4, lg: 4 }}
          templateColumns={{
            base: 'repeat(1, 1fr)',
            lg: 'repeat(2, 1fr)',
            xl: 'repeat(3, 1fr)',
            '2xl': 'repeat(3, 1fr)',
          }}
        >
          <GridItem>
            <FormInput
              accessor="name"
              label="Tariff Name"
              register={formInstance.register}
              errors={formInstance.formState.errors}
              defaultValue={initialFormValues?.name}
              isDisabled={formInstance.formState.isSubmitting}
              isRequired={true}
              registerOptions={{
                maxLength: { value: 50, message: 'Maximum of 50 characters' },
              }}
              paddingInlineStart={'10px'}
            />
          </GridItem>
          <GridItem>
            <SelectFormInput
              label="Method"
              accessor="method"
              placeholder="Select..."
              defaultValue={defaultMethod}
              isRequired={true}
              isDisabled={lockCalculatedMethods}
              control={formInstance.control}
              options={methodOptions()}
            />
          </GridItem>
          <GridItem>
            <SelectFormInput
              label="Currency"
              accessor="currency"
              placeholder="Select..."
              isRequired={true}
              defaultValue={defaultCurrency}
              control={formInstance.control}
              options={currencies}
            />
          </GridItem>
          <GridItem>
            <MultiSelectFormInput
              label="Direction"
              accessor="directions"
              placeholder="Select..."
              isRequired={true}
              defaultValue={defaultDirections}
              control={formInstance.control}
              options={[
                { label: 'Import', value: ApiTransportDirection.Import },
                { label: 'Export', value: ApiTransportDirection.Export },
              ]}
            />
          </GridItem>
          <GridItem>
            <SelectFormInput
              label="Calculator"
              accessor="calculator"
              placeholder="Select..."
              isDisabled={lockCalculatedMethods}
              isRequired={true}
              control={formInstance.control}
              defaultValue={
                initialFormValues.calculator
                  ? {
                      label: CalculatorTariff[initialFormValues.calculator],
                      value: initialFormValues.calculator,
                    }
                  : undefined
              }
              options={[
                { label: 'FLT', value: CalculatorTariff.FLT },
                { label: 'MPU', value: CalculatorTariff.MPU },
              ]}
            />
          </GridItem>
          <GridItem>
            <DateFormInput
              label="Valid From"
              accessor="validFrom"
              placeholderText="Select Date"
              isRequired={true}
              onChange={(value) => {
                const date = value ?? new Date();
                formInstance.setValue('validFrom', date);
              }}
              selected={formInstance.watch('validFrom')}
              control={formInstance.control}
              paddingInlineStart={'10px'}
            />
          </GridItem>
          <GridItem>
            <SelectFormInput
              label="Transport Type"
              accessor="transportType"
              placeholder="Select..."
              isRequired={true}
              defaultValue={defaultTransportType}
              control={formInstance.control}
              options={[
                { label: 'Air', value: TransportMode.Air },
                { label: 'LCL', value: TransportMode.LCL },
              ]}
            />
          </GridItem>
          <GridItem
            colSpan={1}
            display={!canAssignAirports ? 'none' : 'inherit'}
          >
            <MultiSelectFormInput
              label="Assigned Airports"
              accessor="assignedAirports"
              placeholder="Select..."
              defaultValue={initialAirportOptions}
              isRequired={false}
              isDisabled={!canAssignAirports}
              control={formInstance.control}
              options={airports}
            />
          </GridItem>
          <GridItem colSpan={1} display={!canAssignPorts ? 'none' : 'inherit'}>
            <MultiSelectFormInput
              label="Assigned Ports"
              minMenuHeight={80}
              accessor="assignedPorts"
              placeholder="Select..."
              defaultValue={initialPortOptions}
              isRequired={false}
              isDisabled={!canAssignPorts}
              control={formInstance.control}
              options={ports}
            />
          </GridItem>
          <GridItem>
            <DateFormInput
              label="Valid To"
              accessor="validTo"
              placeholderText="Select Date"
              validate={(value: number | string | Date) =>
                formInstance.watch('validFrom') === undefined ||
                new Date(value).getTime() -
                  new Date(formInstance.watch('validFrom')).getTime() >
                  0 ||
                'Valid To must be after Valid From'
              }
              isRequired={true}
              onChange={(value) => {
                const date = value ?? new Date();
                formInstance.setValue('validTo', date);
              }}
              selected={formInstance.watch('validTo')}
              control={formInstance.control}
              paddingInlineStart={'10px'}
            />
          </GridItem>
        </Grid>
        <Flex pb="6" pt="6">
          <Divider borderColor="grey.900" borderWidth="2px" opacity="unset" />
        </Flex>
        <Grid
          gap={{ base: 8, lg: 4 }}
          templateColumns={{
            base: 'repeat(1, 1fr)',
            lg: 'repeat(3, 1fr)',
          }}
        >
          <GridItem>
            <NumberFormInput
              accessor="percentFuel"
              label="Fuel Surcharge"
              precision={0}
              step={1}
              control={formInstance.control}
              rightElement={<Text fontSize="xs">%</Text>}
            />
          </GridItem>
          <GridItem colSpan={2}>
            <Flex alignItems="flex-end">
              <NumberFormInput
                accessor="chargeableRatio"
                label="Chargeable Weight Ratio"
                precision={2}
                step={1}
                isRequired={true}
                isDisabled={lockRatio}
                control={formInstance.control}
                rightElement={
                  <Text marginLeft="-6" fontSize="xs">
                    cbm
                  </Text>
                }
              />
              {/*24px margin top*/}
              <Text mt="28px" mx="4" fontSize="2xl">
                =
              </Text>
              <NumberFormInput
                accessor="chargeableWeight"
                precision={2}
                step={1}
                isRequired={true}
                control={formInstance.control}
                rightElement={
                  <Text marginLeft="-6" fontSize="xs">
                    kg
                  </Text>
                }
              />
            </Flex>
          </GridItem>
        </Grid>
      </form>
    </Card>
  );
};
