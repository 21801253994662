import {
  FormControl,
  FormLabel,
  TextareaProps,
  Textarea,
  FormErrorMessage,
} from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import { RegisterOptions } from 'react-hook-form';

export interface TextareaFormInputProps {
  label?: string;
  register: any;
  errors?: any;
  accessor: string;
  isDisabled?: boolean;
  registerOptions?: RegisterOptions;
}

export interface ExtendedTextareaProps
  extends TextareaFormInputProps,
    TextareaProps {}

export const TextareaFormInput: FunctionComponent<ExtendedTextareaProps> = ({
  label,
  accessor,
  register,
  errors,
  isRequired,
  isDisabled,
  isReadOnly,
  registerOptions = {},
  ...rest
}) => (
  <FormControl isInvalid={errors[accessor]} isRequired={isRequired}>
    <FormLabel htmlFor={accessor}>{label}</FormLabel>
    <Textarea
      id={accessor}
      variant="outline"
      bg="grey.900"
      fontSize="xs"
      fontWeight="500"
      _focus={!isReadOnly && { borderColor: 'blue.500' }}
      borderRadius="8"
      borderColor="grey.500"
      disabled={isDisabled}
      isReadOnly={isReadOnly}
      _hover={!isReadOnly && { borderColor: 'grey.400' }}
      {...register(accessor, { required: isRequired, ...registerOptions })}
      {...rest}
    />
    {errors[accessor] && (
      <FormErrorMessage>{errors[accessor].message}</FormErrorMessage>
    )}
  </FormControl>
);
