import { getTariffBrackets } from '../../pages/ChargesPage/TariffCreateRatesPage/utils/getTariffBrackets';
import { formatChargeDate } from '../../pages/ChargesPage/utils/dateUtils';
import { Currency, Port, TransportMode } from '../../types';
import { CalculatorTariff } from '../../types/CalculatorTariff';
import { Data } from '../../types/Data';
import { TariffTrucking } from '../../types/api/TariffTrucking';
import { TariffTruckingDto } from '../../types/api/TariffTruckingDto';
import { CreateTariffMetaValues } from '../../types/forms/CreateTariffMetaValues';
import { findCurrencyByCode } from './findCurrencyByCode';
import { getCalculatorType } from './getCalculatorType';
import { getMethodType } from './getMethodType';

export const transformTariffTruckingDtoToModel = (
  metaForm: CreateTariffMetaValues,
  tariffTrucking: TariffTruckingDto,
  zoneValues: [string, any][],
  headerValues: [string, any][],
  cellValues: [string, any][],
  currencies: Data<Currency[]>,
  transportMode: TransportMode,
  direction: (string | null)[] | undefined,
  selectedPorts: Port[],
  selectedAirports: Port[],
): TariffTrucking => {
  const { weightBrackets, methodBrackets, sortedTariffRates } =
    getTariffBrackets(
      zoneValues,
      headerValues,
      cellValues,
      metaForm.calculator === CalculatorTariff.MPU,
      tariffTrucking,
    );
  const ratio = metaForm.chargeableWeight / metaForm.chargeableRatio;
  const ratioLabel = parseFloat((Math.round(ratio * 100) / 100).toFixed(2));
  return {
    tariffID: tariffTrucking.tariffID,
    name: metaForm.name,
    organization: tariffTrucking.organization,
    country: tariffTrucking.country,
    calculator: getCalculatorType(metaForm?.calculator || 0),
    method: getMethodType(metaForm?.method || 0),
    currency: findCurrencyByCode(currencies.data, metaForm?.currency || ''),
    validFrom: formatChargeDate(metaForm?.validFrom.toDateString() || ''),
    validTo: formatChargeDate(metaForm?.validTo.toDateString() || ''),
    isActive: tariffTrucking.isActive,
    createUser: tariffTrucking.createUser,
    createTimestamp: tariffTrucking.createTimestamp,
    lastEditUser: tariffTrucking.lastEditUser,
    lastEditTimestamp: tariffTrucking.lastEditTimestamp,
    transportMode: transportMode,
    direction: direction,
    percentFuel: metaForm?.percentFuel,
    chargeableWeightRatio: ratioLabel,
    assignedPorts: [...selectedPorts, ...selectedAirports],
    tariffRatesSorted: sortedTariffRates,
    weightBrackets: weightBrackets,
    methodBrackets: methodBrackets,
  };
};
