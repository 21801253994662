import { useNavigate } from 'react-router-dom';
import { useDecoratedApi } from '../../../../hooks/api/useDecoratedApi';
import { useSuccessToast } from '../../../../hooks/useSuccessToast';
import { HttpMethod } from '../../../../providers/ApiProvider';
import { TariffTruckingDto } from '../../../../types/api/TariffTruckingDto';
import { API_ROUTES } from '../../../../utils/apiRoutes';
import { routes } from '../../../../utils/routes';

export const useTariffDeactivate = () => {
  const successToast = useSuccessToast();

  const { sendRequest: sendDeleteRequest, loading: deleteLoading } =
    useDecoratedApi('Failed to update', HttpMethod.DELETE);

  const navigate = useNavigate();

  const deleteResource = (rate: TariffTruckingDto | null | undefined) => {
    if (!rate) {
      return;
    }
    sendDeleteRequest(API_ROUTES.tariffsTruckingSingle(rate.tariffID)).then(
      (response) => {
        if (response) {
          successToast({
            title: 'Successfully deactivated tariff',
          });
          navigate(routes.charges.trucking);
        }
      },
    );
  };
  return { deleteResource, deleteLoading };
};
