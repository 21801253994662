import { getTariffBrackets } from '../../pages/ChargesPage/TariffCreateRatesPage/utils/getTariffBrackets';
import { formatChargeDate } from '../../pages/ChargesPage/utils/dateUtils';
import { Country, Currency, Port, TransportMode } from '../../types';
import { CalculatorTariff } from '../../types/CalculatorTariff';
import { Data } from '../../types/Data';
import { TariffTrucking } from '../../types/api/TariffTrucking';
import { CreateTariffMetaValues } from '../../types/forms/CreateTariffMetaValues';
import { findCurrencyByCode } from './findCurrencyByCode';
import { getCalculatorType } from './getCalculatorType';
import { getMethodType } from './getMethodType';

export const transformCreateFormsToTariffTrucking = (
  metaForm: CreateTariffMetaValues,
  zoneValues: [string, any][],
  headerValues: [string, any][],
  cellValues: [string, any][],
  country: Country,
  currencies: Data<Currency[]>,
  transportMode: TransportMode,
  direction: (string | null)[] | undefined,
  selectedPorts: Port[],
  selectedAirports: Port[],
): TariffTrucking => {
  const { weightBrackets, methodBrackets, sortedTariffRates } =
    getTariffBrackets(
      zoneValues,
      headerValues,
      cellValues,
      metaForm.calculator === CalculatorTariff.MPU,
      undefined,
    );
  const ratio = metaForm.chargeableWeight / metaForm.chargeableRatio;
  const ratioLabel = parseFloat((Math.round(ratio * 100) / 100).toFixed(2));
  return {
    tariffID: '00000000-0000-0000-0000-000000000000',
    name: metaForm?.name || '',
    organization: null,
    country: country,
    calculator: getCalculatorType(metaForm?.calculator || 0),
    method: getMethodType(metaForm?.method || 0),
    currency: findCurrencyByCode(currencies.data, metaForm?.currency || ''),
    validFrom: formatChargeDate(metaForm?.validFrom.toDateString() || ''),
    validTo: formatChargeDate(metaForm?.validTo.toDateString() || ''),
    isActive: true,
    createUser: '00000000-0000-0000-0000-000000000000',
    createTimestamp: formatChargeDate(new Date().toDateString()),
    lastEditUser: '00000000-0000-0000-0000-000000000000',
    lastEditTimestamp: formatChargeDate(new Date().toDateString()),
    transportMode: transportMode,
    direction: direction,
    percentFuel: metaForm?.percentFuel,
    chargeableWeightRatio: ratioLabel,
    assignedPorts: [...selectedPorts, ...selectedAirports],
    tariffRatesSorted: sortedTariffRates,
    weightBrackets: weightBrackets,
    methodBrackets: methodBrackets,
  };
};
