import {
  Divider,
  FormLabel,
  Grid,
  GridItem,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import type { Control } from 'react-hook-form';
import { useQuery } from 'react-query';
import shallow from 'zustand/shallow';
import { useLocalFilters } from '../../../hooks/useLocalFilters';
import { useApi } from '../../../providers/ApiProvider';
import { useData } from '../../../providers/DataProvider';
import type { ChargeCode, Currency, LocalCharge, Port } from '../../../types';
import { getPortTypeFromTransportMode, TransportMode } from '../../../types';
import {
  NumberFormInput,
  SelectFormInput,
  SelectInputOption,
} from '../../Input';

export interface LocalChargeFormValues {
  chargeCode: ChargeCode;
  port: Port | undefined;
  currency: Currency;
  ratePerKg: string | null;
  ratePerCbm: string | null;
  ratePer20FeetContainer: string | null;
  ratePer40FeetContainer: string | null;
  ratePer40HCFeetContainer: string | null;
  min: string | null;
  flat: string | null;
  validFrom: string;
  validTo: string;
}

export interface FieldProps {
  control: Control<any>;
  register: (input: any) => void;
  getValues: (key?: string) => any;
}

interface LocalChargeFieldsProps extends FieldProps {
  rate: LocalCharge | undefined;
  setValue: any;
}

export const LocalChargeFields: FunctionComponent<LocalChargeFieldsProps> = ({
  control,
  setValue,
  getValues,
  rate,
}) => {
  const { getApi } = useApi();
  const { chargeCodes, countries } = useData();

  const { countryId, transport, category } = useLocalFilters(
    (state) => ({
      countryId: state.countryId,
      transport: state.transportMode,
      category: state.category,
    }),
    shallow,
  );

  const countryIdValue = getValues('country.countryID') ?? countryId;
  const transportValue = getValues('transportMode') ?? transport;
  const categoryValue = category ?? getValues('category');

  const { isLoading: isLoadingPorts, data: ports } = useQuery<
    Port[] | undefined
  >(['ports', countryId, transport], async () => {
    const portType = getPortTypeFromTransportMode(transport);

    if (countryId) {
      const result = await getApi(
        portType ? `ports?portType=${portType}` : 'ports',
      );

      if (result.ok) {
        const resultPorts = (await result.json()) as Port[];
        const filteredPorts = resultPorts.filter(
          (port) => countryId && port.country.countryID === countryId,
        );

        return filteredPorts;
      }
    }
  });

  const valueInputs = () => {
    if (transport === TransportMode.FCL) {
      const valueValidationMessage =
        'Please fill either flat or per container rate(s).';

      const noValuesFilled = () =>
        !getValues('flat') &&
        !getValues('ratePer20FeetContainer') &&
        !getValues('ratePer40FeetContainer') &&
        !getValues('ratePer40FeeratePer40HCFeetContainertContainer');

      return (
        <>
          <GridItem>
            <NumberFormInput
              label="Flat"
              accessor="flat"
              control={control}
              precision={2}
              step={1.0}
              validate={{
                validate: (v: any) => {
                  if (
                    noValuesFilled() ||
                    (v &&
                      (getValues('ratePer20FeetContainer') ||
                        getValues('ratePer40FeetContainer') ||
                        getValues('ratePer40HCFeetContainer')))
                  ) {
                    return valueValidationMessage;
                  }
                  return true;
                },
              }}
            />
          </GridItem>

          <GridItem>
            <NumberFormInput
              label="Per 20GP"
              accessor="ratePer20FeetContainer"
              control={control}
              precision={2}
              step={1.0}
              validate={{
                validate: (v: any) => {
                  if (noValuesFilled() || (v && getValues('flat'))) {
                    return valueValidationMessage;
                  }
                  return true;
                },
              }}
            />
          </GridItem>
          <GridItem>
            <NumberFormInput
              label="Per 40GP"
              accessor="ratePer40FeetContainer"
              control={control}
              precision={2}
              step={1.0}
              validate={{
                validate: (v: any) => {
                  if (noValuesFilled() || (v && getValues('flat'))) {
                    return valueValidationMessage;
                  }
                  return true;
                },
              }}
            />
          </GridItem>
          <GridItem>
            <NumberFormInput
              label="Per 40HC"
              accessor="ratePer40HCFeetContainer"
              control={control}
              precision={2}
              step={1.0}
              validate={{
                validate: (v: any) => {
                  if (noValuesFilled() || (v && getValues('flat'))) {
                    return valueValidationMessage;
                  }
                  return true;
                },
              }}
            />
          </GridItem>
        </>
      );
    }

    return (
      <>
        <GridItem>
          <NumberFormInput
            label="Flat"
            accessor="flat"
            control={control}
            precision={2}
            step={1.0}
            validate={{
              validate: (v: any) => {
                if (
                  !getValues('flat') &&
                  !getValues('min') &&
                  !getValues('ratePerKg') &&
                  !getValues('ratePerCbm')
                ) {
                  return 'Please fill either flat or min and rate(s).';
                }
                if (
                  v &&
                  (getValues('ratePerKg') ||
                    getValues('ratesPerCbm') ||
                    getValues('min'))
                ) {
                  return 'Please fill either flat or min and rate(s).';
                }
                return true;
              },
            }}
          />
        </GridItem>
        <GridItem>
          <NumberFormInput
            label="Min"
            accessor="min"
            control={control}
            precision={2}
            step={1.0}
            validate={{
              validate: (v: any) => {
                if (
                  !getValues('flat') &&
                  !getValues('min') &&
                  !getValues('ratePerKg') &&
                  !getValues('ratePerCbm')
                ) {
                  return 'Please fill either flat or min and rate(s).';
                }
                if (v && getValues('flat')) {
                  return 'Please fill either flat or min and rate(s).';
                }
                return true;
              },
            }}
          />
        </GridItem>
        <GridItem>
          <NumberFormInput
            label={
              transport === TransportMode.LCL ? 'Rate per 1000 Kg' : 'Rate'
            }
            accessor="ratePerKg"
            control={control}
            precision={2}
            step={1.0}
            validate={{
              validate: (v: any) => {
                if (
                  !getValues('flat') &&
                  !getValues('min') &&
                  !getValues('ratePerKg') &&
                  !getValues('ratePerCbm')
                ) {
                  return 'Please fill either flat or min and rate(s).';
                }
                if (!v && getValues('min') && !getValues('ratePerCbm')) {
                  return transport === TransportMode.LCL
                    ? 'Required when min and no other rate is filled.'
                    : 'Required when min is filled.';
                }
                if (v && getValues('flat')) {
                  return 'Please fill either flat or min and rate(s).';
                }
                return true;
              },
            }}
          />
        </GridItem>
        {transport === TransportMode.LCL && (
          <GridItem>
            <NumberFormInput
              label="Rate per CBM"
              accessor="ratePerCbm"
              control={control}
              precision={2}
              step={1.0}
              validate={{
                validate: (v: any) => {
                  if (
                    !getValues('flat') &&
                    !getValues('min') &&
                    !getValues('ratePerKg') &&
                    !getValues('ratePerCbm')
                  ) {
                    return 'Please fill either flat or min and rate(s).';
                  }
                  if (!v && getValues('min') && !getValues('ratePerKg')) {
                    return 'Required when min and no other rate is filled.';
                  }
                  if (v && getValues('flat')) {
                    return 'Please fill either flat or min and rate(s).';
                  }
                  return true;
                },
              }}
            />
          </GridItem>
        )}
      </>
    );
  };

  return (
    <Stack spacing="4">
      <Stack w={{ base: 'full', lg: '50%' }}>
        <SimpleGrid columns={2} rowGap="4">
          <FormLabel mb="0">Country</FormLabel>
          <Text fontSize="xs">
            {
              countries.data?.find(
                (country) => country.countryID === countryIdValue,
              )?.name
            }
          </Text>
          <FormLabel mb="0">Transport Type</FormLabel>
          <Text fontSize="xs">{TransportMode[transportValue]}</Text>
          <FormLabel mb="0">Category</FormLabel>
          <Text fontSize="xs">
            {categoryValue === 'Import' ? 'Destination' : 'Origin'}
          </Text>
        </SimpleGrid>
      </Stack>
      <Divider borderColor="grey.900" borderWidth="1px" opacity="unset" />
      <SelectFormInput
        label="Code"
        accessor="chargeCode.chargeCodeID"
        placeholder="Select Charge Code"
        isRequired={true}
        control={control}
        defaultValue={
          rate
            ? {
                label: `${rate.chargeCode.code} - ${rate.chargeCode.name}`,
                value: rate.chargeCode.chargeCodeID,
              }
            : undefined
        }
        controllerDefaultValue={rate?.chargeCode.chargeCodeID}
        onChange={(value) => {
          setValue(
            'chargeCode',
            chargeCodes.data?.filter(
              (chargeCode) =>
                (value as SelectInputOption).value === chargeCode.chargeCodeID,
            )[0],
          );
        }}
        options={
          chargeCodes.isLoading || !chargeCodes.data
            ? []
            : chargeCodes.data.map((chargeCode) => ({
                label: `${chargeCode.code} - ${chargeCode.name}`,
                value: chargeCode.chargeCodeID,
              }))
        }
      />
      <Grid
        gap={{ base: 8, lg: 16 }}
        templateColumns={{
          base: 'repeat(1, 1fr)',
          lg: 'repeat(2, 1fr)',
        }}
      >
        <GridItem>
          <SelectFormInput
            label="Port"
            accessor="port.portID"
            placeholder="Select Port"
            isRequired={false}
            isClearable={true}
            defaultValue={
              rate && rate.port
                ? {
                    label:
                      rate.port?.iataCode ||
                      rate.port?.unloCode ||
                      rate.port?.locationCode,
                    value: rate.port?.portID,
                  }
                : undefined
            }
            controllerDefaultValue={rate?.port?.portID}
            onChange={(value) => {
              if (value == null) {
                setValue('port', null);
              } else {
                setValue(
                  'port',
                  ports?.find(
                    (port) =>
                      (value as SelectInputOption)?.value === port.portID,
                  ),
                );
              }
            }}
            control={control}
            options={
              isLoadingPorts || !ports
                ? []
                : ports?.map((port) => ({
                    label: port.iataCode || port.unloCode || port.locationCode,
                    value: port.portID,
                  }))
            }
          />
        </GridItem>
      </Grid>
      <Stack>
        <Grid
          columnGap={{ base: 8, lg: 16 }}
          rowGap={{ base: 2, lg: 4 }}
          templateColumns={
            transport !== TransportMode.Air
              ? 'repeat(2, 1fr)'
              : 'repeat(3, 1fr)'
          }
        >
          {valueInputs()}
        </Grid>
        <Text color="grey.400" fontSize="sm">
          {transport !== TransportMode.FCL
            ? 'Please fill either flat or min and rate(s).'
            : 'Please fill either flat or per container rate(s).'}
        </Text>
      </Stack>
    </Stack>
  );
};
