import { chakra, HTMLChakraProps } from '@chakra-ui/system';

export const CargonerdsIcon = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg viewBox="0 0 16 16" {...props}>
    <polygon
      fill="currentColor"
      points="0 13.66 7.02 16 7.02 9.09 0 6.72 0 13.66"
    />
    <polygon
      fill="currentColor"
      points="8.98 9.09 8.98 16 16 13.66 16 6.72 8.98 9.09"
    />
    <path
      fill="currentColor"
      d="M17.53,3.9a.1.1,0,0,0-.09-.12L9.52,1.12l-2.34.79L1.6,3.78c-.06,0-.07.05-.07.11V5.82a.09.09,0,0,0,.08.11L9.52,8.6l7.94-2.67a.09.09,0,0,0,.07-.1Z"
      transform="translate(-1.53 -1.12)"
    />
  </chakra.svg>
);
