import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react';
import type { FunctionComponent, ReactNode } from 'react';
import { RegisterOptions } from 'react-hook-form';

export interface FormInputProps {
  label?: string;
  register: any;
  registerOptions?: RegisterOptions;
  errors?: any;
  accessor: string;
  isDisabled?: boolean;
  rightElement?: ReactNode;
  hideLabel?: boolean;
  hidden?: boolean;
}

export interface ExtendedInputProps extends FormInputProps, InputProps {}

export const FormInput: FunctionComponent<ExtendedInputProps> = ({
  label,
  accessor,
  register,
  errors,
  isRequired,
  isDisabled,
  isReadOnly,
  rightElement,
  maxWidth,
  width,
  registerOptions = {},
  hideLabel = false,
  hidden = false,
  ...rest
}) => (
  <FormControl
    isInvalid={errors[accessor]}
    isRequired={isRequired}
    display={hidden ? 'none' : 'inherit'}
  >
    <FormLabel htmlFor={accessor} display={hideLabel ? 'none' : 'inherit'}>
      {label}
    </FormLabel>
    <InputGroup maxWidth={maxWidth} width={width}>
      <Input
        id={accessor}
        variant="outline"
        bg="grey.900"
        fontSize="xs"
        fontWeight="500"
        _focus={!isReadOnly && { borderColor: 'blue.500' }}
        borderRadius="8"
        borderColor="grey.500"
        disabled={isDisabled}
        isReadOnly={isReadOnly}
        _hover={!isReadOnly && { borderColor: 'grey.400' }}
        onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
          e.preventDefault();
          e.target.select();
        }}
        {...register(accessor, { required: isRequired, ...registerOptions })}
        {...rest}
      />
      {rightElement && (
        <InputRightElement zIndex={0}>{rightElement}</InputRightElement>
      )}
    </InputGroup>
    <FormErrorMessage fontSize="xs" mb="2" color="red.300">
      {errors[accessor] && errors[accessor].message}
    </FormErrorMessage>
  </FormControl>
);
