import { Heading, HStack } from '@chakra-ui/react';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import type { Column } from 'react-table';
import shallow from 'zustand/shallow';
import { Card, MainCard, HeadingCard } from '../../../components/Layout';
import { RateTable } from '../../../components/RateTable';
import { useErrorToast } from '../../../hooks/useErrorToast';
import { useTruckingFiltersOld } from '../../../hooks/useTruckingFilterOld';
import { useApi } from '../../../providers/ApiProvider';
import { useAuthorization } from '../../../providers/AuthorizationProvider';
import {
  Currency,
  RateType,
  TransportMode,
  TruckingCharge,
} from '../../../types';
import { DistanceZone } from '../../../types/DistanceZone';
import { toDisplayDateFormat } from '../../../utils/formatter';
import { getHttpStatusDescription } from '../../../utils/httpStatus';
import { formatChargeDate } from '../utils/dateUtils';
import { checkCanMutateRates } from '../utils/ratePermissions';
import { TruckingChargeFilter } from './components/TruckingChargeFilter';
import { TruckingSurchargeSettings } from './components/TruckingSurchargeSettings';

export interface TruckingChargeFormValues {
  below100Kg: number;
  below300Kg: number;
  below500Kg: number;
  below1000Kg: number;
  below3000Kg: number;
  below5000Kg: number;
  currency: Currency;
  distanceZone: DistanceZone;
  minimumCharge: number;
  validFrom: string;
  validTo: string;
}

export interface TruckingChargeResponse
  extends Omit<TruckingCharge, 'validTo' | 'validFrom'> {
  validFrom: string;
  validTo: string;
}

const emptyRate: Omit<TruckingCharge, 'distanceZone'> = {
  below100Kg: null,
  below300Kg: null,
  below500Kg: null,
  below1000Kg: null,
  below3000Kg: null,
  below5000Kg: null,
  id: '',
  country: {
    code: '',
    countryID: '',
    name: '',
    isActive: false,
    region: {
      code: '',
      name: '',
      regionID: '',
      isActive: false,
    },
  },
  transportMode: TransportMode.Air,
  currency: {
    currencyID: '',
    code: '',
    isConvertible: false,
    name: '',
  },
  minimumCharge: null,
  createTimestamp: new Date(),
  createUser: '',
  validFrom: new Date(),
  validTo: new Date(),
};

export const TruckingOldPage: FunctionComponent = () => {
  const { userCountries, userRole } = useAuthorization();
  const [fetchFailed, setFetchFailed] = useState<boolean>(false);
  const { getApi, deleteApi, putApi, postApi } = useApi();
  const [statusCode, setStatusCode] = useState<number>(0);
  const toast = useErrorToast();

  const errorTitle = 'Saving your changes failed, please try again';

  const { setCountryId, countryId, reset, transportType } = useTruckingFiltersOld(
    (state) => ({
      countryId: state.countryId,
      setCountryId: state.setCountryId,
      reset: state.reset,
      transportType: state.transportType,
    }),
    shallow,
  );

  useEffect(() => {
    reset();
    setCountryId(userCountries[0]?.countryID ?? undefined);
  }, [reset, setCountryId, userCountries]);

  const { data: distanceZones } = useQuery<DistanceZone[] | undefined>(
    ['distanceZones'],
    async () => {
      const result = await getApi('distance-zones');

      if (result.ok) {
        return (await result.json()) as DistanceZone[];
      }
    },
    { refetchOnWindowFocus: false },
  );

  const {
    isLoading,
    data: rates,
    refetch: refetchRates,
  } = useQuery<TruckingCharge[]>(
    ['trucking-charges', countryId, transportType, distanceZones],
    async () => {
      setFetchFailed(false);
      if (countryId === undefined) return [];
      const result = await getApi(
        `rates/trucking?countryID=${countryId}&transportType=${transportType}`,
      );
      setStatusCode(result.status);
      if (result.ok) {
        const truckingRates = (await result.json()) as TruckingCharge[];
        let allCombinations: TruckingCharge[] = [];
        distanceZones
          ?.sort((a, b) => a.distanceFrom - b.distanceFrom)
          .forEach((distanceZone) => {
            const correspondingCharge = truckingRates.find(
              (rate) =>
                rate.distanceZone.distanceZoneID ===
                distanceZone.distanceZoneID,
            );

            const remainingProps = correspondingCharge ?? emptyRate;
            allCombinations.push({
              distanceZone,
              ...remainingProps,
            });
          });
        return allCombinations;
      }
      setFetchFailed(true);

      return [];
    },
  );

  const columns = useMemo<Column<TruckingCharge>[]>(
    () => [
      {
        Header: 'Distance from (km)',
        accessor: (rate) => rate.distanceZone.distanceFrom,
        width: '110',
      },
      {
        Header: 'Distance to (km)',
        accessor: (rate) => rate.distanceZone.distanceTo,
        width: '110',
      },
      {
        Header: 'Min',
        accessor: (rate) => rate.minimumCharge,
      },
      {
        Header: 'Below 100Kg',
        accessor: (rate) => rate.below100Kg,
      },
      {
        Header: 'Below 300Kg',
        accessor: (rate) => rate.below300Kg,
      },
      {
        Header: 'Below 500Kg',
        accessor: (rate) => rate.below500Kg,
      },
      {
        Header: 'Below 1000Kg',
        accessor: (rate) => rate.below1000Kg,
      },
      {
        Header: 'Below 3000Kg',
        accessor: (rate) => rate.below3000Kg,
      },
      {
        Header: 'Below 5000Kg',
        accessor: (rate) => rate.below5000Kg,
      },
      {
        Header: 'Currency',
        accessor: (rate) => rate.currency.code,
      },
      {
        Header: 'Valid From',
        accessor: (rate) =>
          rate.id === '' ? '' : toDisplayDateFormat(rate.validFrom),
      },
      {
        Header: 'Valid To',
        accessor: (rate) =>
          rate.id === '' ? '' : toDisplayDateFormat(rate.validTo),
      },
    ],
    [],
  );

  const onEdit = useCallback(
    async (submitData: TruckingChargeFormValues, rate?: TruckingCharge) => {
      if (!rate) {
        return false;
      }

      let response: Response;

      if (rate.id === '' && countryId) {
        const selectedCountry = userCountries.find(
          (country) => country.countryID === countryId,
        );

        const createRate: Omit<
          TruckingChargeResponse,
          'createUser' | 'createTimestamp' | 'id'
        > = {
          below100Kg: submitData.below100Kg,
          below300Kg: submitData.below300Kg,
          below500Kg: submitData.below500Kg,
          below1000Kg: submitData.below1000Kg,
          below3000Kg: submitData.below3000Kg,
          below5000Kg: submitData.below5000Kg,
          currency: submitData.currency,
          distanceZone: submitData.distanceZone,
          minimumCharge: submitData.minimumCharge,
          validFrom: formatChargeDate(submitData.validFrom),
          validTo: formatChargeDate(submitData.validTo),
          country: selectedCountry!,
          transportMode: transportType,
        };
        try {
          response = await postApi('rates/trucking', createRate);
        } catch (e) {
          toast({ title: errorTitle });
          return false;
        }
      } else {
        const updatedRate: TruckingChargeResponse = {
          ...rate,
          distanceZone: submitData.distanceZone,
          currency: submitData.currency,
          minimumCharge: submitData.minimumCharge,
          below100Kg: submitData.below100Kg,
          below300Kg: submitData.below300Kg,
          below500Kg: submitData.below500Kg,
          below1000Kg: submitData.below1000Kg,
          below3000Kg: submitData.below3000Kg,
          below5000Kg: submitData.below5000Kg,
          validFrom: formatChargeDate(submitData.validFrom),
          validTo: formatChargeDate(submitData.validTo),
          transportMode: transportType,
        };

        try {
          response = await putApi(
            `rates/trucking/${updatedRate.id}`,
            updatedRate,
          );
        } catch (e) {
          toast({ title: errorTitle });
          return false;
        }
      }

      if (response.ok) {
        await refetchRates();
        return true;
      }

      toast({ title: getHttpStatusDescription(response.status) });
      return false;
    },
    [
      countryId,
      postApi,
      putApi,
      refetchRates,
      toast,
      transportType,
      userCountries,
    ],
  );

  const onDelete = useCallback(
    async (id: string) => {
      let response;

      try {
        response = await deleteApi(`rates/trucking/${id}`);
      } catch (e) {
        toast({ title: errorTitle });
        return false;
      }

      if (response.ok) {
        await refetchRates();
        return true;
      }

      toast({ title: getHttpStatusDescription(response.status) });
      return false;
    },
    [deleteApi, refetchRates, toast],
  );

  const canMutateRates = checkCanMutateRates(userRole, userCountries, [
    countryId,
  ]);

  return (
    <>
      <HeadingCard heading="Trucking Charges" />
      <Card>
        <TruckingChargeFilter />
      </Card>
      <Card>
        <TruckingSurchargeSettings
          countryId={countryId}
          transportType={transportType}
          canMutateSettings={canMutateRates}
        />
      </Card>
      <MainCard>
        <HStack justifyContent="space-between">
          <Heading as="h2" variant="h2">{`${
            rates?.length ?? 0
          } Rates`}</Heading>
        </HStack>
        <RateTable<TruckingCharge, TruckingChargeFormValues>
          rateType={RateType.Trucking}
          data={rates}
          columns={columns}
          isLoading={isLoading}
          fetchFailed={fetchFailed}
          statusCode={statusCode}
          onEdit={onEdit}
          onDelete={onDelete}
          showActionColumn={canMutateRates}
        />
      </MainCard>
    </>
  );
};
