import { Button } from '@chakra-ui/react';
import { useTableContext } from '../../hooks/useTableContext';

export const ResetAllColumnsFilter = () => {
  const { setAllFilters } = useTableContext();
  return (
    <Button onClick={() => setAllFilters([])} colorScheme="blue">
      Reset
    </Button>
  );
};
