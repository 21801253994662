import { Country } from './Country';
import { TransportMode } from './Rate';

export enum PortType {
  Sea = 1,
  Air = 4,
}

export interface Port {
  portID: string;
  portType: PortType;
  iataCode: string;
  unloCode: string;
  locationCode: string;
  name: string;
  locationString: string;
  isActive: boolean;
  country: Country;
  city?: string;
}

export const isPort = (port: any) =>
  port &&
  'portID' in port &&
  'portType' in port &&
  'iataCode' in port &&
  'unloCode' in port &&
  'locationCode' in port &&
  'name' in port &&
  'locationString' in port &&
  'isActive' in port &&
  'country' in port &&
  'city' in port;

export const getPortTypeFromTransportMode = (rateType: TransportMode) => {
  switch (rateType) {
    case TransportMode.Air:
      return PortType.Air;
    case TransportMode.FCL:
    case TransportMode.LCL:
      return PortType.Sea;
    default:
      return 0;
  }
};
