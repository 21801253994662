import { Box, SimpleGridProps, Stack } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { Control } from 'react-hook-form';
import {
  FormInputProps,
  NumberFormInput,
  SelectFormInput,
} from '../../../components/Input';
import { ContainerFormData, Unit } from '../../../types';

export interface CargoInputProps extends Omit<FormInputProps, 'accessor'> {
  control: Control<any>;
  gridProps: SimpleGridProps;
  weightUnits: Unit[];
  containerSizeTypes: Unit[];
  defaultValue?: ContainerFormData;
  value?: ContainerFormData;
  isDisabled: boolean;
}

export const ContainerInput: FunctionComponent<CargoInputProps> = ({
  control,
  defaultValue,
  isDisabled,
  value,
  weightUnits,
  containerSizeTypes,
}) => (
  <Stack spacing={5} direction={{ base: 'column', md: 'row' }}>
    <NumberFormInput
      label="Quantity"
      accessor={'containers.quantity'}
      control={control}
      defaultValue={defaultValue && defaultValue.quantity}
      precision={0}
      min={1}
      max={999}
      useButtons={true}
      isRequired={true}
      isDisabled={isDisabled}
      value={value?.quantity ?? 1}
      wrapperProps={{ maxWidth: { base: 'full', md: '40' } }}
    />
    <SelectFormInput
      control={control}
      label="Container Type"
      accessor={'containers.containerSizeType'}
      value={{
        value: defaultValue?.containerSizeType,
        label: containerSizeTypes.find(
          (unit) => unit.code === defaultValue?.containerSizeType,
        )?.name,
      }}
      defaultValue={{
        value: defaultValue?.containerSizeType,
        label: containerSizeTypes.find(
          (unit) => unit.code === defaultValue?.containerSizeType,
        )?.name,
      }}
      controllerDefaultValue={defaultValue?.containerSizeType}
      isRequired={true}
      isDisabled={isDisabled}
      placeholder="Select unit"
      options={containerSizeTypes.map((unit) => ({
        label: unit.name,
        value: unit.code,
      }))}
      wrapperProps={{ maxWidth: { base: 'full', md: '40' } }}
    />
    <Box w="16" />
    <NumberFormInput
      label="Weight per piece"
      accessor={'containers.weight'}
      control={control}
      defaultValue={defaultValue && defaultValue.weight}
      precision={2}
      min={0}
      max={99999}
      isRequired={false}
      isDisabled={isDisabled}
      value={value?.weight}
      wrapperProps={{ maxWidth: { base: 'full', md: '40' } }}
      minW="8rem"
    />
    <SelectFormInput
      control={control}
      label="Unit"
      accessor={'containers.weightUnit'}
      value={{
        value: defaultValue?.weightUnit,
        label: weightUnits.find(
          (unit) => unit.code === defaultValue?.weightUnit,
        )?.name,
      }}
      defaultValue={{
        value: defaultValue?.weightUnit,
        label: weightUnits.find(
          (unit) => unit.code === defaultValue?.weightUnit,
        )?.name,
      }}
      controllerDefaultValue={defaultValue?.weightUnit}
      isRequired={false}
      isDisabled={isDisabled}
      placeholder="Select unit"
      options={weightUnits.map((unit) => ({
        label: unit.name,
        value: unit.code,
      }))}
      wrapperProps={{ maxWidth: { base: 'full', md: '40' } }}
    />
  </Stack>
);
