import React from 'react';
import { useQuery } from 'react-query';
import { TariffRateTable } from '../../../components/Tables/TariffRateTable';
import { useApi } from '../../../providers/ApiProvider';
import { TariffTruckingDto } from '../../../types/api/TariffTruckingDto';
import { API_ROUTES } from '../../../utils/apiRoutes';
import { useTruckingColumns } from '../../ChargesPage/TruckingPage/hooks/useTruckingColumns';

interface ExpiredTruckingTariffModalBodyProps {
  isExpired: boolean;
}

export const ExpiredTruckingTariffModalBody = ({
  isExpired,
}: ExpiredTruckingTariffModalBodyProps) => {
  const { getApi } = useApi();

  const { data, isLoading, isError } = useQuery<TariffTruckingDto[]>(
    [],
    async () => {
      const result = await getApi(
        API_ROUTES.tariffsTruckingExpired({ showSoonExpiring: !isExpired }),
      );

      if (result.ok) {
        return result.json();
      }
    },
  );

  const { columns } = useTruckingColumns();

  return (
    <TariffRateTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      fetchFailed={isError}
      statusCode={0}
      showActionColumn={true}
      hideView={true}
    />
  );
};
