import { chakra, HTMLChakraProps } from '@chakra-ui/system';

export const UserIcon = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg viewBox="0 0 24 24" {...props}>
    <g
      id="Icons/User"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M9,13.75 C6.66,13.75 2,14.92 2,17.25 L2,19 L16,19 L16,17.25 C16,14.92 11.34,13.75 9,13.75 Z M4.34,17 C5.18,16.42 7.21,15.75 9,15.75 C10.79,15.75 12.82,16.42 13.66,17 L4.34,17 Z M9,12 C10.93,12 12.5,10.43 12.5,8.5 C12.5,6.57 10.93,5 9,5 C7.07,5 5.5,6.57 5.5,8.5 C5.5,10.43 7.07,12 9,12 Z M9,7 C9.83,7 10.5,7.67 10.5,8.5 C10.5,9.33 9.83,10 9,10 C8.17,10 7.5,9.33 7.5,8.5 C7.5,7.67 8.17,7 9,7 Z M16.04,13.81 C17.2,14.65 18,15.77 18,17.25 L18,19 L22,19 L22,17.25 C22,15.23 18.5,14.08 16.04,13.81 L16.04,13.81 Z M15,12 C16.93,12 18.5,10.43 18.5,8.5 C18.5,6.57 16.93,5 15,5 C14.46,5 13.96,5.13 13.5,5.35 C14.13,6.24 14.5,7.33 14.5,8.5 C14.5,9.67 14.13,10.76 13.5,11.65 C13.96,11.87 14.46,12 15,12 Z"
        id="Shape-Copy-3"
        fill="currentColor"
        fillRule="nonzero"
      ></path>
    </g>
  </chakra.svg>
);
