import {
  FormControl,
  FormLabel,
  HStack,
  Radio,
  RadioGroup,
  Stack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { DeselectableSelectInput } from '../../../../components/Input/DeselectableSelectInput';
import { useTruckingFiltersOld } from '../../../../hooks/useTruckingFilterOld';
import { useData } from '../../../../providers/DataProvider';
import { TransportMode } from '../../../../types/Rate';

export const TruckingChargeFilter: FunctionComponent = () => {
  const onMobile = useBreakpointValue({ base: true, xl: false });
  const { countries } = useData();
  const { countryId, setCountryId, transportType, setTransportType } =
    useTruckingFiltersOld();

  return (
    <HStack gap="8">
      <DeselectableSelectInput
        label="Country"
        accessor="originCountry"
        currentValue={countryId}
        onSelectionChange={(selected) => {
          setCountryId(selected?.value);
        }}
        width={onMobile ? '100%' : '13rem'}
        options={
          countries.data?.map((country) => ({
            label: country.name,
            value: country.countryID,
          })) ?? []
        }
        noOptionsMessage="You don't have any assigned countries"
      />
      <FormControl>
        <FormLabel htmlFor="transport">Transport Type</FormLabel>
        <RadioGroup
          id="transport"
          value={transportType}
          onChange={(value) => {
            setTransportType(parseInt(value) as TransportMode);
          }}
        >
          <Stack direction="row" h="2.5rem" spacing={{ base: '4', xl: '8' }}>
            <Radio value={TransportMode.Air}>Air</Radio>
            <Radio value={TransportMode.LCL}>LCL</Radio>
          </Stack>
        </RadioGroup>
      </FormControl>
    </HStack>
  );
};
