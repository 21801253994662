import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import type { FunctionComponent } from 'react';
import { getEnv } from '../utils/envVar';
import { TokenClaims } from './AuthenticationProvider';

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

interface Token {
  value: string;
  expiry: number;
}

interface ApiContextProps {
  getApi: (path: string, requestInit?: RequestInit) => Promise<Response>;
  postApi: (
    path: string,
    body: object | null,
    requestInit?: RequestInit,
  ) => Promise<Response>;
  putApi: (
    path: string,
    body: object | null,
    requestInit?: RequestInit,
  ) => Promise<Response>;
  deleteApi: (path: string, requestInit?: RequestInit) => Promise<Response>;
}

const ApiContext = createContext<ApiContextProps>({
  getApi: async () => Promise.resolve(new Response()),
  postApi: async () => Promise.resolve(new Response()),
  putApi: async () => Promise.resolve(new Response()),
  deleteApi: async () => Promise.resolve(new Response()),
});

export const useApi = () => useContext(ApiContext);

export const ApiProvider: FunctionComponent = ({ children }) => {
  const { accounts, instance } = useMsal();
  const [cachedToken, setCachedToken] = useState<Token | null>(null);

  const acquireToken = useCallback(async () => {
    if (accounts.length === 0) return '';

    if (cachedToken != null && cachedToken.expiry > Date.now()) {
      return cachedToken.value;
    }

    const request = {
      scopes: ['profile'],
      account: accounts[0],
    };

    try {
      const auth = await instance.acquireTokenSilent(request);
      const claims = auth.idTokenClaims as TokenClaims;
      setCachedToken({ value: auth.idToken, expiry: claims.exp * 1000 });
      return auth.idToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(request);
      }
    }

    return cachedToken?.value ?? '';
  }, [accounts, cachedToken, instance]);

  const fetchApi = useCallback(
    async (
      path: string,
      method: HttpMethod = HttpMethod.GET,
      requestInit?: RequestInit,
    ) => {
      const token = await acquireToken();

      let authorization = `Bearer ${token}`;
      let defaultRequestInit: RequestInit = {};
      let defaultHeaders: HeadersInit = {
        'Content-Type': 'application/json-patch+json',
        Authorization: authorization,
      };

      if (requestInit != null) {
        const { headers, ...rest } = requestInit;
        defaultHeaders = { ...defaultHeaders, ...headers };
        defaultRequestInit = rest;
      }

      return fetch(`${getEnv('API_URL')}/${path}`, {
        method,
        headers: defaultHeaders,
        ...defaultRequestInit,
      });
    },
    [acquireToken],
  );

  const getApi = useCallback(
    async (path: string, requestInit?: RequestInit) =>
      fetchApi(path, HttpMethod.GET, requestInit),
    [fetchApi],
  );

  const postApi = useCallback(
    async (path: string, body: object | null, requestInit?: RequestInit) =>
      fetchApi(path, HttpMethod.POST, {
        body: JSON.stringify(body),
        ...requestInit,
      }),
    [fetchApi],
  );

  const putApi = useCallback(
    async (path: string, body: object | null, requestInit?: RequestInit) =>
      fetchApi(path, HttpMethod.PUT, {
        body: JSON.stringify(body),
        ...requestInit,
      }),
    [fetchApi],
  );

  const deleteApi = useCallback(
    async (path: string, requestInit?: RequestInit) =>
      fetchApi(path, HttpMethod.DELETE, requestInit),
    [fetchApi],
  );

  const values = useMemo(
    () => ({
      getApi,
      postApi,
      putApi,
      deleteApi,
    }),
    [deleteApi, getApi, postApi, putApi],
  );

  return <ApiContext.Provider value={values}>{children}</ApiContext.Provider>;
};
