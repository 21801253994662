import {
  Grid,
  GridItem,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { valueOrDefault } from '../../../components/LabelUtils';
import { GridDivider } from '../../../components/Layout/GridDivider';
import { MarkdownContainer } from '../../../components/Output/MarkdownContainer';
import { OrgSettingsWarningBox } from '../../../components/Output/OrgSettingsWarningBox';
import { useAuthorization } from '../../../providers/AuthorizationProvider';
import { LengthUnit, Organization, UserRole, WeightUnit } from '../../../types';
import { InfoItem } from '../../AccountPage/InfoItem';
import { OrganizationSettings } from '../../OrganizationEdit';

interface IDetailsProps {
  organization: Organization;
  organizationSettings: OrganizationSettings;
}

export const Details: FunctionComponent<IDetailsProps> = ({
  organization,
  organizationSettings,
}) => {
  const { hasRole } = useAuthorization();

  const marginBottom: string = '10px';
  const getWeightUnit = () => WeightUnit[organizationSettings.weightUnit];
  const getLengthUnit = () => LengthUnit[organizationSettings.lengthUnit];

  return (
    <Grid>
      <Grid
        templateColumns={{ base: 'repeat(1, auto)', md: 'repeat(2, 1fr)' }}
        gap="2"
      >
        <GridItem>
          <Grid templateColumns="repeat(2, 1fr)" gap={2}>
            <InfoItem title="Name" value={organization.name ?? '-'} />
            <InfoItem title="Address" value={organization.addressText ?? '-'} />
            <InfoItem
              title="Country"
              value={organization.mainCountry?.name ?? '-'}
            />
            {hasRole([UserRole.GLOBAL_ADMINISTRATOR]) && (
              <InfoItem
                title="QWYK Hub ID"
                value={
                  organization.qwykOrganizationId == null ||
                  organization.qwykOrganizationId.trim() === ''
                    ? '-'
                    : organization.qwykOrganizationId
                }
              />
            )}
            <InfoItem
              title="Is Active"
              value={organization.isActive ? 'True' : 'False'}
            />
          </Grid>
        </GridItem>
      </Grid>
      <GridDivider />
      <GridItem>
        <Heading as="h2" variant="h2" mb={marginBottom}>
          Instant Quotation Limits
        </Heading>
        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
          }}
        >
          <GridItem>
            <Text mb={marginBottom}>Air</Text>
            <Grid templateColumns="repeat(2, 1fr)" gap={2}>
              <InfoItem
                title="Max length"
                value={valueOrDefault(
                  organizationSettings.airMaxLength,
                  getLengthUnit(),
                  '-',
                )}
              />
              <InfoItem
                title="Max width"
                value={valueOrDefault(
                  organizationSettings.airMaxWidth,
                  getLengthUnit(),
                  '-',
                )}
              />
              <InfoItem
                title="Max height"
                value={valueOrDefault(
                  organizationSettings.airMaxHeight,
                  getLengthUnit(),
                  '-',
                )}
              />
              <InfoItem
                title="Max weight"
                value={valueOrDefault(
                  organizationSettings.airMaxWeight,
                  getWeightUnit(),
                  '-',
                )}
              />
              <InfoItem
                title="Max volume"
                value={valueOrDefault(
                  organizationSettings.airMaxVolume,
                  'cbm',
                  '-',
                )}
              />
            </Grid>
          </GridItem>
          <GridItem>
            <Text mb={marginBottom}>LCL</Text>
            <Grid templateColumns="repeat(2, 1fr)" gap={2}>
              <InfoItem
                title="Max length"
                value={valueOrDefault(
                  organizationSettings.lclMaxLength,
                  getLengthUnit(),
                  '-',
                )}
              />
              <InfoItem
                title="Max width"
                value={valueOrDefault(
                  organizationSettings.lclMaxWidth,
                  getLengthUnit(),
                  '-',
                )}
              />
              <InfoItem
                title="Max height"
                value={valueOrDefault(
                  organizationSettings.lclMaxHeight,
                  getLengthUnit(),
                  '-',
                )}
              />
              <InfoItem
                title="Max weight"
                value={valueOrDefault(
                  organizationSettings.lclMaxWeight,
                  getWeightUnit(),
                  '-',
                )}
              />
              <InfoItem
                title="Max volume"
                value={valueOrDefault(
                  organizationSettings.lclMaxVolume,
                  'cbm',
                  '-',
                )}
              />
            </Grid>
          </GridItem>
          <GridItem>
            <OrgSettingsWarningBox />
          </GridItem>
        </Grid>
        <GridDivider />
        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
          }}
        >
          <GridItem>
            <Heading as="h2" variant="h2" mb={marginBottom}>
              Default Quotation Validity
            </Heading>
            <Grid templateColumns="repeat(4, 1fr)">
              <InfoItem
                title={'Days'}
                value={organizationSettings.defaultQuotationValidity}
              />
            </Grid>
          </GridItem>
          <GridItem>
            <Heading as="h2" variant="h2" mb={marginBottom}>
              Minimum Weight for Instant Quotes
            </Heading>
            <Grid templateColumns="repeat(4, 1fr)">
              <InfoItem
                title={'Air'}
                value={valueOrDefault(
                  organizationSettings.airMinWeight,
                  getWeightUnit(),
                  '-',
                )}
              />
              <InfoItem
                title={'LCL'}
                value={valueOrDefault(
                  organizationSettings.lclMinWeight,
                  getWeightUnit(),
                  '-',
                )}
              />
            </Grid>
          </GridItem>
        </Grid>
      </GridItem>
      <GridDivider />
      <GridItem>
        <Tabs>
          <TabList>
            <Tab>Internal Remarks</Tab>
            <Tab>External Remarks</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <MarkdownContainer
                markdown={organizationSettings.internalRemarks}
              />
            </TabPanel>
            <TabPanel>
              <MarkdownContainer
                markdown={organizationSettings.externalRemarks}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </GridItem>
    </Grid>
  );
};
