import { Flex, Text, TableProps } from '@chakra-ui/react';
import { useState } from 'react';
import { Controller, FieldErrors } from 'react-hook-form';
import { FormInput } from '../../../../components/Input';
import { MethodTariff } from '../../../../types/MethodTariff';
import { ZIP_CODE_SEPARATOR } from '../constants/tariffConstants';
import { TariffZoneInput } from './TariffZoneInput';

interface TariffInputCellProps extends TableProps {
  accessor: string;
  register: any;
  errors: FieldErrors;
  control: any;
  defaultValue: string;
  country: string;
  method: MethodTariff;
  isRequired?: boolean;
  zoneKey: number;
  setValue?: (zoneKey: string, newValue: string) => void;
}

const isNumeric = (value: string): boolean => /^-?\d+$/.test(value);

export const TariffZoneInputCell = ({
  accessor,
  register,
  errors,
  control,
  defaultValue,
  method,
  isRequired = true,
  zoneKey,
  setValue,
}: TariffInputCellProps) => {
  const defaultValues = defaultValue
    .split(ZIP_CODE_SEPARATOR)
    .map((item: string) => item.trim());
  const [fieldFrom, setFieldFrom] = useState(defaultValues[0]);
  const [fieldTo, setFieldTo] = useState(defaultValues[1]);
  const [areFieldsValid, setAreFieldsValid] = useState(true);

  const handleZoneFromChange = (value: string) => {
    setFieldFrom(value);
    if (setValue) {
      setValue(`zone-${zoneKey}`, `${value}${ZIP_CODE_SEPARATOR}${fieldTo}`);
    }
  };

  const handleZoneToChange = (value: string) => {
    setFieldTo(value);
    if (setValue) {
      setValue(`zone-${zoneKey}`, `${fieldFrom}${ZIP_CODE_SEPARATOR}${value}`);
    }
  };

  const validateDistances = (
    zipFrom: string,
    zipTo: string,
  ): boolean | string => {
    if (isNumeric(zipFrom) && isNumeric(zipTo)) {
      const valueFrom = parseInt(zipFrom);
      const valueTo = parseInt(zipTo);
      if (valueFrom < valueTo) {
        setAreFieldsValid(true);
        return true;
      }
      setAreFieldsValid(false);
      return 'DistanceFrom has to be lower than DistanceTo';
    }
    setAreFieldsValid(false);
    return 'The Provided distance zones are invalid';
  };

  const validate = (v: any) => {
    const [zipFrom, zipTo] = v
      .split(ZIP_CODE_SEPARATOR)
      .map((item: string) => item.trim());
    if (method === MethodTariff.DistanceZones) {
      return validateDistances(zipFrom, zipTo);
    }
  };

  return (
    <Flex alignItems="center">
      <Controller
        name={`zone-${zoneKey}`}
        control={control}
        rules={{
          required: false,
          validate: validate,
        }}
        render={() => (
          <FormInput
            accessor={accessor}
            register={register}
            isRequired={isRequired}
            errors={errors}
            defaultValue={defaultValue}
            hidden={true}
          />
        )}
      />
      <Controller
        name={`zone-${zoneKey}-zoneFrom`}
        control={control}
        render={() => (
          <TariffZoneInput
            error={errors[`zone-${zoneKey}`]}
            value={fieldFrom}
            name="zoneFrom"
            isInvalid={!areFieldsValid}
            handleZoneFromChange={(val) => handleZoneFromChange(val)}
          />
        )}
      />

      <Text fontSize="lg" paddingX="1">
        -
      </Text>
      <Controller
        name={`zone-${zoneKey}-zoneTo`}
        control={control}
        render={() => (
          <TariffZoneInput
            error={errors[`zone-${zoneKey}`]}
            value={fieldTo}
            name="zoneTo"
            isInvalid={!areFieldsValid}
            handleZoneFromChange={(val) => handleZoneToChange(val)}
          />
        )}
      />
    </Flex>
  );
};
