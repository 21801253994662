import type { ChargeCode, Country, Currency, Port } from '.';

export enum MasterDataType {
  PORTS,
  COUNTRIES,
  CURRENCIES,
  CHARGE_CODES,
}

export type MasterData = ChargeCode | Country | Currency | Port;

export type SelectedSystem = {name: string, code: string}