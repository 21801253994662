import { FieldValues } from 'react-hook-form';
import { getRateFormValuesSorted } from './getRateFormValuesSorted';

export const getRatesFormFiltered = (
  formValues: FieldValues,
  rowCount: number,
  columnCount: number,
) => {
  const tempValues: Record<string, any> = {};
  const { zones, headers, cells } = getRateFormValuesSorted(
    formValues,
    rowCount,
    columnCount,
  );
  const populateValuesObject = (items: [string, any][]) => {
    items.forEach((item) => {
      tempValues[item[0] as string] = item[1];
    });
  };
  populateValuesObject(zones);
  populateValuesObject(headers);
  populateValuesObject(cells);
  return tempValues;
};
