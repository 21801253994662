import { Center, Checkbox, GridItem, Spinner } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import type {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useQuery } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import {
  FormInput,
  SelectFormInput,
  SelectInputOption,
} from '../../../components/Input';
import { useApi } from '../../../providers/ApiProvider';
import type { ChargeCode, SystemChargeCode } from '../../../types';
import { SelectedSystem } from '../../../types/MasterData';

interface ChargeCodeFieldsProps {
  register: UseFormRegister<ChargeCode>,
  errors: FieldErrors<ChargeCode>,
  control: Control<any>,
  watch: UseFormWatch<ChargeCode>,
  setValue: UseFormSetValue<ChargeCode>,
  selectedSystem?: SelectedSystem
}

export const ChargeCodeFields: FunctionComponent<ChargeCodeFieldsProps> = ({
                                                                             register,
                                                                             errors,
                                                                             control,
                                                                             watch,
                                                                             setValue,
                                                                             selectedSystem,
                                                                           }) => {
  const { getApi } = useApi();
  const { isLoading, data: systemChargeCodes } = useQuery<SystemChargeCode[]>(
    'systemChargeCodes',
    async () => {
      const result = await getApi(`system-charge-codes?code=${selectedSystem?.code}`);

      if (result.ok) {
        return (await result.json()) as SystemChargeCode[];
      }

      return [];
    },
  );

  if (isLoading) {
    return (
      <GridItem colSpan={2}>
        <Center>
          <Spinner size="xl" />
        </Center>
      </GridItem>
    );
  }

  return (
    <>
      <GridItem>
        <FormInput
          label="Code"
          register={register}
          errors={errors}
          accessor={'code'}
          isRequired={true}
          maxLength={10}
          textTransform="uppercase"
          registerOptions={{
            maxLength: { value: 10, message: 'Maximum of 10 characters' },
          }}
        />
      </GridItem>
      <GridItem>
        <SelectFormInput
          label={`${selectedSystem?.name} Code`}
          accessor={'chargeCodeMappings'}
          control={control}
          placeholder={`Select a ${selectedSystem?.name} Code`}
          isRequired={false}
          defaultValue={{
            label: watch('chargeCodeMappings')?.find(
              (mapping) => mapping.system.code === selectedSystem?.code,
            )?.systemChargeCode.code,
            value: watch('chargeCodeMappings')?.find(
              (mapping) => mapping.system.code === selectedSystem?.code,
            )?.systemChargeCode.systemChargeCodeID,
          }}
          onChange={(value) => {
            const systemChargeCode = systemChargeCodes?.find(
              (scc) =>
                scc.systemChargeCodeID === (value as SelectInputOption).value,
            );
            if (systemChargeCode != null) {
              setValue('chargeCodeMappings', [
                {
                  chargeCodeMappingID: uuidv4(),
                  system: systemChargeCode.system,
                  systemChargeCode,
                },
              ]);
            }
          }}
          options={systemChargeCodes?.map((systemChargeCode) => ({
            label: systemChargeCode.code,
            value: systemChargeCode.systemChargeCodeID,
          }))}
        />
      </GridItem>
      <GridItem colSpan={2}>
        <FormInput
          label="Description"
          register={register}
          errors={errors}
          accessor={'name'}
          isRequired={true}
          registerOptions={{
            maxLength: { value: 50, message: 'Maximum of 50 characters' },
          }}
        />
      </GridItem>
      <GridItem colSpan={2}>
        <Checkbox {...register('isActive')}>Is Active</Checkbox>
      </GridItem>
    </>
  );
};
