import { TransportMode } from './Rate';

export enum PlaceType {
  Airport,
  Seaport,
  Address,
}

export interface Place {
  placeId: string;
  placeName: string;
  placeType?: PlaceType;
}

export const getPlaceTypeFromTransportMode = (rateType: TransportMode) => {
  switch (rateType) {
    case TransportMode.Air:
      return PlaceType.Airport;
    case TransportMode.LCL:
    case TransportMode.FCL:
      return PlaceType.Seaport;
    default:
      return PlaceType.Address;
  }
};
