import { Stack } from '@chakra-ui/layout';
import type { FunctionComponent } from 'react';
import { HeadingCard } from '../../components/Layout/HeadingCard';
import { useAuthentication } from '../../providers/AuthenticationProvider';
import { useAuthorization } from '../../providers/AuthorizationProvider';
import { UserRole } from '../../types';
import { DashboardGrid } from './components/DashboardGrid';

export const HomePage: FunctionComponent = () => {
  const { user } = useAuthentication();
  const { hasRole } = useAuthorization();

  return (
    <Stack h="full" justify="space-between">
      <Stack>
        <HeadingCard
          heading={`Hello ${user?.name?.split(' ')[0] ?? 'Anonymous'}!`}
        />
        {hasRole([
          UserRole.ORGANIZATION_MANAGER,
          UserRole.REGION_MANAGER,
          UserRole.RATE_MANAGER,
        ]) && <DashboardGrid />}
      </Stack>
    </Stack>
  );
};
