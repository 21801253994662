import { ViewIcon } from '@chakra-ui/icons';
import { Button, Flex, Link } from '@chakra-ui/react';
import type { TableProps } from '@chakra-ui/react';
import { ReactElement, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import type { Column } from 'react-table';
import { TableActionButtonProps } from '../../../theme/components';
import { TariffTruckingDto } from '../../../types/api/TariffTruckingDto';
import { routes } from '../../../utils/routes';
import { DataTable } from '../../DataTable';
import { EditIcon } from '../../Icons';

interface TariffRateTableProps<T extends object> extends TableProps {
  data?: T[];
  columns: Column<T>[];
  isLoading: boolean;
  fetchFailed: boolean;
  statusCode: number;
  empty?: ReactElement;
  showActionColumn?: boolean;
  enableFilters?: boolean;
  hideView?: boolean;
}

export const TariffRateTable = ({
  data,
  columns,
  isLoading,
  fetchFailed,
  statusCode,
  empty,
  showActionColumn = true,
  enableFilters = false,
  hideView = false,
}: TariffRateTableProps<TariffTruckingDto>) => {
  const viewColumn: Column<TariffTruckingDto> = useMemo(
    () => ({
      Header: '',
      id: 'view',
      accessor: (rowRate: TariffTruckingDto) => (
        <Flex>
          {!hideView && (
            <Flex w="100%" justifyContent="flex-end">
              <Link
                to={routes.charges.tariffDetails(rowRate.tariffID)}
                as={RouterLink}
              >
                <Button
                  {...TableActionButtonProps}
                  rightIcon={<ViewIcon w="4" h="4" />}
                >
                  View
                </Button>
              </Link>
            </Flex>
          )}
          {showActionColumn && (
            <Flex w="100%" justifyContent="flex-end">
              <Link
                to={{
                  pathname: routes.charges.tariffDetails(rowRate.tariffID),
                  search: `?isEdit=1`,
                }}
                as={RouterLink}
              >
                <Button
                  {...TableActionButtonProps}
                  rightIcon={<EditIcon w="4" h="4" />}
                >
                  Edit
                </Button>
              </Link>
            </Flex>
          )}
        </Flex>
      ),
      minWidth: 180,
      disableFilters: true,
    }),
    [showActionColumn, hideView],
  );

  const newColumns = useMemo(
    () =>
      !showActionColumn && hideView ? [...columns] : [...columns, viewColumn],
    [showActionColumn, columns, viewColumn, hideView],
  );

  return (
    <DataTable
      data={data}
      isLoading={isLoading}
      columns={newColumns}
      enableFilters={enableFilters}
      fetchFailed={fetchFailed}
      statusCode={statusCode}
      empty={empty}
      variant={showActionColumn ? 'sticky' : 'default'}
      minHeight="20rem"
    />
  );
};
