import { getTariffBrackets } from '../../pages/ChargesPage/TariffCreateRatesPage/utils/getTariffBrackets';
import { formatChargeDate } from '../../pages/ChargesPage/utils/dateUtils';
import { CalculatorTariff } from '../../types/CalculatorTariff';
import { TariffTrucking } from '../../types/api/TariffTrucking';
import { TariffTruckingDto } from '../../types/api/TariffTruckingDto';

export const transformTariffTruckingDtoToCreateModel = (
  tariffTrucking: TariffTruckingDto,
  zoneValues: [string, any][],
  headerValues: [string, any][],
  cellValues: [string, any][],
): TariffTrucking => {
  const { weightBrackets, methodBrackets, sortedTariffRates } =
    getTariffBrackets(
      zoneValues,
      headerValues,
      cellValues,
      tariffTrucking.calculator === CalculatorTariff.MPU,
      undefined,
    );
  return {
    tariffID: '00000000-0000-0000-0000-000000000000',
    name: tariffTrucking.name,
    organization: null,
    country: tariffTrucking.country,
    calculator: CalculatorTariff[tariffTrucking.calculator],
    method: tariffTrucking.method[0],
    currency: tariffTrucking.currency,
    validFrom: formatChargeDate(new Date().toDateString()),
    validTo: formatChargeDate(new Date().toDateString()),
    isActive: true,
    createUser: '00000000-0000-0000-0000-000000000000',
    createTimestamp: formatChargeDate(new Date().toDateString()),
    lastEditUser: '00000000-0000-0000-0000-000000000000',
    lastEditTimestamp: formatChargeDate(new Date().toDateString()),
    transportMode: tariffTrucking.transportMode ,
    direction: tariffTrucking.direction as unknown as (string | null)[],
    percentFuel: tariffTrucking.percentFuel ?? 0,
    chargeableWeightRatio: tariffTrucking.chargeableWeightRatio ?? 0,
    assignedPorts: tariffTrucking.assignedPorts,
    tariffRatesSorted: sortedTariffRates,
    weightBrackets: weightBrackets,
    methodBrackets: methodBrackets,
  };
};
