import { ComponentStyleConfig } from '@chakra-ui/react';

export const Heading: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: 'bold',
    fontSize: '1.25rem',
  },
  variants: {
    logo: {
      fontSize: '1.125rem',
      fontWeight: '600',
    },
    h1: {
      fontSize: '1.25rem',
    },
    h2: {
      fontSize: 'md',
    },
  },
};
