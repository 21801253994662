import { BracketWeightDto } from '../../../../types/api/BracketWeightDto';
import { EMPTY_BRACKET } from '../constants/emptyBracket';

export const getEmptyBrackets = (
  columnCount: number,
  savedForm?: Record<string, unknown>,
): BracketWeightDto[] => {
  const brackets = [];

  for (let i = 0; i < columnCount; i += 1) {
    let prefillValue: number = 0;
    if (savedForm && i > 0) {
      const formMatch = savedForm[`header-${i}`];
      prefillValue = formMatch as number;
    }

    brackets.push({
      ...EMPTY_BRACKET,
      order: i,
      isMinValue: i === 0,
      value: prefillValue,
    });
  }

  return brackets;
};
