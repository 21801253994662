import { chakra, HTMLChakraProps } from '@chakra-ui/system';

export const EditIcon = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg viewBox="0 0 16 16" {...props}>
    <g
      id="Icons/Small/Edit"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M3.5,10.6252604 L3.5,12.5 L5.37473962,12.5 L10.9039717,6.97076795 L9.02923205,5.09602833 L3.5,10.6252604 Z M12.3537703,5.52096931 C12.5487432,5.32599639 12.5487432,5.01104013 12.3537703,4.81606721 L11.1839328,3.64622969 C10.9889599,3.45125677 10.6740036,3.45125677 10.4790307,3.64622969 L9.56415776,4.56110262 L11.4388974,6.43584224 L12.3537703,5.52096931 Z"
        id="icon/content/create_24px"
        fill="currentColor"
        fillRule="nonzero"
      ></path>
    </g>
  </chakra.svg>
);
