import { DeleteIcon } from '@chakra-ui/icons';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/modal';
import {
  Box,
  Button,
  Heading,
  HStack,
  ModalHeader,
  Stack,
} from '@chakra-ui/react';
import { addDays } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useData } from '../../../providers/DataProvider';
import type {
  Rate,
  LocalCharge,
  AirCharge,
  SeaLclCharge,
  TruckingCharge,
  SeaFclCharge,
} from '../../../types';
import { RateType } from '../../../types';
import { rateTypeIcons } from '../../Icons/RateTypeIcons';
import { DateFormInput, SelectFormInput, SelectInputOption } from '../../Input';
import { AirChargeFields } from './AirChargeFields';
import { LocalChargeFields } from './LocalChargeFields';
import { SeaFclChargeFields } from './SeaFclChargeFields';
import { SeaLclChargeFields } from './SeaLclChargeFields';
import { TruckingChargeFields } from './TruckingChargeFields';

interface UpsertRateModalProps<
  T extends Omit<Rate, 'organizationId'>,
  K extends object,
> {
  isOpen: boolean;
  onClose: () => void;
  rate?: T;
  rateType: RateType;
  isCreate: boolean;
  onSave: (submitData: K, rate?: T) => Promise<boolean>;
  onDeleteOpen: () => void;
  createRateHeaderTitle?: string;
  updateRateHeaderTitle?: string;
}

type RateTypes =
  | LocalCharge
  | AirCharge
  | SeaLclCharge
  | SeaFclCharge
  | TruckingCharge
  | Omit<Rate, 'organizationId'>;

export const transportMode: Record<string, number> = {
  air: 1,
  seaFcl: 2,
  seaLcl: 3,
};

export const UpsertRateModal = <
  R extends Omit<Rate, 'organizationId'>,
  S extends object,
>({
  isOpen,
  onClose,
  rate,
  rateType,
  isCreate,
  onSave,
  onDeleteOpen,
  createRateHeaderTitle = 'Create Rate',
  updateRateHeaderTitle = 'Edit Rate',
}: UpsertRateModalProps<R, S>) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { currencies } = useData();

  const getDefaultValues = useCallback(
    (defaultRate: RateTypes | undefined) => {
      if (rateType === RateType.Local) {
        const localRate = defaultRate as LocalCharge;
        return {
          chargeCode: localRate?.chargeCode,
          transportMode: localRate?.transportMode,
          category: localRate?.isOrigin ? 'Export' : 'Import',
          country: localRate?.country,
          port: localRate?.port,
          currency: localRate?.currency,
          ratePerKg: localRate?.ratePerKg ?? '',
          ratePerCbm: localRate?.ratePerCbm ?? '',
          ratePer20FeetContainer: localRate?.ratePer20FeetContainer ?? '',
          ratePer40FeetContainer: localRate?.ratePer40FeetContainer ?? '',
          ratePer40HCFeetContainer: localRate?.ratePer40HCFeetContainer ?? '',
          min: localRate?.minimumCharge ?? '',
          flat: localRate?.flat ?? '',
          validFrom: localRate?.validFrom
            ? new Date(localRate?.validFrom)
            : new Date(),
          validTo: localRate?.validTo
            ? new Date(localRate?.validTo)
            : addDays(new Date(), 1),
        };
      }

      if (rateType === RateType.Air) {
        const airRate = defaultRate as AirCharge;
        return {
          origin: airRate?.origin,
          destination: airRate?.destination,
          currency: airRate?.currency,
          min: airRate?.minimumCharge ?? '',
          below100kg: airRate?.below100kg ?? '',
          above100kg: airRate?.above100kg ?? '',
          above300kg: airRate?.above300kg ?? '',
          above500kg: airRate?.above500kg ?? '',
          above1000kg: airRate?.above1000kg ?? '',
          validFrom: airRate?.validFrom
            ? new Date(airRate?.validFrom)
            : new Date(),
          validTo: airRate?.validTo
            ? new Date(airRate?.validTo)
            : addDays(new Date(), 1),
        };
      }

      if (rateType === RateType.SeaLcl) {
        const seaLclRate = defaultRate as SeaLclCharge;
        return {
          origin: seaLclRate?.origin,
          destination: seaLclRate?.destination,
          currency: seaLclRate?.currency,
          min: seaLclRate?.minimumCharge ?? '',
          perCbm: seaLclRate?.perCbm ?? '',
          per1000kg: seaLclRate?.per1000kg ?? '',
          validFrom: seaLclRate?.validFrom
            ? new Date(seaLclRate?.validFrom)
            : new Date(),
          validTo: seaLclRate?.validTo
            ? new Date(seaLclRate?.validTo)
            : addDays(new Date(), 1),
        };
      }

      if (rateType === RateType.SeaFcl) {
        const seaFclRate = defaultRate as SeaFclCharge;
        return {
          origin: seaFclRate?.origin,
          destination: seaFclRate?.destination,
          currency: seaFclRate?.currency,
          per20GP: seaFclRate?.per20GP ?? '',
          per40GP: seaFclRate?.per40GP ?? '',
          per40HC: seaFclRate?.per40HC ?? '',
          validFrom: seaFclRate?.validFrom
            ? new Date(seaFclRate?.validFrom)
            : new Date(),
          validTo: seaFclRate?.validTo
            ? new Date(seaFclRate?.validTo)
            : addDays(new Date(), 1),
        };
      }

      if (rateType === RateType.Trucking) {
        const truckingRate = defaultRate as TruckingCharge;
        return {
          currency: truckingRate?.currency,
          transportMode: truckingRate.transportMode,
          country: truckingRate.country,
          minimumCharge: truckingRate?.minimumCharge ?? '',
          distanceZone: truckingRate.distanceZone ?? '',
          below100Kg: truckingRate.below100Kg ?? '',
          below300Kg: truckingRate.below300Kg ?? '',
          below500Kg: truckingRate.below500Kg ?? '',
          below1000Kg: truckingRate.below1000Kg ?? '',
          below3000Kg: truckingRate.below3000Kg ?? '',
          below5000Kg: truckingRate.below5000Kg ?? '',
          validFrom: truckingRate?.validFrom
            ? new Date(truckingRate?.validFrom)
            : new Date(),
          validTo: truckingRate?.validTo
            ? new Date(truckingRate?.validTo)
            : addDays(new Date(), 1),
        };
      }

      return {
        validFrom: new Date(),
        validTo: addDays(new Date(), 1),
      };
    },
    [rateType],
  );

  const {
    control,
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: { ...getDefaultValues(rate) },
  });

  const resetModal = () => {
    reset({ ...getDefaultValues(rate) });
    onClose();
  };

  // This is necessary to reload the data displayed in modal
  useEffect(() => {
    reset({ ...getDefaultValues(rate) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rate]);

  // SubmitData is typed as any here, as the typing of react hook form with generic types caused some trouble
  const onSubmit = async (submitData: any) => {
    setIsSubmitting(true);
    const success = await onSave(submitData, rate);
    setIsSubmitting(false);
    if (success) {
      reset();
      onClose();
    }
  };

  const fields = useMemo(() => {
    if (rateType === RateType.Local) {
      return (
        <LocalChargeFields
          control={control}
          setValue={setValue}
          getValues={getValues}
          register={register}
          rate={rate as unknown as LocalCharge | undefined}
        />
      );
    }

    if (rateType === RateType.Air) {
      return (
        <AirChargeFields
          control={control}
          getValues={getValues}
          register={register}
        />
      );
    }

    if (rateType === RateType.SeaLcl) {
      return (
        <SeaLclChargeFields
          control={control}
          getValues={getValues}
          register={register}
        />
      );
    }

    if (rateType === RateType.SeaFcl) {
      return (
        <SeaFclChargeFields
          control={control}
          getValues={getValues}
          register={register}
        />
      );
    }

    if (rateType === RateType.Trucking) {
      return (
        <TruckingChargeFields
          control={control}
          getValues={getValues}
          errors={errors}
          register={register}
        />
      );
    }

    return null;
  }, [rateType, control, setValue, getValues, register, rate, errors]);

  return (
    <Modal isOpen={isOpen} onClose={resetModal}>
      <ModalOverlay />
      <ModalContent bg="grey.800" minWidth={{ base: '20rem', md: '37.5rem' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalCloseButton />
          <ModalHeader borderBottom="4px" borderColor="grey.900">
            <HStack justifyContent="center">
              {rateTypeIcons[rateType]}
              <Heading fontSize="lg">
                {isCreate ? createRateHeaderTitle : updateRateHeaderTitle}
              </Heading>
            </HStack>
          </ModalHeader>
          <ModalBody py="8" px={{ base: '7', md: '14' }}>
            <Stack spacing="6">
              {fields}
              <Stack direction={{ base: 'column', md: 'row' }}>
                <SelectFormInput
                  label="Currency"
                  accessor="currency.code"
                  placeholder="Select Currency"
                  isRequired={true}
                  defaultValue={
                    rate && rate.currency.code !== ''
                      ? {
                          label: rate.currency.code,
                          value: rate.currency.code,
                        }
                      : undefined
                  }
                  controllerDefaultValue={rate?.currency.code}
                  onChange={(value) => {
                    setValue(
                      'currency',
                      currencies.data?.filter(
                        (currency) =>
                          (value as SelectInputOption).value === currency.code,
                      )[0],
                    );
                  }}
                  control={control}
                  options={
                    !currencies.isLoading && currencies.data
                      ? currencies.data.map((currency) => ({
                          label: currency.code,
                          value: currency.code,
                        }))
                      : []
                  }
                />
                <DateFormInput
                  label="Valid From"
                  accessor="validFrom"
                  isRequired={true}
                  onChange={(value) => {
                    const date = value ?? new Date();
                    setValue('validFrom', date);
                  }}
                  selected={watch('validFrom')}
                  control={control}
                />
                <DateFormInput
                  label="Valid To"
                  accessor="validTo"
                  validate={(value: number | string | Date) =>
                    watch('validFrom') === undefined ||
                    new Date(value).getTime() -
                      new Date(watch('validFrom')).getTime() >
                      0 ||
                    'Valid To must be after Valid From'
                  }
                  isRequired={true}
                  onChange={(value) => {
                    const date = value ?? new Date();
                    setValue('validTo', date);
                  }}
                  selected={watch('validTo')}
                  control={control}
                />
              </Stack>
            </Stack>
            {!isCreate && (
              <Button
                w="100%"
                mt="8"
                py="8"
                variant="ghost"
                _hover={{ bgColor: 'grey.600' }}
                colorScheme="white"
                color="grey.400"
                borderTop="1px solid grey.900"
                borderBottom="1px solid grey.900"
                borderRadius="0"
                onClick={() => {
                  onDeleteOpen();
                }}
              >
                <DeleteIcon mr="2" />
                <Box>Clear values</Box>
              </Button>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              mr={3}
              onClick={resetModal}
              colorScheme="white"
              variant="ghost"
              _hover={{ bgColor: 'grey.600' }}
            >
              Cancel
            </Button>
            <Button type="submit" colorScheme="blue" isLoading={isSubmitting}>
              Save
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
