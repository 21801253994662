export interface Address {
  city: string;
  countryCode: string;
  freeTextAddress: string;
  geoLocation: string;
  name: string;
  street: string;
  zipCode: string;
}

export const isAddress = (address: any) =>
  address &&
  'city' in address &&
  'countryCode' in address &&
  'freeTextAddress' in address &&
  'geoLocation' in address &&
  'name' in address &&
  'zipCode' in address;
