import { Button, ButtonProps, useClipboard } from "@chakra-ui/react"
import { FunctionComponent } from "react"
import { CheckIcon } from "./Icons"
import { CopyIcon } from "./Icons/CopyIcon"

interface CopyButtonProps extends ButtonProps{
    copyValue: string
    size: string
}

export const CopyButton: FunctionComponent<CopyButtonProps> = ({copyValue, size, ...rest}) =>
{
  const { onCopy, hasCopied } = useClipboard(copyValue, 5_000)
  
    return(
            <Button 
            {...rest}
            border='1px'
            borderColor='grey.300'
            paddingY='4px'
            paddingX='6px'
            height='fit-content'
            variant="ghost"
            colorScheme="white"
            _focus={{outline: 'none'}}
            leftIcon={hasCopied ? <CheckIcon width={size} /> : <CopyIcon width={size}/> } 
            onClick={onCopy}
            >
                {hasCopied ? 'Copied!' : 'Copy'}
            </Button>
    )

}