import create from 'zustand';
import { Port } from '../types';

interface FilterStore {
  originCountryId: string | undefined;
  originPortId: string | undefined;
  destinationCountryId: string | undefined;
  destinationPortId: string | undefined;
  maxCount: number;
  ports: Port[];
  setOriginCountryId: (countryId: string | undefined) => void;
  setOriginPortId: (portId: string | undefined) => void;
  setDestinationCountryId: (countryId: string | undefined) => void;
  setDestinationPortId: (portId: string | undefined) => void;
  setMaxCount: (count: number) => void;
  setPorts: (ports: Port[]) => void;
  reset: () => void;
}

export const useSeaLclFilters = create<FilterStore>((set) => ({
  originCountryId: undefined,
  originPortId: undefined,
  destinationCountryId: undefined,
  destinationPortId: undefined,
  ports: [],
  maxCount: 10,
  setOriginCountryId: (originCountryId) => set(() => ({ originCountryId })),
  setOriginPortId: (originPortId) => set(() => ({ originPortId })),
  setDestinationCountryId: (destinationCountryId) =>
    set(() => ({ destinationCountryId })),
  setDestinationPortId: (destinationPortId) =>
    set(() => ({ destinationPortId })),
  setMaxCount: (maxCount) => set(() => ({ maxCount })),
  setPorts: (ports) => set(() => ({ ports })),
  reset: () =>
    set(() => ({
      originCountryId: undefined,
      originPortId: undefined,
      destinationCountryId: undefined,
      destinationPortId: undefined,
      ports: [],
      maxCount: 10,
    })),
}));
