import { Heading, HStack } from '@chakra-ui/layout';
import type { FunctionComponent } from 'react';
import { CargonerdsIcon } from './Icons/CargonerdsIcon';

export const Logo: FunctionComponent = () => (
  <HStack p="2.5">
    <CargonerdsIcon w="7" h="7" color="blue.400" />
    <Heading variant="logo">Pricing Manager</Heading>
  </HStack>
);
