import { Divider, Flex } from '@chakra-ui/react';
import { Skeleton } from '@chakra-ui/skeleton';
import { FunctionComponent } from 'react';
import { Card } from '../../../../components/Layout';
import { Country, Port } from '../../../../types';
import { CreateTariffMetaValues } from '../../../../types/forms/CreateTariffMetaValues';
import { TruckingSurchargeSettings } from '../../TruckingPage/components/TruckingSurchargeSettings';
import { TariffMeta } from './TariffMeta';

interface TariffMetaInformationProps {
  metaInformation?: CreateTariffMetaValues;
  country?: Country;
  ports?: Port[];
  isLoading?: boolean;
}

export const TariffMetaInformation: FunctionComponent<
  TariffMetaInformationProps
> = ({ metaInformation, country, ports, isLoading = false }) => {
  const ratio = (metaInformation?.chargeableWeight || 0) / (metaInformation?.chargeableRatio || 1)
  const ratioLabel = (Math.round(ratio * 100) / 100).toFixed(2);
  return (
    <Card>
      {isLoading && <Skeleton isLoaded={!isLoading} height="50px"></Skeleton>}
      {metaInformation && ports && country && (
        <TariffMeta
          metaInformation={metaInformation}
          ports={ports}
          country={country}
        />
      )}
      <Flex pb="4" pt="4">
        <Divider borderColor="grey.900" borderWidth="2px" opacity="unset" />
      </Flex>
      <Skeleton isLoaded={!isLoading} height="50px">
        {!!metaInformation && country && (
          <TruckingSurchargeSettings
            percentFuel={metaInformation.percentFuel}
            transportType={metaInformation.transportType}
            chargeableWeightRatio={ratioLabel}
          />
        )}
      </Skeleton>
    </Card>
  );
};
