import { QuoteNotificationType } from '../../types';

export const getNotificationColor = (type: QuoteNotificationType) => {
  switch (type) {
    case QuoteNotificationType.Error:
      return 'red';
    case QuoteNotificationType.Warning:
      return 'orange';
    case QuoteNotificationType.Info:
    default:
      return 'white';
  }
};
