import { ChevronLeftIcon, DeleteIcon } from '@chakra-ui/icons';
import {
  Button,
  HStack,
  Skeleton,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { ActionModal } from '../../components/ActionModal';
import { CloseIcon, CheckIcon } from '../../components/Icons';
import {
  FormInput,
  SelectFormInput,
  SelectInputOption,
} from '../../components/Input';
import { HeadingCard, MainCard } from '../../components/Layout';
import { ErrorResult } from '../../components/Result';
import { UnsavedChangesModal } from '../../components/UnsavedChangesModal';
import { useErrorToast } from '../../hooks/useErrorToast';
import { useApi } from '../../providers/ApiProvider';
import { useAuthorization } from '../../providers/AuthorizationProvider';
import type {
  Authentication,
  AuthenticationRequest,
  ThirdParty,
  ThirdPartyApiFormData,
} from '../../types';
import { MainLegSource } from '../../types';
import { getHttpStatusDescription } from '../../utils/httpStatus';
import { routes } from '../../utils/routes';

interface ThirdPartyApiFormProps {
  authentication?: Authentication;
  thirdParties?: ThirdParty[];
  isLoading: boolean;
  statusCode: number;
  onSubmit: (data: AuthenticationRequest) => void;
}

const errorTitle = 'Saving your changes failed, please try again';
export const ThirdPartyApiForm: FunctionComponent<ThirdPartyApiFormProps> = ({
  authentication,
  thirdParties,
  isLoading,
  statusCode,
  onSubmit,
}) => {
  const { userCountries } = useAuthorization();
  const { getApi, deleteApi } = useApi();
  const navigate = useNavigate();
  const errorToast = useErrorToast();
  const {
    isOpen: isOpenBack,
    onOpen: onOpenBack,
    onClose: onCloseBack,
  } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onClose: onCloseDelete,
    onOpen: onOpenDelete,
  } = useDisclosure();

  const { isLoading: isLoadingAuthentications, data: authentications } =
    useQuery<Authentication[]>(
      ['authentications'],
      async () => {
        const result = await getApi(`authentications`);
        if (result.ok) return result.json();
      },
      {
        refetchOnWindowFocus: false,
      },
    );

  const filteredCountries = useMemo(() => {
    if (authentications == null) return userCountries;

    const countryIds = authentications.map((auth) => auth.country?.countryID);

    return userCountries.filter(
      (country) =>
        !countryIds.includes(country.countryID) ||
        country.countryID === authentication?.country?.countryID,
    );
  }, [authentication, authentications, userCountries]);

  const defaultValues = useMemo(() => {
    const authStrings = (authentication?.authenticationString ?? '||').split(
      '|',
    );
    let customerKey1 = '';
    let customerKey2 = '';
    let username = '';
    let password = '';
    let environment = '';
    let hapagLloydClientId = '';
    let hapagLloydClientSecret = '';

    if (authentication?.thirdParty.api === MainLegSource.WebCargo || authentication?.thirdParty.api === MainLegSource.CargoOne) {
      customerKey1 = authStrings[1];
      customerKey2 = authStrings[2];
    } else if (authentication?.thirdParty.api === MainLegSource.CargoSphere) {
      const parts = authStrings[2].split(':');
      username = parts[0];
      password = parts[1];
      environment = parts[2];
    } else if(authentication?.thirdParty.api === MainLegSource.HapagLloyd)
    {
      hapagLloydClientId = authStrings[2];
      hapagLloydClientSecret = authStrings[5];
    }

    return {
      api: authentication?.thirdParty.thirdPartyID ?? '',
      country: authentication?.country?.countryID ?? '',
      customerKey1,
      customerKey2,
      username,
      password,
      environment,
      hapagLloydClientId,
      hapagLloydClientSecret,
    };
  }, [authentication]);

  const getFormData = useCallback(
    (values: ThirdPartyApiFormData) => {
      const thirdParty = thirdParties?.find(
        (tp) => tp.thirdPartyID === values.api,
      );

      if (thirdParty == null) return;

      let authenticationString = '';
      if (thirdParty?.api === MainLegSource.WebCargo || thirdParty?.api === MainLegSource.CargoOne) {
        authenticationString = `Body|${values.customerKey1.trim()}|${values.customerKey2.trim()}`;
      } else if (thirdParty?.api === MainLegSource.CargoSphere) {
        authenticationString = `Header|CustomAuth|${values.username.trim()}:${values.password.trim()}:${values.environment.trim()}`;
      } else if(thirdParty?.api === MainLegSource.HapagLloyd) {
        authenticationString = `Header|X-IBM-Client-Id|${values.hapagLloydClientId.trim()}|Header|X-IBM-Client-Secret|${values.hapagLloydClientSecret.trim()}`;
      }

      return {
        authenticationString,
        country: filteredCountries.find(
          (country) => country.countryID === values.country,
        ),
        thirdParty,
      };
    },
    [thirdParties, filteredCountries],
  );

  const sendRequest = useCallback(
    (values: ThirdPartyApiFormData) => {
      const data = getFormData(values);
      if (data != null) onSubmit(data);
    },
    [getFormData, onSubmit],
  );

  const onDelete = useCallback(async () => {
    let response;
    try {
      response = await deleteApi(
        `authentications/${authentication?.authenticationID}`,
      );
    } catch (e) {
      errorToast({
        title: errorTitle,
      });
      return false;
    }

    if (response.ok) {
      navigate(routes.thirdPartyApi.base);
      return true;
    }

    errorToast({ title: getHttpStatusDescription(response.status) });
    return false;
  }, [authentication, deleteApi, errorToast, navigate]);

  const {
    control,
    handleSubmit,
    register,
    setValue,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<ThirdPartyApiFormData>({ defaultValues });

  const { api } = watch();

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const content = useMemo(() => {
    if (isLoading || isLoadingAuthentications) {
      return <Skeleton height="200px" />;
    }

    if (thirdParties == null) {
      return <ErrorResult statusCode={statusCode} />;
    }

    return (
      <form onSubmit={handleSubmit(sendRequest)}>
        <Stack spacing="5">
          <SelectFormInput
            accessor="api"
            label="API"
            controllerDefaultValue={authentication?.thirdParty.thirdPartyID}
            defaultValue={{
              label: authentication?.thirdParty?.name,
              value: authentication?.thirdParty?.thirdPartyID,
            }}
            isDisabled={isSubmitting}
            isRequired={true}
            placeholder="Select an API"
            control={control}
            onChange={(newValue) => {
              const thirdParty = thirdParties.find(
                (tp) =>
                  tp.thirdPartyID === (newValue as SelectInputOption).value,
              );
              setValue('api', thirdParty?.thirdPartyID ?? '');
            }}
            options={thirdParties.map((tp) => ({
              label: tp.name,
              value: tp.thirdPartyID,
            }))}
          />
          {thirdParties.find((tp) => tp.thirdPartyID === api)?.api ===
            MainLegSource.WebCargo && (
            <>
              <SelectFormInput
                accessor="country"
                label="Country"
                controllerDefaultValue={authentication?.country?.countryID}
                defaultValue={
                  authentication?.country && {
                    label: authentication?.country?.name,
                    value: authentication?.country?.countryID,
                  }
                }
                isDisabled={isSubmitting}
                isRequired={true}
                placeholder="Select a Country"
                control={control}
                options={filteredCountries.map((country) => ({
                  label: country.name,
                  value: country.countryID,
                }))}
              />
              <FormInput
                accessor="customerKey1"
                label="Customer Key 1"
                register={register}
                errors={errors}
                isDisabled={isSubmitting}
                isRequired={true}
              />
              <FormInput
                accessor="customerKey2"
                label="Customer Key 2"
                register={register}
                errors={errors}
                isDisabled={isSubmitting}
                isRequired={true}
              />
            </>
          )}
          {thirdParties.find((tp) => tp.thirdPartyID === api)?.api ===
            MainLegSource.CargoSphere && (
            <>
              <FormInput
                accessor="username"
                label="Username"
                register={register}
                errors={errors}
                isDisabled={isSubmitting}
                isRequired={true}
              />
              <FormInput
                accessor="password"
                label="Password"
                register={register}
                errors={errors}
                isDisabled={isSubmitting}
                isRequired={true}
              />
              <FormInput
                accessor="environment"
                label="Environment"
                register={register}
                errors={errors}
                isDisabled={isSubmitting}
                isRequired={true}
              />
            </>
          )}
          {thirdParties.find((tp) => tp.thirdPartyID === api)?.api ===
            MainLegSource.CargoOne && (
            <>
              <FormInput
                accessor="customerKey1"
                label="Api key"
                register={register}
                errors={errors}
                isDisabled={isSubmitting}
                isRequired={true}
              />
              <FormInput
                accessor="customerKey2"
                label="User Key"
                register={register}
                errors={errors}
                isDisabled={isSubmitting}
                isRequired={true}
              />
            </>
          )}
          {thirdParties.find((tp) => tp.thirdPartyID === api)?.api ===
            MainLegSource.HapagLloyd && (
              <>
                <FormInput
                  accessor="hapagLloydClientId"
                  label="X-IBM-Client-Id"
                  register={register}
                  errors={errors}
                  isDisabled={isSubmitting}
                  isRequired={true}
                />
                <FormInput
                  accessor="hapagLloydClientSecret"
                  label="X-IBM-Client-Secret"
                  register={register}
                  errors={errors}
                  isDisabled={isSubmitting}
                  isRequired={true}
                />
              </>
            )}
        </Stack>
      </form>
    );
  }, [
    isLoading,
    isLoadingAuthentications,
    thirdParties,
    handleSubmit,
    sendRequest,
    authentication,
    isSubmitting,
    control,
    filteredCountries,
    register,
    errors,
    statusCode,
    setValue,
    api,
  ]);

  return (
    <Stack>
      <UnsavedChangesModal
        isOpen={isOpenBack}
        onCancel={onCloseBack}
        onSuccess={onCloseBack}
        route={routes.thirdPartyApi.base}
      />
      <HeadingCard
        heading={
          <>
            <Button
              onClick={onOpenBack}
              variant="outline"
              size="xs"
              mr="1rem"
              leftIcon={<ChevronLeftIcon h="4" w="4" />}
            >
              Back
            </Button>
            {authentication == null
              ? 'Add Third Party API'
              : `Edit ${authentication?.thirdParty.name}`}
          </>
        }
        direction="row"
        align="center"
        justify="space-between"
      >
        <HStack>
          {authentication != null && (
            <Button
              onClick={() => {
                onOpenDelete();
              }}
              size="xs"
              colorScheme="red"
              isDisabled={isSubmitting}
              rightIcon={<DeleteIcon w="3" h="3" />}
            >
              Delete
            </Button>
          )}
          <Button
            as={Link}
            to={routes.thirdPartyApi.base}
            size="xs"
            variant="ghost"
            colorScheme="white"
            color="grey.100"
            rightIcon={<CloseIcon w="5" h="5" />}
            _hover={{ bgColor: 'grey.600' }}
          >
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            color="grey.100"
            size="xs"
            onClick={handleSubmit(sendRequest)}
            isLoading={isSubmitting}
            rightIcon={<CheckIcon w="5" h="5" />}
          >
            Save
          </Button>
        </HStack>
      </HeadingCard>
      <MainCard heading="ThirdParty API Details">{content}</MainCard>
      <ActionModal
        onCancel={onCloseDelete}
        onSuccess={onCloseDelete}
        isOpen={isDeleteOpen}
        onActionClick={onDelete}
        modalText="Do you really want to delete this third party API?"
        modalHeader="Delete API"
      />
    </Stack>
  );
};
