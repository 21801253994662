import { tabsAnatomy } from '@chakra-ui/anatomy';
import { ComponentStyleConfig } from '@chakra-ui/react';
import { colors } from '../colors';

export const Tabs: ComponentStyleConfig = {
  parts: tabsAnatomy.keys,
  baseStyle: {
    tab: {
      color: 'white',
      fontWeight: 'bold',
      border: '1px',
      borderColor: colors.blue[500], //don't ask my why normal blue.500 is not working...
      _selected: {
        bg: 'blue.500',
        color: 'white',
        borderColor: 'blue.500',
      },
      _hover: {
        bg: 'blue.400',
      },
      _last: {
        borderRadius: '0px 6px 6px 0px',
      },
      _first: {
        borderRadius: '6px 0px 0px 6px',
      },
    },
    tablist: {
      border: '0px !important',
    },
  },
  defaultProps: {
    variant: '',
  },
};
