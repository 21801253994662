import { ComponentStyleConfig } from '@chakra-ui/react';

export const Table: ComponentStyleConfig = {
  parts: ['table', 'tbody', 'thead', 'td', 'th', 'tr'],
  baseStyle: {
    table: {
      borderCollapse: 'separate',
      borderSpacing: '0 0.4rem',
    },
    thead: {
      tr: {
        th: {
          fontWeight: '600',
          color: 'grey.400',
          border: 'none',
        },
      },
    },
    tbody: {
      tr: {
        borderWidth: '1px',
        borderColor: 'grey.600',
        borderRadius: '4px',
        _hover: {
          backgroundColor: 'grey.500',
        },
        td: {
          display: 'flex',
          alignItems: 'center',
          fontSize: '0.75rem',
          border: 'none',
        },
      },
    },
  },
  variants: {
    sticky: {
      thead: {
        tr: {
          th: {
            _last: {
              maxWidth: '7rem',
              position: 'sticky',
              right: 0,
              backgroundColor: 'grey.800',
            },
          },
        },
      },
      tbody: {
        tr: {
          td: {
            _last: {
              maxWidth: '7rem',
              borderLeft: '1px solid',
              borderColor: 'grey.700',
              position: 'sticky',
              right: 0,
              backgroundColor: 'grey.800',
              _hover: {
                backgroundColor: 'grey.500',
              },
            },
          },
        },
      },
    },
    fadedLight: {
      tbody: {
        tr: {
          backgroundColor: 'grey.750',
          td: {
            display: 'flex',
            alignItems: 'center',
            fontSize: '0.75rem',
            border: 'none',
            _last: {
              maxWidth: '7rem',
              position: 'sticky',
              mask: 'linear-gradient(to right,transparent, black 60%)',
              right: 0,
              backgroundColor: 'grey.750',
            },
          },
        },
      },
    },
    faded: {
      tbody: {
        tr: {
          td: {
            display: 'flex',
            alignItems: 'center',
            fontSize: '0.75rem',
            border: 'none',
            _last: {
              maxWidth: '7rem',
              position: 'sticky',
              mask: 'linear-gradient(to right,transparent, black 60%);',
              right: 0,
              backgroundColor: 'grey.800',
            },
          },
        },
      },
    },
  },
};
