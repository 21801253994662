export interface Unit {
  code: number;
  name: string;
}

enum UnitEnum {
  undefined = 0,
  t = 1,
  kg = 2,
  lbs = 3,
  m = 51,
  cm = 52,
  inch = 53,
  ft = 54,
}

export enum LengthUnit {
  m = UnitEnum.m,
  cm = UnitEnum.cm,
  inch = UnitEnum.inch,
  ft = UnitEnum.ft,
}

export enum WeightUnit {
  t = UnitEnum.t,
  kg = UnitEnum.kg,
  lbs = UnitEnum.lbs,
}
