import { createContext, useContext } from 'react';
import { Filters } from 'react-table';

interface ITableContext {
  setAllFilters: <T extends object>(
    updater: Filters<T> | ((filters: Filters<T>) => Filters<T>),
  ) => void;
}

const tableContext: ITableContext = {
  setAllFilters: () => {},
};

export const TableContext = createContext(tableContext);

export const useTableContext = () => useContext(TableContext);
