import type { Column } from 'react-table';
import { CalculatorTariff } from '../../../../types/CalculatorTariff';
import { MethodTariff } from '../../../../types/MethodTariff';
import { BracketWeightDto } from '../../../../types/api/BracketWeightDto';

export const getTariffColumns = (
  tariffWeightBrackets: BracketWeightDto[],
  method: MethodTariff,
  calculator: CalculatorTariff,
): Column[] => {
  const tableMethodHeader =
    method === MethodTariff.ZipCodes ? 'ZIP Code' : 'Distance Zone (KM)';
  const generatedColumns: Column[] = [
    {
      Header: tableMethodHeader,
      accessor: (rate: Record<string, any>) => rate.zone,
    },
  ];
  tariffWeightBrackets.forEach((cell, cellIndex) => {
    if (cell.isMinValue && calculator !== CalculatorTariff.FLT) {
      generatedColumns.push({
        Header: 'Minimum',
        accessor: (rate: Record<string, any>) => rate[cell.bracketWeightID],
      });
    } else {
      generatedColumns.push({
        Header: `Below ${cell.value} Kg`,
        id: `header-${cellIndex}`,
        accessor: (rate: Record<string, any>) => rate[cell.bracketWeightID],
      });
    }
  });
  return generatedColumns;
};
