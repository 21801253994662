import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/modal';
import { Button, Stack } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import { useCallback, useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { SelectFormInput, FormInput } from '../../../components/Input';
import { useErrorToast } from '../../../hooks/useErrorToast';
import { useSuccessToast } from '../../../hooks/useSuccessToast';
import { useApi } from '../../../providers/ApiProvider';
import { useData } from '../../../providers/DataProvider';
import type { Organization } from '../../../types';
import { ApprovalStatus } from '../../../types';
import { getHttpStatusDescription } from '../../../utils/httpStatus';

interface ModalProps {
  onClose: () => void;
  isOpen: boolean;
  data?: Organization | null;
}
const errorTitle = 'Saving your changes failed, please try again';

export const CreateOrganizationModal: FunctionComponent<ModalProps> = ({
  onClose,
  isOpen,
  data,
}) => {
  const { postApi, putApi } = useApi();
  const { countries } = useData();
  const successToast = useSuccessToast();
  const errorToast = useErrorToast();
  const {
    control,
    reset,
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  const upsertOrganization = useCallback(
    async (values: FieldValues) => {
      const postData = {
        name: values.name,
        code: '',
        addressText: values.address,
        qwykOrganizationId: values.qwykOrganizationId,
        mainCountry: countries.data?.find(
          (country) => country.countryID === values.country,
        ),
        approvalStatus: ApprovalStatus.APPROVED,
        isActive: true,
      };
      let response;

      try {
        response =
          data?.organizationID == null
            ? await postApi('organizations', postData)
            : await putApi(
                `organizations/${data.organizationID}/approve`,
                postData,
              );
      } catch (e) {
        errorToast({
          title: errorTitle,
        });
        return;
      }

      if (!response.ok) {
        errorToast({
          title: getHttpStatusDescription(response.status),
        });
        return;
      }
      // React hook form does not reset properly if initial value is undefined
      // https://github.com/react-hook-form/react-hook-form/discussions/7589
      reset({
        name: '',
        address: '',
        qwykOrganizationId: '',
      });
      successToast({
        title: 'Organization successfully created!',
      });
      onClose();
    },
    [
      countries.data,
      data?.organizationID,
      errorToast,
      onClose,
      postApi,
      putApi,
      reset,
      successToast,
    ],
  );

  useEffect(() => {
    reset();
  }, [data, reset]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        reset();
      }}
      isCentered={true}
    >
      <ModalOverlay />
      <ModalContent bg="grey.800">
        <form onSubmit={handleSubmit(upsertOrganization)}>
          <ModalHeader>Create new Organization</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="5">
              <FormInput
                label="Name"
                accessor="name"
                register={register}
                errors={errors}
                type="name"
                isDisabled={isSubmitting}
                isRequired={true}
                defaultValue={data?.name}
                registerOptions={{
                  maxLength: { value: 50, message: 'Maximum of 50 characters' },
                }}
              />
              <FormInput
                label="Address"
                accessor="address"
                register={register}
                errors={errors}
                type="address"
                isDisabled={isSubmitting}
                isRequired={true}
                defaultValue={data?.addressText}
                registerOptions={{
                  maxLength: {
                    value: 100,
                    message: 'Maximum of 100 characters',
                  },
                }}
              />
              <SelectFormInput
                label="Country"
                accessor="country"
                placeholder="Select a Country"
                isDisabled={isSubmitting}
                isRequired={true}
                defaultValue={{
                  label: data?.mainCountry?.name,
                  value: data?.mainCountry?.countryID,
                }}
                control={control}
                controllerDefaultValue={data?.mainCountry?.countryID}
                options={countries.data?.map((country) => ({
                  label: country.name,
                  value: country.countryID,
                }))}
              />
              <FormInput
                label="QWYK Hub ID"
                accessor="qwykOrganizationId"
                register={register}
                errors={errors}
                isDisabled={isSubmitting}
                isRequired={false}
                defaultValue={data?.qwykOrganizationId}
                registerOptions={{
                  maxLength: { value: 50, message: 'Maximum of 50 characters' },
                }}
              />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              w="full"
              colorScheme="blue"
              isLoading={isSubmitting}
            >
              Create Organization
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
