import {
  Text,
  Skeleton,
  Stack,
  HStack,
  Heading,
  SimpleGrid,
  Box,
  Divider,
  Grid,
  GridItem,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Link,
} from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';
import {
  FormInput,
  SelectFormInput,
  TextareaFormInput,
} from '../../components/Input';
import { MarkdownContainer } from '../../components/Output/MarkdownContainer';
import { OrgSettingsWarningBox } from '../../components/Output/OrgSettingsWarningBox';
import { LengthUnit, WeightUnit } from '../../types';
import { OrganizationForm, OrganizationSettings } from '.';

interface IOrganizationSettingsFieldsProps {
  isLoading: boolean;
  isSubmitting: boolean;
  register: UseFormRegister<OrganizationForm>;
  control: Control<OrganizationForm>;
  organizationSettings?: OrganizationSettings;
  watch: UseFormWatch<OrganizationForm>;
  errors: FieldErrors<OrganizationForm>;
}

export const OrganizationSettingsFields: FunctionComponent<IOrganizationSettingsFieldsProps> =
  ({
    register,
    isLoading,
    isSubmitting,
    organizationSettings,
    control,
    watch,
    errors,
  }) => {
    if (isLoading) {
      return <Skeleton height="200px" />;
    }

    if (organizationSettings == null) {
      return null;
    }
    const maxInput = 999_999_999;
    const getNavigatorLanguage = () =>
      navigator.languages && navigator.languages.length
        ? navigator.languages[0]
        : navigator.language || 'en';
    const defaultOrgSettingsInputProps = {
      register,
      errors,
      isDisabled: isSubmitting,
      maxWidth: '20rem',
      type: 'number',
      mb: '2',
      registerOptions: {
        max: {
          value: maxInput,
          message:
            'Must be below ' +
            (maxInput + 1).toLocaleString(getNavigatorLanguage()),
        },
      },
    };

    const getUnitElement = (unitType: 'length' | 'weight' | 'volume') => {
      switch (unitType) {
        case 'length':
          return <Box mr="4">{LengthUnit[watch('lengthUnit')]}</Box>;
        case 'weight':
          return <Box mr="4">{WeightUnit[watch('weightUnit')]}</Box>;
        case 'volume':
          return <Box mr="4">cbm</Box>;
      }
    };

    return (
      <Stack>
        <HStack>
          <Heading as="h2" variant="h2">
            Instant Quotation Rules
          </Heading>
          <Text color="grey.300">Add rules for your quotations</Text>
        </HStack>
        <SimpleGrid columns={3} spacing="8" pb="8">
          <Stack>
            <SelectFormInput
              accessor="weightUnit"
              label="Weight Unit"
              isDisabled={defaultOrgSettingsInputProps.isDisabled}
              isRequired={true}
              control={control}
              value={{
                label: WeightUnit[watch('weightUnit')],
                value: watch('weightUnit'),
              }}
              options={Object.keys(WeightUnit)
                .filter((unit) => isNaN(Number(unit)))
                .map((unit) => ({
                  label: unit,
                  value:
                    Object.keys(WeightUnit)[
                      Object.values(WeightUnit).indexOf(unit)
                    ],
                }))}
              wrapperProps={{ maxWidth: defaultOrgSettingsInputProps.maxWidth }}
            />
          </Stack>
          <Stack>
            <SelectFormInput
              accessor="lengthUnit"
              label="Length Unit"
              isDisabled={defaultOrgSettingsInputProps.isDisabled}
              isRequired={true}
              control={control}
              value={{
                label: LengthUnit[watch('lengthUnit')],
                value: watch('lengthUnit'),
              }}
              options={Object.keys(LengthUnit)
                .filter((unit) => isNaN(Number(unit)))
                .map((unit) => ({
                  label: unit,
                  value:
                    Object.keys(LengthUnit)[
                      Object.values(LengthUnit).indexOf(unit)
                    ],
                }))}
              wrapperProps={{ maxWidth: defaultOrgSettingsInputProps.maxWidth }}
            />
          </Stack>
          <Box></Box>
        </SimpleGrid>
        <Divider my="8" border="2px" borderColor="black" />
        <SimpleGrid columns={3} spacing="8" py="8">
          <Stack>
            <Heading as="h3" size="sm">
              Air
            </Heading>
            <FormInput
              accessor="airMaxLength"
              label="Max length"
              isRequired={true}
              rightElement={getUnitElement('length')}
              {...defaultOrgSettingsInputProps}
            />
            <FormInput
              accessor="airMaxWidth"
              label="Max width"
              isRequired={true}
              rightElement={getUnitElement('length')}
              {...defaultOrgSettingsInputProps}
            />
            <FormInput
              accessor="airMaxHeight"
              label="Max height"
              isRequired={true}
              rightElement={getUnitElement('length')}
              {...defaultOrgSettingsInputProps}
            />
            <FormInput
              accessor="airMaxWeight"
              label="Max weight"
              isRequired={true}
              rightElement={getUnitElement('weight')}
              {...defaultOrgSettingsInputProps}
            />
            <FormInput
              accessor="airMaxVolume"
              label="Max volume"
              rightElement={getUnitElement('volume')}
              {...defaultOrgSettingsInputProps}
            />
          </Stack>
          <Stack>
            <Heading as="h3" size="sm">
              LCL
            </Heading>
            <FormInput
              accessor="lclMaxLength"
              label="Max length"
              isRequired={true}
              rightElement={getUnitElement('length')}
              {...defaultOrgSettingsInputProps}
            />
            <FormInput
              accessor="lclMaxWidth"
              label="Max width"
              isRequired={true}
              rightElement={getUnitElement('length')}
              {...defaultOrgSettingsInputProps}
            />
            <FormInput
              accessor="lclMaxHeight"
              label="Max height"
              isRequired={true}
              rightElement={getUnitElement('length')}
              {...defaultOrgSettingsInputProps}
            />
            <FormInput
              accessor="lclMaxWeight"
              label="Max weight"
              isRequired={true}
              rightElement={getUnitElement('weight')}
              {...defaultOrgSettingsInputProps}
            />
            <FormInput
              accessor="lclMaxVolume"
              label="Max volume"
              isRequired={true}
              rightElement={getUnitElement('volume')}
              {...defaultOrgSettingsInputProps}
            />
          </Stack>
          <OrgSettingsWarningBox />
        </SimpleGrid>
        <Divider border="2px" borderColor="black" />
        <Grid templateColumns="repeat(3, 1fr)" gap="8" pt="8">
          <GridItem>
            <Heading as="h2" variant="h2" mb="4">
              Default Quotation Validity
            </Heading>
            <FormInput
              accessor="defaultQuotationValidity"
              label="Days"
              isRequired={true}
              {...defaultOrgSettingsInputProps}
              registerOptions={{
                max: {
                  value: 999,
                  message: 'Must be below 1000',
                },
              }}
            />
          </GridItem>
          <GridItem colSpan={2}>
            <Heading as="h2" variant="h2" mb="4">
              Minimum Weight for Instant Quotations
            </Heading>
            <FormInput
              accessor="airMinWeight"
              label="Air"
              rightElement={getUnitElement('weight')}
              {...defaultOrgSettingsInputProps}
              registerOptions={{
                max: {
                  value: watch('airMaxWeight'),
                  message: 'Min weight should be smaller than max weight',
                },
              }}
            />

            <FormInput
              accessor="lclMinWeight"
              label="LCL"
              rightElement={getUnitElement('weight')}
              {...defaultOrgSettingsInputProps}
              registerOptions={{
                max: {
                  value: watch('lclMaxWeight'),
                  message: 'Min weight should be smaller than max weight',
                },
              }}
            />
          </GridItem>
          <GridItem colSpan={3}>
            <Divider my="8" border="2px" borderColor="black" />
            <Heading as="h2" variant="h2" mb="4">
              Internal Remarks
            </Heading>
            <Text>
              Remarks are written in Markdown. A sheet with the most important
              elements can be found{' '}
              <Link
                target="_blank"
                href="https://www.markdownguide.org/cheat-sheet/#basic-syntax"
              >
                here
              </Link>
            </Text>
            <TextareaFormInput
              label=""
              accessor="internalRemarks"
              register={register}
              errors={errors}
              isRequired={false}
              isDisabled={isSubmitting}
              defaultValue={organizationSettings?.internalRemarks}
              height="7.5rem"
            />
            <Accordion allowToggle={true} mt="2">
              <AccordionItem border="none">
                <AccordionButton>
                  Preview
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel px="0">
                  <Box
                    borderWidth="1px"
                    borderColor="grey.600"
                    padding="1.5rem"
                    borderRadius="4px"
                  >
                    <MarkdownContainer
                      markdown={watch('internalRemarks') ?? ''}
                    />
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </GridItem>
          <GridItem colSpan={3}>
            <Divider my="8" border="2px" borderColor="black" />
            <Heading as="h2" variant="h2" mb="4">
              External Remarks
            </Heading>
            <Text>
              Remarks are written in Markdown. A sheet with the most important
              elements can be found{' '}
              <Link
                target="_blank"
                href="https://www.markdownguide.org/cheat-sheet/#basic-syntax"
              >
                here
              </Link>
            </Text>
            <TextareaFormInput
              label=""
              accessor="externalRemarks"
              register={register}
              errors={errors}
              isRequired={false}
              isDisabled={isSubmitting}
              defaultValue={organizationSettings?.externalRemarks}
              resize="none"
              height="7.5rem"
            />
            <Accordion allowToggle={true} mt="2">
              <AccordionItem border="none">
                <AccordionButton>
                  Preview
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel px="0">
                  <Box
                    borderWidth="1px"
                    borderColor="grey.600"
                    padding="1.5rem"
                    borderRadius="4px"
                  >
                    <MarkdownContainer
                      markdown={watch('externalRemarks') ?? ''}
                    />
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </GridItem>
        </Grid>
      </Stack>
    );
  };
