import { useEffect, useState } from 'react';
import { Control, FieldErrors } from 'react-hook-form';
import { Column } from 'react-table';
import { CalculatorTariff } from '../../../../types/CalculatorTariff';
import { MethodTariff } from '../../../../types/MethodTariff';
import { BracketWeightDto } from '../../../../types/api/BracketWeightDto';
import { TariffRateDto } from '../../../../types/api/TariffRateDto';
import { getEmptyBrackets } from '../utils/getEmptyBrackets';
import { getEmptyRows } from '../utils/getEmptyRows';
import { getPrefilledBrackets } from '../utils/getPrefilledBrackets';
import { getPrefilledRows } from '../utils/getPrefilledRows';
import { getSortedRateRows } from '../utils/getSortedRateRows';
import { getTariffColumns } from '../utils/getTariffColumns';
import { getTariffEditColumns } from '../utils/getTariffEditColumns';

export const useDynamicTariffTable = (
  errors: FieldErrors,
  register: any,
  control: Control<any>,
  isEdit: boolean,
  columnCount: number,
  rowCount: number,
  method: MethodTariff,
  calculator: CalculatorTariff,
  country: string,
  savedForm?: Record<string, unknown>,
  weightBrackets?: BracketWeightDto[],
  tariffRatesSorted?: TariffRateDto[][],
  setValue?: (zoneKey: string, newValue: string) => void,
) => {
  const [columns, setColumns] = useState<Column[]>([]);
  const [rows, setRows] = useState<Record<string, string>[]>([]);

  const [initialFormWeightBrackets, setInitialFormWeightBrackets] = useState<
    BracketWeightDto[]
  >(weightBrackets ? weightBrackets : getEmptyBrackets(columnCount, savedForm));

  const [initialFormTariffRates, setInitialFormTariffRates] = useState<
    TariffRateDto[][]
  >(
    tariffRatesSorted
      ? tariffRatesSorted
      : getEmptyRows(columnCount, rowCount, savedForm),
  );

  const [formWeightBrackets, setFormWeightBrackets] = useState<
    BracketWeightDto[]
  >(initialFormWeightBrackets);
  const [formTariffRates, setFormTariffRates] = useState<TariffRateDto[][]>(
    initialFormTariffRates,
  );

  useEffect(() => {
    if (!formWeightBrackets || !formTariffRates) {
      return;
    }
    if (isEdit) {
      setColumns(
        getTariffEditColumns(
          formWeightBrackets,
          errors,
          register,
          control,
          method,
          calculator,
          country,
          setValue,
        ),
      );
      setRows(getSortedRateRows(formTariffRates, false));
    } else {
      setColumns(getTariffColumns(formWeightBrackets, method, calculator));
      setRows(getSortedRateRows(formTariffRates));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formWeightBrackets,
    formTariffRates,
    isEdit,
    errors,
    register,
    control,
    setValue,
    method,
    calculator,
  ]);

  useEffect(() => {
    setFormWeightBrackets(
      formWeightBrackets
        ? getPrefilledBrackets(formWeightBrackets, columnCount, savedForm)
        : getEmptyBrackets(columnCount, savedForm),
    );
    setFormTariffRates(
      formTariffRates
        ? getPrefilledRows(formTariffRates, columnCount, rowCount)
        : getEmptyRows(columnCount, rowCount, savedForm),
    );
    // this is intentional, as formTariffRates is used to provide values for itself, when bracket size changes
    // eslint-disable-next-line
  }, [rowCount, columnCount]);

  useEffect(() => {
    setFormWeightBrackets(initialFormWeightBrackets);
    setFormTariffRates(initialFormTariffRates);
  }, [initialFormWeightBrackets, initialFormTariffRates]);

  useEffect(() => {
    if (weightBrackets) {
      setInitialFormWeightBrackets(weightBrackets);
    }
    if (tariffRatesSorted) {
      setInitialFormTariffRates(tariffRatesSorted);
    }
  }, [weightBrackets, tariffRatesSorted]);

  return {
    columnCount,
    rowCount,
    columns,
    rows,
  };
};
