import { Link } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

interface IFooterLinkProps {
  linkText: string;
  href: string;
}

export const FooterLink: FunctionComponent<IFooterLinkProps> = ({
  linkText,
  href,
}) => (
  <Link p="2" target="_blank" color="gray.400" fontSize="small" href={href}>
    {linkText}
  </Link>
);
