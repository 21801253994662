import {
  Button,
  Checkbox,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';
import { FunctionComponent, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { SelectFormInput } from '../../../components/Input';
import { useApi } from '../../../providers/ApiProvider';
import { useData } from '../../../providers/DataProvider';
import { NetworkCountry } from '../../../types/NetworkCountry';

interface IAssignCountryModal {
  assignedCountries: NetworkCountry[];
  isOpen: boolean;
  onClose: CallableFunction;
}

interface IAssignCountryFormData {
  organizationId: string;
  countryId: string;
  isPrivate: boolean;
}

export const AssignCountryModal: FunctionComponent<IAssignCountryModal> = ({
  assignedCountries,
  isOpen,
  onClose,
}) => {
  const { countries } = useData();
  const { id: organizationId } = useParams();
  const { postApi } = useApi();
  const { control, reset, register, handleSubmit } =
    useForm<IAssignCountryFormData>({
      defaultValues: {
        organizationId,
        countryId: undefined,
        isPrivate: false,
      },
    });

  const onCloseModal = () => {
    reset();
    onClose();
  };

  const onSubmit = async (data: IAssignCountryFormData) => {
    const response = await postApi(
      `organizations/${data.organizationId}/assignedNetworkCountries`,
      { countryId: data.countryId, isPrivate: data.isPrivate },
    );
    if (response.ok) {
      onCloseModal();
    }
  };

  const assignableCountries = useMemo(
    () =>
      countries.data?.filter(
        (country) =>
          !assignedCountries.some(
            (assigned) => assigned.country.countryID === country.countryID,
          ),
      ),
    [assignedCountries, countries.data],
  );

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>
          <Heading fontSize="lg">Assign new country</Heading>
        </ModalHeader>
        <ModalBody>
          <VStack alignItems="flex-start" spacing="4">
            <SelectFormInput
              accessor="countryId"
              label="Country"
              isRequired={true}
              placeholder="Select a country"
              options={assignableCountries?.map((c) => ({
                label: c.name,
                value: c.countryID,
              }))}
              control={control}
            />
            <Checkbox {...register('isPrivate')}>Is Private</Checkbox>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            mr={3}
            onClick={() => onCloseModal()}
            colorScheme="white"
            variant="ghost"
            _hover={{ bgColor: 'grey.600' }}
          >
            Cancel
          </Button>
          <Button type="submit" colorScheme="blue">
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
