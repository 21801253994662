import create from 'zustand';
import { Country, Port } from '../types';
import { CreateTariffMetaValues } from '../types/forms/CreateTariffMetaValues';

interface TariffCreateStore {
  metaForm: CreateTariffMetaValues | undefined;
  setMetaForm: (formValues?: CreateTariffMetaValues) => void;
  rateForm: Record<string, unknown> | undefined;
  setRateForm: (formValues?: Record<string, unknown>) => void;
  country: Country | undefined;
  setCountry: (country?: Country) => void;
  selectedAirports: Port[];
  setSelectedAirports: (ports: Port[]) => void;
  selectedPorts: Port[];
  setSelectedPorts: (ports: Port[]) => void;
  reset: () => void;
}

export const useTariffCreateStore = create<TariffCreateStore>((set) => ({
  metaForm: undefined,
  setMetaForm: (formValues) => set(() => ({ metaForm: formValues })),
  rateForm: undefined,
  setRateForm: (formValues) => set(() => ({ rateForm: formValues })),
  country: undefined,
  setCountry: (country) => set(() => ({ country: country })),
  selectedAirports: [],
  setSelectedAirports: (ports) => set(() => ({ selectedAirports: ports })),
  selectedPorts: [],
  setSelectedPorts: (ports) => set(() => ({ selectedPorts: ports })),
  reset: () =>
    set(() => ({
      metaForm: undefined,
      rateForm: undefined,
      country: undefined,
      selectedAirports: [],
      selectedPorts: [],
    })),
}));
