import { useToast, UseToastOptions } from '@chakra-ui/react';

export const useSuccessToast = (options?: UseToastOptions) =>
  useToast({
    status: 'success',
    isClosable: true,
    position: 'top',
    variant: 'solid',
    ...options,
  });
