import { chakra, Heading, useMultiStyleConfig } from '@chakra-ui/react';
import type { HeadingProps } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';

interface ListHeadingProps extends HeadingProps {
}

export const ListHeading: FunctionComponent<ListHeadingProps> = ({ children, ...props }) => {
  const styles = useMultiStyleConfig('ListHeading', props);

  return (
    <Heading as="h4" sx={styles.heading} {...props}>
      <chakra.span sx={styles.text}>{children}</chakra.span>
    </Heading>
  );
};
