import { FormControl, FormLabel, SelectProps } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import ReactSelect from 'react-select';
import { useReactSelectStylings } from '../../hooks/useReactSelectStylings';
import { FormInputProps } from './FormInput';

interface SelectInputOption {
  label: string;
  value: string;
}

interface InputProps
  extends Omit<FormInputProps, 'register' | 'errors'>,
    SelectProps {
  options: SelectInputOption[];
  currentValue?: string;
  onSelectionChange: (options: SelectInputOption) => void;
  width: string;
  noOptionsMessage?: string;
}

export const DeselectableSelectInput: FunctionComponent<InputProps> = ({
  onSelectionChange,
  label,
  accessor,
  options,
  width,
  isRequired,
  isDisabled,
  currentValue,
  noOptionsMessage,
}) => {
  const styles = useReactSelectStylings(width, !!currentValue, isDisabled);

  return (
    <FormControl isInvalid={false} isRequired={isRequired} width={width}>
      <FormLabel htmlFor={accessor}>{label}</FormLabel>
      <ReactSelect
        id={accessor}
        styles={styles}
        options={options}
        isClearable={true}
        isDisabled={isDisabled}
        value={options.filter((option) => option.value === currentValue)}
        onChange={(value) => onSelectionChange(value as SelectInputOption)}
        noOptionsMessage={(obj) =>
          obj?.inputValue === '' ? noOptionsMessage : 'No results found'
        }
        aria-label={accessor}
      />
    </FormControl>
  );
};
