import { chakra, HTMLChakraProps } from '@chakra-ui/system';

export const LCLIcon = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg viewBox="0 -960 960 960" {...props}>
    <g
      id="Icons/LCL"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        fill="currentColor"
        fillRule="nonzero"
        id="Shape"
        d="M440-183v-274L200-596v274l240 139Zm80 0 240-139v-274L520-457v274Zm-80 92L160-252q-19-11-29.5-29T120-321v-318q0-22 10.5-40t29.5-29l280-161q19-11 40-11t40 11l280 161q19 11 29.5 29t10.5 40v318q0 22-10.5 40T800-252L520-91q-19 11-40 11t-40-11Zm200-528 77-44-237-137-78 45 238 136Zm-160 93 78-45-237-137-78 45 237 137Z"
      />
    </g>
  </chakra.svg>
);
