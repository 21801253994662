import { generateParamString } from './generateParamString';

// TODO: write types for params
export const API_ROUTES = {
  tariffsTrucking: (params?: Record<string, unknown>) =>
    `tariffs/trucking?${generateParamString(params)}`,
  tariffsTruckingSingle: (
    tariffId: string | number,
    params?: Record<string, unknown>,
  ) => `tariffs/trucking/${tariffId}?${generateParamString(params)}`,
  truckingSurcharge() {
    return `rates/trucking-surcharge`;
  },
  tariffsTruckingExpired: (params?: Record<string, unknown>)=> `tariffs/trucking/expired?${generateParamString(params)}`,
  ratesTruckingExpired: `rates/trucking`,
  ratesLocalChargesExpired: `rates/localcharges`,
  ratesAirExpired: `rates/air`,
  ratesSeaFCLExpired: `rates/sea-fcl`,
  ratesSeaLCLExpired: `rates/sea-lcl`,
  externalQuickQoute: (quoteID: string | number) =>`quotes/external/${quoteID}`
};
