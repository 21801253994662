import { Button, HStack } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { HeadingCard } from '../../../../components/Layout';
import { useAuthorization } from '../../../../providers/AuthorizationProvider';
import { UserRole } from '../../../../types';

interface TariffCreateHeadingProps {
  tariffTitle: string;
  onNext: () => void;
  onClose: () => void;
  onBack: () => void;
}

export const TariffCreateHeading: FunctionComponent<
  TariffCreateHeadingProps
> = ({ tariffTitle, onNext, onClose, onBack }) => {
  const { userRole } = useAuthorization();

  const canMutate = userRole !== UserRole.STANDARD_USER;

  const handleTariffBack = () => {
    onBack();
  };

  const handleCancelClick = () => {
    onClose();
  };

  const handleNextClick = () => {
    onNext();
  };

  return (
    <>
      <HeadingCard
        heading={tariffTitle ? tariffTitle : 'Untitled Tariff'}
        direction="row"
        align="center"
        justify="space-between"
        onBack={handleTariffBack}
      >
        {canMutate && (
          <HStack>
            <Button colorScheme="white" size="xs" onClick={handleCancelClick}>
              Cancel
            </Button>
            <Button colorScheme="blue" size="xs" onClick={handleNextClick}>
              Next
            </Button>
          </HStack>
        )}
      </HeadingCard>
    </>
  );
};
