import { Button } from '@chakra-ui/button';
import { Heading, HStack } from '@chakra-ui/react';
import React, { FunctionComponent, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { PlusIcon } from '../../../components/Icons';
import { Card, HeadingCard, MainCard } from '../../../components/Layout';
import { TariffRateTable } from '../../../components/Tables/TariffRateTable';
import { useTariffCreateStore } from '../../../hooks/useTariffCreateStore';
import { useAuthorization } from '../../../providers/AuthorizationProvider';
import { routes } from '../../../utils/routes';
import { checkCanMutateRates } from '../utils/ratePermissions';
import { TruckingChargeFilter } from './components/TruckingChargeFilter';
import { useTariffsTruckingApi } from './hooks/useTariffsTruckingApi';
import { useTruckingColumns } from './hooks/useTruckingColumns';

export const TruckingPage: FunctionComponent = () => {
  const { userCountries, userRole } = useAuthorization();
  const navigate = useNavigate();
  const { reset } = useTariffCreateStore();

  const { columns } = useTruckingColumns();
  const { isLoading, statusCode, fetchFailed, rates, countryId } =
    useTariffsTruckingApi(userCountries);

  const canMutateRates = checkCanMutateRates(userRole, userCountries, [
    countryId,
  ]);

  const handleAddTruckingTariff = () => {
    navigate(routes.charges.tariffCreate);
  };

  useEffect(() => {
    // Reset saved Tariff Create store state on current page visit
    reset();
  }, [reset]);

  return (
    <>
      <HeadingCard heading="Trucking Charges" />
      <Card>
        <TruckingChargeFilter />
      </Card>
      <MainCard>
        <HStack justifyContent="space-between">
          <Heading as="h2" variant="h2">
            Tariffs
          </Heading>
          {canMutateRates && (
            <Button
              leftIcon={<PlusIcon w="4" h="4" />}
              size="xs"
              colorScheme="secondary"
              onClick={() => handleAddTruckingTariff()}
              isDisabled={userCountries.length === 0}
            >
              Add Trucking Tariff
            </Button>
          )}
        </HStack>
        <TariffRateTable
          data={rates}
          columns={columns}
          isLoading={isLoading}
          fetchFailed={fetchFailed}
          statusCode={statusCode}
          showActionColumn={canMutateRates}
        />
      </MainCard>
    </>
  );
};
