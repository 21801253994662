import {
  Button,
  Divider,
  FormLabel,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FunctionComponent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { NumberFormInput } from '../../../../components/Input';
import { useData } from '../../../../providers/DataProvider';
import { TransportMode } from '../../../../types';
import { TruckingSurcharge } from '../../../../types/TruckingSurcharge';

export interface EditTruckingSurchargeValues
  extends Required<
    Omit<TruckingSurcharge, 'id' | 'country' | 'transportMode'>
  > {}

interface IUpsertSurchargeSettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (data: EditTruckingSurchargeValues) => Promise<boolean>;
  surchargeValues: EditTruckingSurchargeValues;
  countryId?: string;
  transportMode: TransportMode;
}

export const UpsertSurchargeSettingsModal: FunctionComponent<IUpsertSurchargeSettingsModalProps> =
  ({ isOpen, onClose, onSave, surchargeValues, transportMode, countryId }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { countries } = useData();

    const { handleSubmit, reset, control } =
      useForm<EditTruckingSurchargeValues>({
        defaultValues: { ...surchargeValues },
      });

    useEffect(() => {
      reset({ ...surchargeValues });
    }, [reset, surchargeValues]);

    const onSubmit = async (item: EditTruckingSurchargeValues) => {
      setIsSubmitting(true);
      let success: boolean = await onSave(item);
      setIsSubmitting(false);
      if (success) {
        onClose();
        reset();
      }
    };
    const handleClose = () => {
      onClose();
      reset();
    };

    return (
      <>
        <Modal isOpen={isOpen} onClose={handleClose}>
          <ModalOverlay />
          <ModalContent
            bg="grey.800"
            minWidth={{ base: '20rem', md: '37.5rem' }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <ModalCloseButton />
              <ModalHeader textAlign="center" borderBottom="4px" borderColor="grey.900">
                Edit Trucking Surcharges
              </ModalHeader>
              <ModalBody py="8" px={{ base: '7', md: '14' }}>
                <Stack spacing="4">
                  <SimpleGrid
                    columns={2}
                    gap="4"
                    w={{ base: 'full', lg: '50%' }}
                  >
                    <FormLabel mb="0">Country</FormLabel>
                    <Text fontSize="xs">
                      {
                        countries.data?.find(
                          (country) => country.countryID === countryId,
                        )?.name
                      }
                    </Text>
                    <FormLabel mb="0">Transport Type</FormLabel>
                    <Text fontSize="xs">{TransportMode[transportMode]}</Text>
                  </SimpleGrid>
                  <Divider
                    borderColor="grey.900"
                    borderWidth="1px"
                    opacity="unset"
                  />
                  <Grid
                    gap={{ base: 8, lg: 16 }}
                    templateColumns={{
                      base: 'repeat(1, 1fr)',
                      lg: 'repeat(2, 1fr)',
                    }}
                  >
                    <GridItem>
                      <NumberFormInput
                        accessor="percentFuel"
                        label="Fuel Surcharge"
                        precision={0}
                        step={1}
                        isRequired={true}
                        control={control}
                        rightElement={<Text fontSize="xs">%</Text>}
                      />
                    </GridItem>
                    <GridItem>
                      <NumberFormInput
                        accessor="chargeableWeightRatio"
                        label="Chargeable Weight Ratio"
                        precision={2}
                        step={1}
                        isRequired={true}
                        control={control}
                        rightElement={
                          <Text marginLeft="-6" fontSize="xs">
                            kg/cbm
                          </Text>
                        }
                      />
                    </GridItem>
                  </Grid>
                </Stack>
              </ModalBody>
              <ModalFooter>
                <Button
                  mr={3}
                  onClick={handleClose}
                  colorScheme="white"
                  variant="ghost"
                  _hover={{ bgColor: 'grey.600' }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  colorScheme="blue"
                  isLoading={isSubmitting}
                >
                  Save
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      </>
    );
  };
