import { HStack, Link, Text, LinkProps } from '@chakra-ui/layout';
import type { FunctionComponent, ReactNode } from 'react';
import {
  Link as RouterLink,
  useMatch,
  useResolvedPath,
} from 'react-router-dom';

interface NavLinkProps extends LinkProps {
  title: string;
  to: string;
  icon?: ReactNode;
}

export const NavLink: FunctionComponent<NavLinkProps> = ({
  title,
  icon,
  to,
  px = '2',
  py = '2',
  ...rest
}) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link
      variant='NavItem'
      as={RouterLink}
      to={to}
      px={px}
      py={py}
      _hover={{ backgroundColor: 'grey.500', color: 'blue.500' }}
      aria-current={match ? 'page' : undefined}
      _activeLink={{ backgroundColor: 'grey.500', color: 'blue.500' }}
      borderRadius="6"
      {...rest}
    >
      <HStack>
        {icon}
        <Text variant="navlink">{title}</Text>
      </HStack>
    </Link>
  );
};
