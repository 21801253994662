import { chakra, HTMLChakraProps } from '@chakra-ui/system';

export const QuotationIcon = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg viewBox="0 0 24 24" {...props}>
    <g
      id="Icons/Quotation"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M14,2 L6,2 C4.9,2 4,2.9 4,4 L4,20 C4,21.1 4.9,22 6,22 L18,22 C19.1,22 20,21.1 20,20 L20,8 L14,2 Z M6,20 L6,4 L13,4 L13,8 L18,8 L18,20 L6,20 Z M11,19 L13,19 L13,18 L14,18 C14.55,18 15,17.55 15,17 L15,14 C15,13.45 14.55,13 14,13 L11,13 L11,12 L15,12 L15,10 L13,10 L13,9 L11,9 L11,10 L10,10 C9.45,10 9,10.45 9,11 L9,14 C9,14.55 9.45,15 10,15 L13,15 L13,16 L9,16 L9,18 L11,18 L11,19 Z"
        id="Shape-Copy-2"
        fill="currentColor"
        fillRule="nonzero"
      ></path>
    </g>
  </chakra.svg>
);
