import { modalAnatomy } from '@chakra-ui/anatomy';
import { ComponentStyleConfig } from '@chakra-ui/react';

export const Modal: ComponentStyleConfig = {
  parts: modalAnatomy.keys,
  baseStyle: {
    dialog: {
      bg: 'grey.800',
    },
  },
};
