import {
  Box,
  Flex,
  FormLabel,
  IconButton,
  useBreakpointValue,
} from '@chakra-ui/react';
import { FunctionComponent, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import shallow from 'zustand/shallow';
import { SwitchIcon } from '../../../../components/Icons/SwitchIcon';
import { DeselectableSelectInput } from '../../../../components/Input/DeselectableSelectInput';
import { useSeaLclFilters } from '../../../../hooks/useSeaLclFilters';
import { useApi } from '../../../../providers/ApiProvider';
import { useAuthorization } from '../../../../providers/AuthorizationProvider';
import { useData } from '../../../../providers/DataProvider';
import type { Port } from '../../../../types';
import { PortType } from '../../../../types';

export const SeaLclChargeFilter: FunctionComponent = () => {
  const { userCountries } = useAuthorization();
  const { getApi } = useApi();
  const { countries } = useData();
  const onMobile = useBreakpointValue({ base: true, xl: false });
  const [isUserOrigin, setIsUserOrigin] = useState(true);
  const [originPorts, setOriginPorts] = useState<Port[]>([]);
  const [destinationPorts, setDestinationPorts] = useState<Port[]>([]);

  const {
    originPortId,
    destinationPortId,
    destinationCountryId,
    originCountryId,
    setDestinationPortId,
    setOriginPortId,
    setDestinationCountryId,
    setOriginCountryId,
    setPorts,
  } = useSeaLclFilters(
    (state) => ({
      originPortId: state.originPortId,
      destinationPortId: state.destinationPortId,
      originCountryId: state.originCountryId,
      destinationCountryId: state.destinationCountryId,
      setOriginPortId: state.setOriginPortId,
      setDestinationPortId: state.setDestinationPortId,
      setOriginCountryId: state.setOriginCountryId,
      setDestinationCountryId: state.setDestinationCountryId,
      setPorts: state.setPorts,
    }),
    shallow,
  );

  const { data: ports } = useQuery<Port[] | undefined>('ports', async () => {
    const result = await getApi(`ports?portType=${PortType.Sea}`);

    if (result.ok) {
      const data = (await result.json()) as Port[];
      setPorts(data);
      return data;
    }
  });

  const filterPorts = (
    origCountryId: string | undefined,
    destCountryId: string | undefined,
    origPortId: string | undefined,
    destPortId: string | undefined,
  ) => {
    let filteredOriginPorts: Port[] = [];
    let filteredDestinationPorts: Port[] = [];

    const originPort = ports?.find((port) => port.portID === origPortId);
    const destinationPort = ports?.find((port) => port.portID === destPortId);

    if (isUserOrigin) {
      filteredOriginPorts =
        ports?.filter(
          (port) =>
            port.country.countryID !== destCountryId &&
            port.country.countryID !== destinationPort?.country.countryID &&
            (origCountryId === undefined ||
              port.country.countryID === origCountryId) &&
            userCountries
              .map((country) => country.countryID)
              .includes(port.country.countryID),
        ) ?? [];
      filteredDestinationPorts =
        ports?.filter(
          (port) =>
            port.country.countryID !== origCountryId &&
            port.country.countryID !== originPort?.country.countryID &&
            (destCountryId === undefined ||
              port.country.countryID === destCountryId),
        ) ?? [];
    } else {
      filteredOriginPorts =
        ports?.filter(
          (port) =>
            port.country.countryID !== destCountryId &&
            port.country.countryID !== destinationPort?.country.countryID &&
            (origCountryId === undefined ||
              port.country.countryID === origCountryId),
        ) ?? [];
      filteredDestinationPorts =
        ports?.filter(
          (port) =>
            port.country.countryID !== origCountryId &&
            port.country.countryID !== originPort?.country.countryID &&
            (destCountryId === undefined ||
              port.country.countryID === destCountryId) &&
            userCountries
              .map((country) => country.countryID)
              .includes(port.country.countryID),
        ) ?? [];
    }

    setOriginPorts(filteredOriginPorts);
    setDestinationPorts(filteredDestinationPorts);
  };

  useEffect(() => {
    filterPorts(
      originCountryId,
      destinationCountryId,
      originPortId,
      destinationPortId,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ports]);

  const filterCountries = (
    isUserCountries: boolean,
    otherSideSelectedCountryId: string | undefined,
    otherSideSelectedPortId: string | undefined,
  ) => {
    const otherSidePort = ports?.find(
      (port) => port.portID === otherSideSelectedPortId,
    );

    if (isUserCountries) {
      return userCountries?.filter(
        (country) =>
          country.countryID !== otherSideSelectedCountryId &&
          country.countryID !== otherSidePort?.country.countryID,
      );
    }

    return (
      countries.data?.filter(
        (country) =>
          country.countryID !== otherSideSelectedCountryId &&
          country.countryID !== otherSidePort?.country.countryID,
      ) ?? []
    );
  };

  const onSwitchSide = () => {
    setIsUserOrigin((state) => !state);
    const tempCountryId = originCountryId;
    setOriginCountryId(destinationCountryId);
    setDestinationCountryId(tempCountryId);

    const tempPortId = originPortId;
    setOriginPortId(destinationPortId);
    setDestinationPortId(tempPortId);

    const tempPorts = originPorts;
    setOriginPorts(destinationPorts);
    setDestinationPorts(tempPorts);
  };

  return (
    <Flex
      justifyContent="space-between"
      flexDirection={{ base: 'column', xl: 'row' }}
    >
      <Box>
        <FormLabel fontWeight="bold">Origin</FormLabel>
        <Flex
          flexDirection={{ base: 'column', xl: 'row' }}
          justifyContent={{ base: 'center', xl: 'flex-start' }}
          gap="1rem"
        >
          <DeselectableSelectInput
            label="Country"
            accessor="originCountry"
            currentValue={originCountryId}
            onSelectionChange={(selected) => {
              setOriginCountryId(selected?.value);
              setOriginPortId('');
              filterPorts(
                selected?.value,
                destinationCountryId,
                '',
                destinationPortId,
              );
            }}
            width={onMobile ? '100%' : '13rem'}
            noOptionsMessage="You don't have any assigned countries"
            options={filterCountries(
              isUserOrigin,
              destinationCountryId,
              destinationPortId,
            ).map((country) => ({
              label: country.name,
              value: country.countryID,
            }))}
          />
          <DeselectableSelectInput
            label="Port"
            accessor="originPort"
            currentValue={originPortId}
            onSelectionChange={(selected) => {
              setOriginPortId(selected?.value);
              filterPorts(
                originCountryId,
                destinationCountryId,
                selected?.value,
                destinationPortId,
              );
            }}
            isDisabled={originCountryId === undefined}
            width={onMobile ? '100%' : '9rem'}
            options={originPorts.map((port) => ({
              label: port.unloCode,
              value: port.portID,
            }))}
          />
        </Flex>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding={{ base: '2rem 0', xl: '3.5rem 2rem 0 2rem' }}
      >
        <IconButton
          onClick={onSwitchSide}
          icon={<SwitchIcon w="8" h="8" />}
          aria-label="Switch"
          variant="switch"
        />
      </Box>
      <Box>
        <FormLabel fontWeight="bold">Destination</FormLabel>
        <Flex
          flexDirection={{ base: 'column', xl: 'row' }}
          justifyContent={{ base: 'center', xl: 'flex-start' }}
          gap="1rem"
        >
          <DeselectableSelectInput
            label="Country"
            accessor="destinationCountry"
            currentValue={destinationCountryId}
            onSelectionChange={(selected) => {
              setDestinationCountryId(selected?.value);
              setDestinationPortId('');
              filterPorts(originCountryId, selected?.value, originPortId, '');
            }}
            width={onMobile ? '100%' : '13rem'}
            noOptionsMessage="You don't have any assigned countries"
            options={filterCountries(
              !isUserOrigin,
              originCountryId,
              originPortId,
            ).map((country) => ({
              label: country.name,
              value: country.countryID,
            }))}
          />
          <DeselectableSelectInput
            label="Port"
            accessor="destinationPort"
            currentValue={destinationPortId}
            onSelectionChange={(selected) => {
              setDestinationPortId(selected?.value);
              filterPorts(
                originCountryId,
                destinationCountryId,
                originPortId,
                selected?.value,
              );
            }}
            width={onMobile ? '100%' : '9rem'}
            isDisabled={destinationCountryId === undefined}
            options={destinationPorts.map((port) => ({
              label: port.unloCode,
              value: port.portID,
            }))}
          />
        </Flex>
      </Box>
    </Flex>
  );
};
