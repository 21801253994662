import { ChevronLeftIcon } from '@chakra-ui/icons';
import {
  Button,
  Divider,
  Skeleton,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { FunctionComponent, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useLocation, useParams } from 'react-router-dom';
import { EditIcon } from '../../components/Icons';
import { HeadingCard, AccessDenied, Card } from '../../components/Layout';
import { ErrorResult } from '../../components/Result';
import { useApi } from '../../providers/ApiProvider';
import { useAuthentication } from '../../providers/AuthenticationProvider';
import { useAuthorization } from '../../providers/AuthorizationProvider';
import { Organization, UserRole } from '../../types';
import { OrganizationSettings } from '../../types/OrganizationSettings';
import { routes } from '../../utils/routes';
import { UsersTable } from '../UserManagementPage/UsersTable';
import { AssignedCountries } from './components/AssignedCountries';
import { Details } from './components/Details';

export const OrganizationDetailsPage: FunctionComponent = () => {
  const { id } = useParams();
  const { user } = useAuthentication();
  const { hasRole } = useAuthorization();
  const { getApi } = useApi();
  const location = useLocation();
  const [statusCode, setStatusCode] = useState<number>(0);

  const { isLoading: isLoadingOrg, data: organization } =
    useQuery<Organization>(['organization', id], async () => {
      const result = await getApi(`organizations/${id}`);
      setStatusCode(result.status);
      if (result.ok) return result.json();
    });

  const { isLoading: isLoadingSettings, data: organizationSettings } =
    useQuery<OrganizationSettings>(['organizationSettings', id], async () => {
      const result = await getApi(`organizationSettings/${id}`);
      setStatusCode(result.status);
      if (result.ok) return result.json();
    });

  if (
    !hasRole([UserRole.GLOBAL_ADMINISTRATOR]) &&
    (user?.organization?.organizationID == null ||
      user?.organization?.organizationID !== id)
  ) {
    return <AccessDenied />;
  }

  if (isLoadingOrg || isLoadingSettings) {
    return <Skeleton height="800px" />;
  }

  if (organization == null || organizationSettings == null) {
    return <ErrorResult statusCode={statusCode} />;
  }

  return (
    <Stack>
      <HeadingCard
        heading={
          <>
            {location.state?.hasBackButton && (
              <Button
                as={Link}
                to={routes.organizations.base}
                variant="outline"
                size="xs"
                mr="1rem"
                leftIcon={<ChevronLeftIcon h="4" w="4" />}
              >
                Back
              </Button>
            )}
            {organization?.name}
          </>
        }
        direction="row"
        align="center"
        justify="space-between"
      >
        {hasRole([
          UserRole.GLOBAL_ADMINISTRATOR,
          UserRole.ORGANIZATION_MANAGER,
        ]) && (
          <Button
            as={Link}
            to={routes.organizations.edit(id!)}
            state={
              location.state?.hasBackButton
                ? { hasBackButton: true }
                : undefined
            }
            colorScheme="secondary"
            color="grey.100"
            size="xs"
            rightIcon={<EditIcon w="5" h="5" />}
          >
            Edit
          </Button>
        )}
      </HeadingCard>
      <Card>
        <Tabs>
          <TabList>
            <Tab>Organization Details</Tab>
            <Tab>Assigned Countries</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Details
                organization={organization}
                organizationSettings={organizationSettings}
              />
              <Stack spacing={7}>
                {hasRole([
                  UserRole.GLOBAL_ADMINISTRATOR,
                  UserRole.ORGANIZATION_MANAGER,
                  UserRole.REGION_MANAGER,
                ]) && (
                  <>
                    <Divider
                      borderColor="grey.900"
                      borderBottomWidth="2px"
                      opacity="unset"
                    />
                    <UsersTable
                      organizationId={id}
                      backTo={routes.organizations.details(id!)}
                    />
                  </>
                )}
              </Stack>
            </TabPanel>
            <TabPanel>
              <AssignedCountries />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Card>
    </Stack>
  );
};
