import { FunctionComponent } from 'react';
import { ActionModal } from '../ActionModal';

interface SaveChangesModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onAction: () => void;
  isLoading?: boolean;
}

export const SaveChangesModal: FunctionComponent<SaveChangesModalProps> = ({
  isOpen,
  onCancel,
  onAction,
  isLoading = false,
}) => {
  const handleOnCancel = () => {
    onCancel();
  };
  const handleActionCancel = () => {
    onAction();
  };

  return (
    <ActionModal
      onCancel={handleOnCancel}
      onActionClick={handleActionCancel}
      isOpen={isOpen}
      parentLoading={isLoading}
      modalHeader="Save changes"
      modalText="Are you sure you want to save changes?"
      actionButtonText="Yes"
      actionButtonColor="blue"
    />
  );
};
