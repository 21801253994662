import create from 'zustand';
import { TransportMode } from '../types';
import { ApiTransportDirection } from '../types/TransportDirection';

interface TruckingFilterStore {
  countryId: string | undefined;
  setCountryId: (countryId?: string) => void;
  transportTypes: TransportMode[];
  setTransportTypes: (transportTypes: TransportMode[]) => void;
  directionTypes: ApiTransportDirection[];
  setDirectionTypes: (directionTypes: ApiTransportDirection[]) => void;
  reset: () => void;
}

export const useTruckingFilters = create<TruckingFilterStore>((set) => ({
  countryId: undefined,
  setCountryId: (countryId) => set(() => ({ countryId })),
  transportTypes: [TransportMode.Air, TransportMode.LCL],
  setTransportTypes: (transportTypes: TransportMode[]) =>
    set(() => ({ transportTypes })),
  directionTypes: [ApiTransportDirection.Export, ApiTransportDirection.Import],
  setDirectionTypes: (directionTypes) => set(() => ({ directionTypes })),
  reset: () =>
    set(() => ({
      countryId: undefined,
    })),
}));
