import { Heading, Stack, StackProps } from '@chakra-ui/layout';
import type { FunctionComponent, ReactNode } from 'react';

export const Card: FunctionComponent<StackProps> = ({ children, ...rest }) => (
  <Stack
    bg="grey.800"
    borderRadius="10px"
    py="2rem"
    px="2.5rem"
    boxShadow="0 14px 28px 0 rgba(0, 0, 0, 0.1)"
    {...rest}
  >
    {children}
  </Stack>
);

interface MainCardProps extends StackProps {
  heading?: string | ReactNode;
}

export const MainCard: FunctionComponent<MainCardProps> = ({
  children,
  heading,
  ...rest
}) => (
  <Card flex="1" {...rest}>
    {heading && (
      <Heading as="h2" variant="h2">
        {heading}
      </Heading>
    )}
    {children}
  </Card>
);
