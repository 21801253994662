import { Flex, HStack, Stack, VStack } from '@chakra-ui/layout';
import { useBreakpointValue } from '@chakra-ui/media-query';
import type { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuthorization } from '../../providers/AuthorizationProvider';
import { ApprovalStatus, UserRole } from '../../types';
import { FooterLink } from '../Navigation/FooterLink';
import { MobileNavbar } from '../Navigation/MobileNavbar';
import { Navbar } from '../Navigation/Navbar';

export const Layout: FunctionComponent = () => {
  const mobileNav = useBreakpointValue({ base: true, lg: false });
  const { approvalStatus, userRole, isOrgDeactivated } = useAuthorization();

  const showNavbar =
    approvalStatus === ApprovalStatus.APPROVED &&
    userRole !== UserRole.NONE &&
    !isOrgDeactivated;

  const renderFooter = () => (
    <HStack justifyContent="center" width="full">
      <FooterLink linkText="Imprint" href="https://cargonerds.com/imprint/" />
      <FooterLink
        linkText="Privacy"
        href="https://cargonerds.com/privacy-policy/"
      />
    </HStack>
  );

  return mobileNav ? (
    <Stack as="main" minH="100vh" bg="grey.900" justifyContent="space-between">
      <Stack>
        {showNavbar && <MobileNavbar />}
        <Stack as="section" w="full" minH="full" p="5">
          <Outlet />
        </Stack>
      </Stack>
      {renderFooter()}
    </Stack>
  ) : (
    <Flex as="main" direction="row" minH="100vh" pr="20px" bg="grey.900">
      {showNavbar && <Navbar />}
      <VStack
        width="full"
        alignItems="flex-start"
        minH="100vh"
        justifyContent="space-between"
        maxW={showNavbar ? 'calc(100% - 16.875rem)' : 'full'} // This is needed because of the navbar & table not working together properly in the width
      >
        <Stack as="section" w="full" pl="5" py="2.5">
          <Outlet />
        </Stack>
        {renderFooter()}
      </VStack>
    </Flex>
  );
};

export * from './Card';
export * from './HeadingCard';
export * from './AccessDenied';
