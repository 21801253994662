import { SearchIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  Center,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Spinner,
} from '@chakra-ui/react';
import type { InputProps } from '@chakra-ui/react';
import Downshift from 'downshift';
import { Card } from '../Layout';

export interface SearchInputProps<T> extends InputProps {
  label?: string;
  register: any;
  errors: any;
  accessor: string;
  items?: T[];
  inputValue?: string;
  itemToString: (item: T | null) => string;
  displayItems?: (
    items: T[],
    highlightedIndex: number | null,
    getItemProps: CallableFunction,
    getMenuProps: CallableFunction,
  ) => JSX.Element;
  isLoading: boolean;
  onItemChange: (selectedItem: T | null) => void;
  onInputValueChange: (input: string) => void;
}

export const SearchInput = <T,>({
  label,
  register,
  accessor,
  errors,
  isLoading,
  isRequired,
  items,
  inputValue: propsInputValue,
  itemToString,
  displayItems,
  onItemChange,
  onInputValueChange,
  ...rest
}: SearchInputProps<T>) => (
  // @ts-ignore
  <Downshift
    onChange={onItemChange}
    onInputValueChange={onInputValueChange}
    itemToString={itemToString}
    inputValue={propsInputValue}
  >
    {({
      getInputProps,
      getItemProps,
      getLabelProps,
      getMenuProps,
      getRootProps,
      clearSelection,
      inputValue,
      isOpen,
      highlightedIndex,
    }) => (
      <FormControl
        isInvalid={errors[accessor]}
        isRequired={isRequired}
        {...getRootProps()}
      >
        {label && (
          <FormLabel htmlFor={accessor} {...getLabelProps()}>
            {label}
          </FormLabel>
        )}
        <InputGroup>
          <Input
            id={accessor}
            variant="outline"
            {...register(accessor, { required: isRequired })}
            {...getInputProps({
              onChange: () => {
                if (inputValue === '') clearSelection();
              },
            })}
            onClick={(e) => e.currentTarget.select()}
            {...rest}
          />
          <InputRightElement children={<SearchIcon color="blue.500" />} />
        </InputGroup>
        {isOpen && inputValue !== '' && (
          <Card
            p="1rem"
            mt="0.25rem"
            position="absolute"
            zIndex={1000}
            w="full"
          >
            {!items || isLoading ? (
              <Center>
                <Spinner />
              </Center>
            ) : items.length > 0 && displayItems ? (
              displayItems(items, highlightedIndex, getItemProps, getMenuProps)
            ) : items.length > 0 ? (
              <List {...getMenuProps()}>
                {items.map((item, index) => (
                  <ListItem
                    p="2"
                    fontSize="xs"
                    backgroundColor={
                      highlightedIndex === index ? 'grey.500' : 'grey.800'
                    }
                    {...getItemProps({
                      key: itemToString(item),
                      index,
                      item,
                    })}
                  >
                    {itemToString(item)}
                  </ListItem>
                ))}
              </List>
            ) : (
              <Alert status="info" color="grey.700" fontSize="sm">
                <AlertIcon />
                No results were found
              </Alert>
            )}
          </Card>
        )}
      </FormControl>
    )}
  </Downshift>
);
