import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { Column } from 'react-table';
import { RateTable } from '../../../components/RateTable';
import { NoResult } from '../../../components/Result';
import { useErrorToast } from '../../../hooks/useErrorToast';
import { useApi } from '../../../providers/ApiProvider';
import { Rate, RateType } from '../../../types';
import { toDisplayDateFormat } from '../../../utils/formatter';
import { getHttpStatusDescription } from '../../../utils/httpStatus';

const errorTitle = 'Saving your changes failed, please try again';

interface IIdObject {
  id: string;
}

interface IExpiredRatesModalBodyProps<
  TRateType extends Omit<Rate, 'organizationId'>,
  TRateFormValues,
  TRateUpdateResponse,
> {
  isExpired: boolean;
  typeEndpoint: string;
  additionalColumns: Column<TRateType>[];
  rateType: RateType;
  createUpdatedRate: (
    submitData: TRateFormValues,
    rate?: TRateType,
  ) => TRateUpdateResponse;
}

export const ExpiredRatesModalBody = <
  TRateType extends Omit<Rate, 'organizationId'>,
  TRateFormValues extends object,
  TRateUpdateResponse extends IIdObject,
>({
  isExpired,
  additionalColumns,
  typeEndpoint,
  createUpdatedRate,
  rateType,
}: IExpiredRatesModalBodyProps<
  TRateType,
  TRateFormValues,
  TRateUpdateResponse
>) => {
  const { getApi, deleteApi, putApi } = useApi();
  const errorToast = useErrorToast();

  const { data, isLoading, isFetching, refetch } = useQuery<TRateType[]>(
    [],
    async () => {
      const result = await getApi(
        `${typeEndpoint}/expired?showSoonExpiring=${!isExpired}`,
      );

      if (result.ok) {
        return result.json();
      }
    },
  );

  const columns = useMemo<Column<TRateType>[]>(
    () => [
      ...additionalColumns,
      {
        Header: 'Valid From',
        accessor: (rate) => toDisplayDateFormat(rate.validFrom),
      },
      {
        Header: 'Valid To',
        accessor: (rate) => toDisplayDateFormat(rate.validTo),
      },
    ],
    [additionalColumns],
  );

  const onEdit = useCallback(
    async (submitData: TRateFormValues, rate?: TRateType) => {
      if (!rate) {
        return false;
      }

      let response: Response;

      const updatedRate = createUpdatedRate(submitData, rate);
      try {
        response = await putApi(
          `${typeEndpoint}/${updatedRate.id}`,
          updatedRate,
        );
      } catch (e) {
        errorToast({ title: errorTitle });
        return false;
      }

      if (response.ok) {
        await refetch();
        return true;
      }

      errorToast({ title: getHttpStatusDescription(response.status) });
      return false;
    },
    [createUpdatedRate, errorToast, putApi, typeEndpoint, refetch],
  );

  const onDelete = useCallback(
    async (id: string) => {
      let response;
      try {
        response = await deleteApi(`${typeEndpoint}/${id}`);
      } catch (e) {
        errorToast({ title: errorTitle });
        return false;
      }

      if (response.ok) {
        await refetch();
        return true;
      }

      errorToast({ title: getHttpStatusDescription(response.status) });
      return false;
    },
    [errorToast, deleteApi, typeEndpoint, refetch],
  );

  return (
    <RateTable<TRateType, TRateFormValues>
      columns={columns}
      onEdit={onEdit}
      onDelete={onDelete}
      rateType={rateType}
      isLoading={isLoading || isFetching}
      fetchFailed={false}
      statusCode={200}
      empty={<NoResult />}
      showActionColumn={true}
      data={data}
    />
  );
};
