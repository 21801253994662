import { ChargeCode } from './ChargeCode';
import { Country } from './Country';
import { Currency } from './Currency';
import { Port } from './Port';
import { TransportMode } from './Rate';

export interface IfApplicable {
  infoId: string;
  chargeCode: ChargeCode;
  origin?: Country;
  originPort?: Port;
  destination?: Country;
  destinationPort?: Port;
  comment?: string;
  breakpointFrom?: number;
  breakpointTo?: number;
  minimum?: number;
  amount: number;
  fourtyFeetContainerAmount?: number;
  fourtyFeetHCContainerAmount?: number;
  currency: Currency;
  calculator: IfApplicableCalculatorType;
  unitType?: IfApplicableUnitType;
  shippingType: TransportMode;
  rateCountry: Country;
  isOrigin: boolean;
  isDestination: boolean;
  validFrom: Date;
  validTo: Date;
}

export enum IfApplicableCalculatorType {
  FLT = 1,
  UNT = 2,
  MPU = 3,
  CMB = 4,
  PER = 5,
}

export enum IfApplicableUnitType {
  KG = 0,
  HB = 1,
  KG100 = 2,
  Percent = 3,
  Package = 4,
  M3Chargeable = 5,
  Kg1000 = 6,
  Container = 7,
  TEU = 8,
  AdditionalLine = 9,
}
