import { Divider, DividerProps, HStack, Text } from '@chakra-ui/layout';
import type { FunctionComponent } from 'react';

interface NavDividerProps extends DividerProps {
  label?: string;
}

export const NavDivider: FunctionComponent<NavDividerProps> = ({
  label,
  ...props
}) => (
  <HStack align="center">
    {label && (
      <Text fontSize="xs" color="grey.400">
        {label}
      </Text>
    )}
    <Divider {...props} borderColor="grey.400" />
  </HStack>
);
