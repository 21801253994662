import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/modal';
import { Button, Stack } from '@chakra-ui/react';
import { FunctionComponent, useCallback, useMemo } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { SelectFormInput, FormInput } from '../../components/Input';
import { useErrorToast } from '../../hooks/useErrorToast';
import { useSuccessToast } from '../../hooks/useSuccessToast';
import { useApi } from '../../providers/ApiProvider';
import { useAuthorization } from '../../providers/AuthorizationProvider';
import { useData } from '../../providers/DataProvider';
import { Organization, ApprovalStatus, UserRole } from '../../types';
import { getHttpStatusDescription } from '../../utils/httpStatus';

interface DeleteModalProps {
  onClose: () => void;
  isOpen: boolean;
  organizationId?: string;
}

const errorTitle = 'Saving your changes failed, please try again';

export const InviteUserModal: FunctionComponent<DeleteModalProps> = ({
  onClose,
  isOpen,
  organizationId,
}) => {
  const { hasRole } = useAuthorization();
  const { getApi, postApi } = useApi();
  const { countries, roles } = useData();
  const successToast = useSuccessToast();
  const errorToast = useErrorToast();
  const {
    control,
    reset,
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  const { data: organizations } = useQuery<Organization[]>(
    ['organizations'],
    async () => {
      const result = await getApi(
        `organizations?approvalStatus=${ApprovalStatus.APPROVED}`,
      );
      if (result.ok) return result.json();
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );

  const filteredRoles = useMemo(
    () =>
      roles.data
        ?.filter(({ role }) => {
          if (hasRole([UserRole.GLOBAL_ADMINISTRATOR])) {
            return role !== UserRole.NONE;
          }
          if (hasRole([UserRole.ORGANIZATION_MANAGER])) {
            return (
              role !== UserRole.GLOBAL_ADMINISTRATOR && role !== UserRole.NONE
            );
          }
          return false;
        })
        .sort((a, b) => a.role - b.role),
    [hasRole, roles],
  );

  const inviteUser = useCallback(
    async (values: FieldValues) => {
      let response;
      try {
        response = await postApi('users/invite', {
          name: values.name,
          email: values.email,
          organizationId: values.organization,
          country: countries.data?.find(
            (country) => country.countryID === values.country,
          ),
          role: roles.data?.find((role) => role.id === values.role),
        });
      } catch (e) {
        errorToast({ title: errorTitle });
        return false;
      }

      if (!response.ok) {
        errorToast({
          title: getHttpStatusDescription(response.status),
        });
        return;
      }
      // React hook form does not reset properly if initial value is undefined
      // https://github.com/react-hook-form/react-hook-form/discussions/7589
      reset({
        name: '',
        email: '',
      });
      successToast({
        title: 'Invitation link has been sent!',
      });
      await onClose();
    },
    [countries, errorToast, onClose, postApi, reset, roles, successToast],
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        reset();
      }}
      isCentered={true}
    >
      <ModalOverlay />
      <ModalContent bg="grey.800">
        <form onSubmit={handleSubmit(inviteUser)}>
          <ModalHeader>Invite User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="5">
              <FormInput
                label="Name"
                accessor="name"
                register={register}
                errors={errors}
                isDisabled={isSubmitting}
                isRequired={true}
              />
              <FormInput
                label="Email"
                accessor="email"
                register={register}
                errors={errors}
                type="email"
                isDisabled={isSubmitting}
                isRequired={true}
              />
              <SelectFormInput
                label="Organization"
                accessor="organization"
                placeholder="Select an Organization"
                isDisabled={isSubmitting}
                isRequired={true}
                defaultValue={organizationId}
                control={control}
                options={organizations?.map((organization) => ({
                  label: organization.name,
                  value: organization.organizationID,
                }))}
              />
              <SelectFormInput
                label="Country"
                accessor="country"
                placeholder="Select a Country"
                isDisabled={isSubmitting}
                isRequired={true}
                control={control}
                options={countries.data?.map((country) => ({
                  label: country.name,
                  value: country.countryID,
                }))}
              />
              <SelectFormInput
                label="Role"
                accessor="role"
                placeholder="Select a Role"
                isDisabled={isSubmitting}
                isRequired={true}
                control={control}
                options={filteredRoles?.map((role) => ({
                  label: role.name,
                  value: role.id,
                }))}
              />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              w="full"
              colorScheme="blue"
              isLoading={isSubmitting}
            >
              Invite User
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
