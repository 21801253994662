import { useCallback, useState } from 'react';
import { HttpMethod, useApi } from '../../providers/ApiProvider';
import { getHttpStatusDescription } from '../../utils/httpStatus';
import { useErrorToast } from '../useErrorToast';

export const useDecoratedApi = <
  RequestBody extends Object,
  ResponseBody extends Object,
>(
  errorMessage: string,
  method: HttpMethod,
) => {
  const { postApi, putApi, deleteApi } = useApi();
  const errorToast = useErrorToast();

  const [fetchFailed, setFetchFailed] = useState<boolean>(false);
  const [statusCode, setStatusCode] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const sendRequest = useCallback(
    async (apiUrl: string, requestBody?: RequestBody) => {
      let response;
      try {
        setLoading(true);
        setFetchFailed(false);
        setStatusCode(0);
        if (method === HttpMethod.POST && requestBody) {
          response = await postApi(apiUrl, requestBody);
          setStatusCode(response.status);
        } else if (method === HttpMethod.PUT && requestBody) {
          response = await putApi(apiUrl, requestBody);
          setStatusCode(response.status);
        } else if (method === HttpMethod.DELETE) {
          response = await deleteApi(apiUrl);
          setStatusCode(response.status);
        }
      } catch (e) {
        errorToast({ title: errorMessage });
        return false;
      } finally {
        setLoading(false);
      }

      if (!response) {
        setFetchFailed(true);
        errorToast({
          title: 'Unknown error',
          description: 'Please try again, or contact the administrator',
        });
        return false;
      }
      if (response.ok) {
        return response as unknown as ResponseBody;
      } else {
        setFetchFailed(true);
        errorToast({
          title: getHttpStatusDescription(response.status),
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [postApi, errorToast],
  );

  return { sendRequest, fetchFailed, statusCode, loading };
};
