import { ChargeCode } from './ChargeCode';
import { Country } from './Country';
import { Currency } from './Currency';
import { DistanceZone } from './DistanceZone';
import { Organization } from './Organization';
import { Port } from './Port';

export enum TransportMode {
  Air = 1,
  LCL = 2,
  FCL = 4,
}

export enum RateType {
  Local = 'local',
  Trucking = 'trucking',
  Air = 'air',
  SeaFcl = 'seaFcl',
  SeaLcl = 'seaLcl',
}

export interface Rate {
  id: string;
  validTo: Date;
  validFrom: Date;
  organizationId: string;
  createUser?: string;
  createTimestamp?: Date;
  currency: Currency;
  organization?: Organization;
}

export interface LocalCharge extends Omit<Rate, 'organizationId'> {
  transportMode: TransportMode;
  country: Country;
  port: Port | null;
  isOrigin: boolean;
  isDestination: boolean;
  chargeCode: ChargeCode;
  minimumCharge?: number | null;
  maximumCharge?: number | null;
  flat?: number | null;
  ratePerKg?: number | null;
  ratePerCbm?: number | null;
  ratePer20FeetContainer?: number | null;
  ratePer40FeetContainer?: number | null;
  ratePer40HCFeetContainer?: number | null;
  lastEditTimestamp?: Date;
  lastEditUser?: string;
}

export interface AirCharge extends Omit<Rate, 'organizationId'> {
  minimumCharge: number | null;
  below100kg: number | null;
  above100kg: number | null;
  above300kg: number | null;
  above500kg: number | null;
  above1000kg: number | null;
  origin: Port | null;
  destination: Port | null;
}

export interface SeaFclCharge extends Omit<Rate, 'organizationId'> {
  per20GP: number | null;
  per40GP: number | null;
  per40HC: number | null;
  origin: Port | null;
  destination: Port | null;
}

export interface SeaLclCharge extends Omit<Rate, 'organizationId'> {
  minimumCharge: number | null;
  perCbm: number | null;
  per1000kg: number | null;
  origin: Port | null;
  destination: Port | null;
}

export interface TruckingCharge extends Omit<Rate, 'organizationId'> {
  below100Kg?: number | null;
  below300Kg?: number | null;
  below500Kg?: number | null;
  below1000Kg?: number | null;
  below3000Kg?: number | null;
  below5000Kg?: number | null;
  country: Country;
  transportMode: TransportMode;
  minimumCharge: number | null;
  distanceZone: DistanceZone;
}
