export enum TransportDirection {
  Import = 'import',
  Export = 'export',
}

export enum ApiTransportDirection {
  Undefined = 0,
  Import = 1,
  Export = 2,
}
