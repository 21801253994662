import { GridItem } from '@chakra-ui/layout';
import type { FunctionComponent, ReactNode } from 'react';

interface InfoItemProps {
  title: string;
  value?: string | ReactNode;
}

export const InfoItem: FunctionComponent<InfoItemProps> = ({
  title,
  value,
}) => (
  <>
    <GridItem fontSize="sm" fontWeight="500" color="grey.400">
      {title}
    </GridItem>
    <GridItem fontSize="sm" fontWeight="600">
      {value}
    </GridItem>
  </>
);
