import { TariffRateDto } from '../../../../types/api/TariffRateDto';
import { EMPTY_BRACKET } from './emptyBracket';

export const EMPTY_CELL: TariffRateDto = {
  bracketTransportMethod: {
    bracketTransportMethodID: '',
    tariffTrucking: '',
    rangeTo: '',
    rangeFrom: '',
  },
  bracketWeight: { ...EMPTY_BRACKET },
  tariffRateID: '',
  tariffTrucking: null,
  value: null,
};
