import { AlertProps } from '@chakra-ui/alert';
import type { FunctionComponent } from 'react';
import {
  getHttpStatusDescription,
  getHttpStatusTitle,
} from '../../utils/httpStatus';
import { Result } from '.';

interface ErrorResultProps extends AlertProps {
  statusCode?: number;
}

export const ErrorResult: FunctionComponent<ErrorResultProps> = ({
  statusCode,
  ...rest
}) => (
  <Result
    status="error"
    title={getHttpStatusTitle(statusCode)}
    description={getHttpStatusDescription(statusCode)}
    {...rest}
  />
);
