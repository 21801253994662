import { Heading, Text, VStack } from '@chakra-ui/layout';
import type { FunctionComponent } from 'react';

interface CountHeadingProps {
  count: number;
  description: string;
}

export const CountHeading: FunctionComponent<CountHeadingProps> = ({
  count,
  description,
}) => (
  <VStack>
    <Heading color="blue.500" fontSize="2xl">
      {count}
    </Heading>
    <Text>{description}</Text>
  </VStack>
);
