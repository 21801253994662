import { Checkbox, GridItem } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import type { FieldErrors, UseFormRegister } from 'react-hook-form';
import { FormInput } from '../../../components/Input';
import { Currency } from '../../../types';

interface CurrencyFieldsProps {
  register: UseFormRegister<Currency>;
  errors: FieldErrors<Currency>;
}

export const CurrencyFields: FunctionComponent<CurrencyFieldsProps> = ({
  register,
  errors,
}) => (
  <>
    <GridItem>
      <FormInput
        label="Name"
        register={register}
        errors={errors}
        accessor={'name'}
        isRequired={true}
        registerOptions={{
          maxLength: { value: 50, message: 'Maximum of 50 characters' },
        }}
      />
    </GridItem>
    <GridItem>
      <FormInput
        label="Code"
        register={register}
        errors={errors}
        accessor={'code'}
        isRequired={true}
        registerOptions={{
          maxLength: { value: 3, message: 'Maximum of 3 characters' },
        }}
      />
    </GridItem>
    <GridItem colSpan={2}>
      <Checkbox {...register('isConvertible')}>Is Convertible</Checkbox>
    </GridItem>
  </>
);
