import { Input } from '@chakra-ui/react';
import { ColumnInstance } from 'react-table';

interface TextColumnFilterProps<T extends object> {
  column: ColumnInstance<T>;
}

export const TextColumnFilter = <T extends object>({
  column,
}: TextColumnFilterProps<T>) => (
  <Input
    value={column.filterValue || ''}
    onChange={(e) => column.setFilter(e.target.value || undefined)}
    placeholder="Filter"
    fontWeight="500"
  />
);
