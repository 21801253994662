import create from 'zustand';
import { TransportMode } from '../types';

interface FilterStore {
  countryId: string | undefined;
  setCountryId: (countryId?: string) => void;
  transportType: TransportMode;
  setTransportType: (transportType: TransportMode) => void;
  reset: () => void;
}

export const useTruckingFiltersOld = create<FilterStore>((set) => ({
  countryId: undefined,
  setCountryId: (countryId) => set(() => ({ countryId })),
  transportType: TransportMode.Air,
  setTransportType: (transportType) => set(() => ({ transportType })),
  reset: () =>
    set(() => ({
      countryId: undefined,
    })),
}));
