import { CheckIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  HStack,
  Skeleton,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Column } from 'react-table';
import { ActionModal } from '../../../components/ActionModal';
import { DataTable } from '../../../components/DataTable';
import { useErrorToast } from '../../../hooks/useErrorToast';
import { useSuccessToast } from '../../../hooks/useSuccessToast';
import { useApi } from '../../../providers/ApiProvider';
import { useAuthorization } from '../../../providers/AuthorizationProvider';
import { TableActionButtonProps } from '../../../theme/components';
import { UserRole } from '../../../types';
import { NetworkCountry } from '../../../types/NetworkCountry';
import { AssignCountryModal } from './AssignCountryForm';

const errorTitle = 'Saving your changes failed, please try again';

export const AssignedCountries = () => {
  const { getApi, deleteApi } = useApi();
  const { id } = useParams();
  const { hasRole } = useAuthorization();
  const [selectedNetworkCountry, setSelectedNetworkCountry] =
    useState<NetworkCountry | null>(null);
  const {
    isOpen: isDeleteOpen,
    onClose: onCloseDelete,
    onOpen: onOpenDelete,
  } = useDisclosure();
  const {
    isOpen: isAssignOpen,
    onClose: onCloseAssign,
    onOpen: onOpenAssign,
  } = useDisclosure();
  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const {
    isLoading: isLoadingAssigedCountries,
    data: assignedCountries,
    refetch: refetchAssignedCountries,
  } = useQuery<NetworkCountry[]>(['assignedCountries', id], async () => {
    const result = await getApi(`organizations/${id}/assignedNetworkCountries`);
    if (result.ok) return result.json();
  });

  const onCloseAssignCountryModal = () => {
    refetchAssignedCountries();
    onCloseAssign();
  };

  const onClickRemove = useCallback(
    (networkCountry: NetworkCountry) => {
      setSelectedNetworkCountry(networkCountry);
      onOpenDelete();
    },
    [onOpenDelete],
  );

  const onDelete = async () => {
    let response;
    try {
      response = await deleteApi(
        `organizations/${id}/assignedNetworkCountries/${selectedNetworkCountry?.country.countryID}`,
      );
    } catch (e) {
      errorToast({ title: errorTitle });
      return false;
    }

    if (response.ok) {
      successToast({
        title: `${selectedNetworkCountry?.country.name} has been successfully removed from the organization`,
      });
    } else {
      errorToast({ title: response.statusText });
    }
    setSelectedNetworkCountry(null);
    refetchAssignedCountries();
  };

  const countryColumns = useMemo<Column<NetworkCountry>[]>(() => {
    const defaultColumns: Column<NetworkCountry>[] = [
      {
        Header: 'Name',
        accessor: (item) => item.country.name,
      },
      { Header: 'Code', accessor: (item) => item.country.code },
      {
        Header: 'Private',
        accessor: (item) => (item.network.isPrivate ? <CheckIcon /> : null),
      },
    ];

    if (hasRole([UserRole.GLOBAL_ADMINISTRATOR])) {
      return [
        ...defaultColumns,
        {
          id: 'Action',
          accessor: (item) => (
            <Flex justifyContent="flex-end" w="100%">
              <Button
                {...TableActionButtonProps}
                onClick={() => onClickRemove(item)}
              >
                Remove
              </Button>
            </Flex>
          ),
        },
      ];
    }

    return defaultColumns;
  }, [hasRole, onClickRemove]);

  if (isLoadingAssigedCountries) {
    return <Skeleton height="800px" />;
  }

  return (
    <>
      <AssignCountryModal
        assignedCountries={assignedCountries ?? []}
        isOpen={isAssignOpen}
        onClose={onCloseAssignCountryModal}
      />
      <ActionModal
        onSuccess={onCloseDelete}
        onCancel={onCloseDelete}
        onActionClick={onDelete}
        isOpen={isDeleteOpen}
        modalText={`Do you really want to remove the country ${selectedNetworkCountry?.country.name} from this organization?`}
        modalHeader={`Remove "${selectedNetworkCountry?.country.name}"`}
      />
      <HStack justifyContent="space-between">
        <Text fontWeight="bold">
          {assignedCountries?.length} assigned countries
        </Text>
        {hasRole([UserRole.GLOBAL_ADMINISTRATOR]) && (
          <Button colorScheme="blue" size="sm" onClick={() => onOpenAssign()}>
            Assign new country
          </Button>
        )}
      </HStack>

      <DataTable<NetworkCountry>
        data={assignedCountries}
        columns={countryColumns}
        isLoading={isLoadingAssigedCountries}
        fetchFailed={false}
        sizeOfPage={() => 1000}
      />
    </>
  );
};
