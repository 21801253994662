import { chakra, HTMLChakraProps } from '@chakra-ui/system';

export const LocalIcon = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg viewBox="0 0 24 24" {...props}>
    <g
      id="Icons/Local"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M12,12 C10.9,12 10,11.1 10,10 C10,8.9 10.9,8 12,8 C13.1,8 14,8.9 14,10 C14,11.1 13.1,12 12,12 Z M18,10.2 C18,6.57 15.35,4 12,4 C8.65,4 6,6.57 6,10.2 C6,12.54 7.95,15.64 12,19.34 C16.05,15.64 18,12.54 18,10.2 Z M12,2 C16.2,2 20,5.22 20,10.2 C20,13.52 17.33,17.45 12,22 C6.67,17.45 4,13.52 4,10.2 C4,5.22 7.8,2 12,2 Z"
        id="Shape"
        fill="currentColor"
        fillRule="nonzero"
      ></path>
    </g>
  </chakra.svg>
);
