import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertProps,
  AlertTitle,
  Stack,
} from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import React from 'react';

export interface ResultProps extends AlertProps {
  title: string;
  description: string | React.ReactNode;
}

export const Result: FunctionComponent<ResultProps> = ({
  title,
  description,
  ...rest
}) => (
  <Alert
    variant="subtle"
    flexDirection="column"
    justifyContent="center"
    height="full"
    {...rest}
  >
    <Stack alignItems="center" textAlign="center" spacing={2}>
      <AlertIcon boxSize="40px" mr={0} />
      <AlertTitle fontSize="lg">{title}</AlertTitle>
      <AlertDescription>{description}</AlertDescription>
    </Stack>
  </Alert>
);

export * from './ErrorResult';
export * from './NoResult';
export * from './NoFilters';
