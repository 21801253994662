import { Port } from '../../types';

export const findPortsByIdList = (
  ports: Port[] | undefined,
  portIds: string[],
): Port[] => {
  if (!ports) return [];
  return ports
    .map((port) => {
      if (portIds.includes(port.country.code + port.unloCode)) {
        return port;
      }
      return undefined;
    })
    .filter((port) => !!port) as Port[];
};
