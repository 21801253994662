export const Link = {
  parts: ['a'],
  baseStyle: {
    color: 'blue.500',
  },
  variants: {
    NavItem: {
      color: 'grey.100'
    },
  },
};
