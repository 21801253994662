import type { FunctionComponent } from 'react';
import { Result, ResultProps } from '.';

export const InfoResult: FunctionComponent<ResultProps> = ({ description, title, ...rest }) => (
  <Result
  status='success'
  backgroundColor='grey.800'
  boxShadow='0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 8px 20px 0 rgba(0, 0, 0, 0.19);'
  borderRadius='8px'
  title={title}
  description={description}
    {...rest}
  />
);