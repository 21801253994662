import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/modal';
import { Button, Stack } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import { useCallback } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { FormInput } from '../../../components/Input';
import { useErrorToast } from '../../../hooks/useErrorToast';
import { useSuccessToast } from '../../../hooks/useSuccessToast';
import { useApi } from '../../../providers/ApiProvider';
import { getHttpStatusDescription } from '../../../utils/httpStatus';

interface ModalProps {
  onClose: () => void;
  isOpen: boolean;
  organizationId?: string;
}

const errorTitle = 'Saving your changes failed, please try again';

export const DeclineOrganizationModal: FunctionComponent<ModalProps> = ({
  onClose,
  isOpen,
  organizationId,
}) => {
  const { putApi } = useApi();
  const successToast = useSuccessToast();
  const errorToast = useErrorToast();
  const {
    reset,
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  const declineOrganization = useCallback(
    async (values: FieldValues) => {
      if (organizationId == null) {
        errorToast({
          title: 'Cannot find the selected organization.',
        });
      }
      let response;
      try {
        response = await putApi(`organizations/${organizationId}/decline`, {
          reason: values.reason,
        });
      } catch (e) {
        errorToast({ title: errorTitle });
        return false;
      }

      if (response.ok) {
        reset();
        successToast({
          title: 'Organization creation was declined!',
        });
        onClose();
      } else {
        errorToast({
          title: getHttpStatusDescription(response.status),
        });
      }
    },
    [errorToast, onClose, putApi, organizationId, reset, successToast],
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
      <ModalOverlay />
      <ModalContent bg="grey.800">
        <form onSubmit={handleSubmit(declineOrganization)}>
          <ModalHeader>Decline Organization</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="5">
              <FormInput
                label="Reason"
                accessor="reason"
                register={register}
                errors={errors}
                type="reason"
                isDisabled={isSubmitting}
                isRequired={true}
              />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              w="full"
              colorScheme="blue"
              isLoading={isSubmitting}
            >
              Decline Organization
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
