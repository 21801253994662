import { CalendarIcon } from '@chakra-ui/icons';
import {
  forwardRef,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { FunctionComponent, useState } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { Control, Controller } from 'react-hook-form';
import type { ExtendedInputProps } from '../FormInput';

import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.css';

interface DateFormInputProps
  extends ReactDatePickerProps,
    Omit<
      ExtendedInputProps,
      | 'register'
      | 'errors'
      | 'onChange'
      | 'onKeyDown'
      | 'onSelect'
      | 'value'
      | 'children'
    > {
  validate?: any;
  control: Control<any>;
  controllerDefaultValue?: any;
  isRequired: boolean;
  placeholderText?: string;
}

export const DateFormInput: FunctionComponent<DateFormInputProps> = ({
  label,
  accessor,
  isRequired,
  isDisabled,
  validate,
  control,
  onChange,
  selected,
  placeholderText,
  ...rest
}) => {
  // Workaround for focus issue with custom input: https://github.com/Hacker0x01/react-datepicker/issues/2051#issuecomment-665223142
  const [hasFocus, setHasFocus] = useState(false);

  const DateInput = forwardRef(({ value, error, ...props }, ref) => (
    <InputGroup>
      <Input
        id={accessor}
        fontSize="xs"
        fontWeight="500"
        disabled={isDisabled}
        _focus={{
          zIndex: 'unset',
          borderColor: error ? 'red.500' : 'blue.500',
        }}
        defaultValue={value}
        ref={ref}
        aria-label={accessor}
        {...props}
      />
      <InputRightElement
        pointerEvents="none"
        children={<CalendarIcon color="blue.500" />}
        zIndex="unset"
      />
    </InputGroup>
  ));

  return (
    <Controller
      name={accessor}
      control={control}
      rules={{
        required: isRequired && 'This field is required',
        validate,
      }}
      render={({ fieldState: { error } }) => (
        <FormControl isInvalid={!!error} isRequired={isRequired}>
          <FormLabel htmlFor={accessor}>{label}</FormLabel>
          <ReactDatePicker
            customInput={<DateInput error={error} {...rest} />}
            dateFormat="yyyy-MM-dd"
            selected={selected}
            onChange={onChange}
            onFocus={() => setHasFocus(true)}
            onBlur={() => setHasFocus(false)}
            autoFocus={hasFocus}
            placeholderText={placeholderText}
            {...rest}
          />
          <FormErrorMessage>{error && error.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
};
