import { chakra, HTMLChakraProps } from '@chakra-ui/system';

export const CheckIcon = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg viewBox="0 0 16 16" {...props}>
    <g
      id="Icons/Small/check"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M11.4675615,4.27256843 L6.20581655,9.75189284 L4.52348993,8 C4.35630288,7.82550712 4.12932227,7.72744498 3.89261745,7.72744498 C3.65591262,7.72744498 3.42893201,7.82550712 3.26174497,8 C2.91275168,8.36342458 2.91275168,8.95049505 3.26174497,9.31391963 L5.57941834,11.7274316 C5.92841163,12.0908561 6.49217002,12.0908561 6.84116331,11.7274316 L12.738255,5.58648806 C13.0872483,5.22306348 13.0872483,4.63599301 12.738255,4.27256843 C12.3892617,3.90914386 11.8165548,3.90914386 11.4675615,4.27256843 Z"
        id="icon/action/check_circle_outline_24px"
        fill="currentColor"
        fillRule="nonzero"
      ></path>
    </g>
  </chakra.svg>
);
