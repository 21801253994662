import '@fontsource/montserrat';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import './theme/styles/';

import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Layout } from './components/Layout';
import { ProtectedRoute } from './components/ProtectedRoute';
import { AccountPage } from './pages/AccountPage';
import { AirPage } from './pages/ChargesPage/AirPage';
import { FclPage } from './pages/ChargesPage/FclPage';
import { LclPage } from './pages/ChargesPage/LclPage';
import { LocalPage } from './pages/ChargesPage/LocalPage';
import { TariffCreatePage } from './pages/ChargesPage/TariffCreatePage';
import { TariffCreateRatesPage } from './pages/ChargesPage/TariffCreateRatesPage';
import { TariffPage } from './pages/ChargesPage/TariffPage';
import { TruckingOldPage } from './pages/ChargesPage/TruckingOldPage';
import { TruckingPage } from './pages/ChargesPage/TruckingPage';
import { DeactivatedPage, DeactivationType } from './pages/DeactivatedPage';
import { HomePage } from './pages/HomePage';
import { MasterDataPage } from './pages/MasterDataPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { OnboardingPage } from './pages/OnboardingPage';
import { OrganizationDetailsPage } from './pages/OrganizationDetails';
import { OrganizationEditPage } from './pages/OrganizationEdit';
import { OrganizationsPage } from './pages/OrganizationsPage';
import { QuotationPage } from './pages/QuotationPage';
import { ThirdPartyApiAddPage } from './pages/ThirdPartyApiAdd';
import { ThirdPartyApiEditPage } from './pages/ThirdPartyApiEdit';
import { ThirdPartyApiPage } from './pages/ThirdPartyApiPage';
import { UserDetailsPage } from './pages/UserDetails';
import { UserEditPage } from './pages/UserEdit';
import { UserManagementPage } from './pages/UserManagementPage';
import { ApiProvider } from './providers/ApiProvider';
import { AuthenticationProvider } from './providers/AuthenticationProvider';
import { AuthorizationProvider } from './providers/AuthorizationProvider';
import { DataProvider } from './providers/DataProvider';
import { theme } from './theme';
import { UserRole } from './types';
import { getEnv } from './utils/envVar';
import { routes } from './utils/routes';

const configuration: Configuration = {
  auth: {
    clientId: getEnv('AZURE_AD_CLIENT_ID'),
    authority: getEnv('AZURE_AD_AUTHORITY'),
    knownAuthorities: getEnv('AZURE_AD_KNOWN_AUTHORITIES').split(','),
    redirectUri: window.location.origin,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

const msalInstance = new PublicClientApplication(configuration);

const queryClient = new QueryClient();

export const App = () => (
  <MsalProvider instance={msalInstance}>
    <ChakraProvider theme={theme}>
      <ApiProvider>
        <QueryClientProvider client={queryClient}>
          <AuthenticationProvider>
            <BrowserRouter>
              <AuthorizationProvider>
                <DataProvider>
                  <Routes>
                    <Route element={<Layout />}>
                      <Route index={true} element={<HomePage />} />
                      <Route
                        path={routes.quotations}
                        element={<QuotationPage />}
                      />
                      <Route
                        path={routes.charges.local}
                        element={
                          <ProtectedRoute
                            roles={[
                              UserRole.ORGANIZATION_MANAGER,
                              UserRole.REGION_MANAGER,
                              UserRole.RATE_MANAGER,
                              UserRole.STANDARD_USER,
                            ]}
                          >
                            <LocalPage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path={routes.charges.truckingOld}
                        element={
                          <ProtectedRoute
                            roles={[
                              UserRole.ORGANIZATION_MANAGER,
                              UserRole.REGION_MANAGER,
                              UserRole.RATE_MANAGER,
                              UserRole.STANDARD_USER,
                            ]}
                          >
                            <TruckingOldPage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path={routes.charges.tariffCreate}
                        element={
                          <ProtectedRoute
                            roles={[
                              UserRole.ORGANIZATION_MANAGER,
                              UserRole.REGION_MANAGER,
                              UserRole.RATE_MANAGER,
                            ]}
                          >
                            <TariffCreatePage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path={routes.charges.tariffCreateRates}
                        element={
                          <ProtectedRoute
                            roles={[
                              UserRole.ORGANIZATION_MANAGER,
                              UserRole.REGION_MANAGER,
                              UserRole.RATE_MANAGER,
                            ]}
                          >
                            <TariffCreateRatesPage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path={routes.charges.tariffDetails(':id')}
                        element={
                          <ProtectedRoute
                            roles={[
                              UserRole.ORGANIZATION_MANAGER,
                              UserRole.REGION_MANAGER,
                              UserRole.RATE_MANAGER,
                              UserRole.STANDARD_USER,
                            ]}
                          >
                            <TariffPage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path={routes.charges.trucking}
                        element={
                          <ProtectedRoute
                            roles={[
                              UserRole.ORGANIZATION_MANAGER,
                              UserRole.REGION_MANAGER,
                              UserRole.RATE_MANAGER,
                              UserRole.STANDARD_USER,
                            ]}
                          >
                            <TruckingPage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path={routes.charges.air}
                        element={
                          <ProtectedRoute
                            roles={[
                              UserRole.ORGANIZATION_MANAGER,
                              UserRole.REGION_MANAGER,
                              UserRole.RATE_MANAGER,
                              UserRole.STANDARD_USER,
                            ]}
                          >
                            <AirPage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path={routes.charges.lcl}
                        element={
                          <ProtectedRoute
                            roles={[
                              UserRole.ORGANIZATION_MANAGER,
                              UserRole.REGION_MANAGER,
                              UserRole.RATE_MANAGER,
                              UserRole.STANDARD_USER,
                            ]}
                          >
                            <LclPage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path={routes.charges.fcl}
                        element={
                          <ProtectedRoute
                            roles={[
                              UserRole.ORGANIZATION_MANAGER,
                              UserRole.REGION_MANAGER,
                              UserRole.RATE_MANAGER,
                              UserRole.STANDARD_USER,
                            ]}
                          >
                            <FclPage />
                          </ProtectedRoute>
                        }
                      />
                      <Route path={routes.account} element={<AccountPage />} />
                      <Route
                        path={routes.userManagement.base}
                        element={
                          <ProtectedRoute
                            roles={[
                              UserRole.GLOBAL_ADMINISTRATOR,
                              UserRole.ORGANIZATION_MANAGER,
                              UserRole.REGION_MANAGER,
                            ]}
                          >
                            <UserManagementPage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path={routes.userManagement.details(':id')}
                        element={
                          <ProtectedRoute
                            roles={[
                              UserRole.GLOBAL_ADMINISTRATOR,
                              UserRole.ORGANIZATION_MANAGER,
                              UserRole.REGION_MANAGER,
                            ]}
                          >
                            <UserDetailsPage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path={routes.userManagement.edit(':id')}
                        element={<UserEditPage />}
                      />
                      <Route
                        path={routes.organizations.base}
                        element={
                          <ProtectedRoute
                            roles={[UserRole.GLOBAL_ADMINISTRATOR]}
                          >
                            <OrganizationsPage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path={routes.masterData}
                        element={
                          <ProtectedRoute
                            roles={[UserRole.GLOBAL_ADMINISTRATOR]}
                          >
                            <MasterDataPage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path={routes.organizations.details(':id')}
                        element={<OrganizationDetailsPage />}
                      />
                      <Route
                        path={routes.organizations.edit(':id')}
                        element={
                          <ProtectedRoute
                            roles={[
                              UserRole.GLOBAL_ADMINISTRATOR,
                              UserRole.ORGANIZATION_MANAGER,
                            ]}
                          >
                            <OrganizationEditPage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path={routes.thirdPartyApi.base}
                        element={
                          <ProtectedRoute
                            roles={[UserRole.ORGANIZATION_MANAGER]}
                          >
                            <ThirdPartyApiPage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path={routes.thirdPartyApi.add}
                        element={
                          <ProtectedRoute
                            roles={[UserRole.ORGANIZATION_MANAGER]}
                          >
                            <ThirdPartyApiAddPage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path={routes.thirdPartyApi.edit(':id')}
                        element={
                          <ProtectedRoute
                            roles={[UserRole.ORGANIZATION_MANAGER]}
                          >
                            <ThirdPartyApiEditPage />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path={routes.onboarding}
                        element={<OnboardingPage />}
                      />
                      <Route
                        path={routes.deactivatedAccount}
                        element={
                          <DeactivatedPage
                            deactivationType={DeactivationType.Account}
                          />
                        }
                      />
                      <Route
                        path={routes.deactivatedOrganization}
                        element={
                          <DeactivatedPage
                            deactivationType={DeactivationType.Organization}
                          />
                        }
                      />
                      <Route path="*" element={<NotFoundPage />} />
                    </Route>
                  </Routes>
                </DataProvider>
              </AuthorizationProvider>
            </BrowserRouter>
          </AuthenticationProvider>
        </QueryClientProvider>
      </ApiProvider>
    </ChakraProvider>
  </MsalProvider>
);
