import { ChevronLeftIcon, DeleteIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Divider,
  HStack,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { FunctionComponent, useEffect, useCallback, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ActionModal } from '../../components/ActionModal';
import { CheckIcon, CloseIcon } from '../../components/Icons';
import { HeadingCard, MainCard, AccessDenied } from '../../components/Layout';
import { WarningBox } from '../../components/Output/WarningBox';
import { UnsavedChangesModal } from '../../components/UnsavedChangesModal';
import { useErrorToast } from '../../hooks/useErrorToast';
import { useApi } from '../../providers/ApiProvider';
import { useAuthentication } from '../../providers/AuthenticationProvider';
import { useAuthorization } from '../../providers/AuthorizationProvider';
import { useData } from '../../providers/DataProvider';
import { ApprovalStatus, Organization, UserRole } from '../../types';
import { Optional } from '../../types/Optional';
import { OrganizationSettings as FullOrganizationSettings } from '../../types/OrganizationSettings';
import { getHttpStatusDescription } from '../../utils/httpStatus';
import { routes } from '../../utils/routes';
import { OrganizationInformationFields } from './OrganizationInformationFields';
import { OrganizationSettingsFields } from './OrganizationSettingsFields';
const errorTitle = 'Saving your changes failed, please try again';

export interface OrganizationSettings
  extends Optional<
    FullOrganizationSettings,
    'organizationID' | 'organizationSettingsID'
  > {}

export interface OrganizationForm
  extends Omit<OrganizationSettings, 'organizationID'>,
    Pick<
      Organization,
      | 'name'
      | 'addressText'
      | 'qwykOrganizationId'
      | 'assignedCountries'
      | 'mainCountry'
    > {}

export const OrganizationEditPage: FunctionComponent = () => {
  const { id } = useParams();
  const { user, reloadUser } = useAuthentication();
  const { hasRole } = useAuthorization();
  const { getApi, putApi } = useApi();
  const errorToast = useErrorToast();
  const navigate = useNavigate();
  const { countries } = useData();
  const [statusCode, setStatusCode] = useState<number>(0);
  const [conflictedCountries, setConflictedCountries] = useState<string[]>([]);
  const location = useLocation();
  const {
    isOpen: isOpenBack,
    onOpen: onOpenBack,
    onClose: onCloseBack,
  } = useDisclosure();
  const {
    isOpen: isDeactivateModalOpen,
    onOpen: onDeactivateModalOpen,
    onClose: onDeactivateModalClose,
  } = useDisclosure();
  const {
    isOpen: isReactivateModalOpen,
    onOpen: onReactivateModalOpen,
    onClose: onReactivateModalClose,
  } = useDisclosure();

  const {
    isLoading: isLoadingOrganizationSettings,
    data: organizationSettings,
  } = useQuery<OrganizationSettings>(
    ['organizationSettings', id],
    async () => {
      const result = await getApi(`organizationSettings/${id}`);
      if (result.status === 204) return undefined;

      if (result.ok) return result.json();
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const {
    isLoading: isLoadingOrganization,
    data: organization,
    refetch: refetchOrganizations,
  } = useQuery<Organization>(
    ['organization', id],
    async () => {
      const result = await getApi(`organizations/${id}`);
      setStatusCode(result.status);
      if (result.ok) return result.json();
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const onClickReactivateOrganization = async () => {
    const organizationsResponse = await getApi(
      `organizations?approvalStatus=${ApprovalStatus.APPROVED}`,
    );
    if (organizationsResponse.ok) {
      const organizations: Organization[] = await organizationsResponse.json();
      const countriesInUse = organizations.reduce(
        (prev, org) =>
          org.organizationID === organization?.organizationID
            ? prev
            : prev.concat(org.assignedCountries.map((country) => country.name)),
        [] as string[],
      );
      setConflictedCountries(
        countriesInUse.filter(
          (country, index, self) =>
            self.indexOf(country) === index &&
            (organization?.mainCountry.name === country ||
              organization?.assignedCountries.find(
                (assignedCountry) => assignedCountry.name === country,
              ) != null),
        ),
      );

      onReactivateModalOpen();
    }
  };
  const onDeactivateOrganization = async () => {
    let response;
    try {
      response = await putApi(`organizations/${id}/deactivate`, null);
    } catch (e) {
      errorToast({ title: errorTitle });
      return false;
    }

    if (response.ok) {
      refetchOrganizations();
      navigate(routes.organizations.details(id!));
    }
  };

  const onReactivateOrganization = async () => {
    let response;
    try {
      response = await putApi(`organizations/${id}/reactivate`, null);
    } catch (e) {
      errorToast({ title: errorTitle });
      return false;
    }

    if (response.ok) {
      refetchOrganizations();
      navigate(routes.organizations.details(id!));
    }
  };

  const {
    control,
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty },
    reset,
    getValues,
    watch,
  } = useForm<OrganizationForm>({
    defaultValues: { ...organization, ...organizationSettings },
  });

  useEffect(() => {
    if (organizationSettings != null) {
      reset({ ...getValues(), ...organizationSettings });
    }
  }, [getValues, organizationSettings, reset]);
  const navigateBack = useCallback(
    () =>
      navigate(routes.organizations.details(id!), {
        state: {
          hasBackButton: location.state?.hasBackButton,
        },
      }),
    [navigate, id, location.state?.hasBackButton],
  );

  const saveChanges = useCallback(
    async (values: FieldValues, navigateOnFinish: boolean = true) => {
      const {
        name,
        addressText,
        qwykOrganizationId,
        assignedCountries,
        country: countryValue,
        ...organizationSettingsValues
      } = values;

      let organizationResponse = null;
      let organizationSettingsResponse = null;
      try {
        organizationResponse = await putApi(`organizations/${id}`, {
          name,
          addressText,
          qwykOrganizationId,
          mainCountry: countries.data?.find(
            (country) => country.countryID === countryValue,
          ),
          assignedCountries: countries.data?.filter((country) =>
            assignedCountries?.includes(country.countryID),
          ),
        });

        organizationSettingsResponse = await putApi(
          `organizationSettings/${id}`,
          organizationSettingsValues,
        );
      } catch (e) {
        errorToast({ title: errorTitle });
        return;
      }

      if (!organizationResponse.ok || !organizationSettingsResponse.ok) {
        errorToast({
          title: getHttpStatusDescription(organizationResponse.status),
        });
        errorToast({
          title: getHttpStatusDescription(organizationSettingsResponse.status),
        });
        return;
      }
      if (id === user?.organization?.organizationID) await reloadUser();
      if (!navigateOnFinish) {
        //Resets isDirty to false after saving values
        reset({}, { keepValues: true });
        return;
      }
      navigateBack();
    },
    [
      putApi,
      id,
      countries.data,
      user?.organization?.organizationID,
      reloadUser,
      errorToast,
      reset,
      navigateBack,
    ],
  );

  const renderUnsavedChangesWarning = () => (
    <WarningBox>
      <Stack>
        <Text>
          Your unsaved changes will get lost. Do you want to save them before?
        </Text>
        <Box>
          <Button
            colorScheme="secondary"
            color="grey.100"
            size="xs"
            onClick={handleSubmit((e) => saveChanges(e, false))}
            isLoading={isSubmitting}
            rightIcon={<CheckIcon w="5" h="5" />}
          >
            Save
          </Button>
        </Box>
      </Stack>
    </WarningBox>
  );

  if (
    !hasRole([UserRole.GLOBAL_ADMINISTRATOR]) &&
    (user?.organization?.organizationID == null ||
      user?.organization?.organizationID !== id)
  ) {
    return <AccessDenied />;
  }

  return (
    <Stack>
      <UnsavedChangesModal
        isOpen={isOpenBack}
        onCancel={onCloseBack}
        onSuccess={onCloseBack}
        route={routes.organizations.details(id!)}
      />
      <HeadingCard
        heading={
          <>
            <Button
              onClick={() => (isDirty ? onOpenBack() : navigateBack())}
              variant="outline"
              size="xs"
              mr="1rem"
              leftIcon={<ChevronLeftIcon h="4" w="4" />}
            >
              Back
            </Button>
            {organization?.name}
          </>
        }
        direction="row"
        align="center"
        justify="space-between"
      >
        <HStack>
          {user?.role.role === UserRole.GLOBAL_ADMINISTRATOR &&
            (organization?.isActive ? (
              <Button
                colorScheme="red"
                size="xs"
                onClick={onDeactivateModalOpen}
                rightIcon={<DeleteIcon w="4" h="4" />}
              >
                Deactivate Organization
              </Button>
            ) : (
              <Button
                colorScheme="green"
                size="xs"
                onClick={onClickReactivateOrganization}
              >
                Reactivate Organization
              </Button>
            ))}
          <Button
            as={Link}
            to={routes.organizations.details(id!)}
            size="xs"
            variant="ghost"
            colorScheme="white"
            color="grey.100"
            rightIcon={<CloseIcon w="5" h="5" />}
            _hover={{ bgColor: 'grey.600' }}
          >
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            color="grey.100"
            size="xs"
            onClick={handleSubmit((e) => saveChanges(e))}
            isLoading={isSubmitting && !isDeactivateModalOpen}
            rightIcon={<CheckIcon w="5" h="5" />}
          >
            Save
          </Button>
        </HStack>
      </HeadingCard>
      <MainCard heading="Edit Organization">
        <form onSubmit={handleSubmit((values) => saveChanges(values))}>
          <OrganizationInformationFields
            isLoading={isLoadingOrganization || countries.isLoading}
            isSubmitting={isSubmitting}
            organization={organization}
            register={register}
            errors={errors}
            control={control}
            statusCode={statusCode}
          />
          <Divider my="8" border="2px" borderColor="black" />
          <OrganizationSettingsFields
            isLoading={isLoadingOrganizationSettings}
            control={control}
            register={register}
            isSubmitting={isSubmitting}
            errors={errors}
            watch={watch}
            organizationSettings={organizationSettings}
          />
        </form>
        <ActionModal
          onCancel={onDeactivateModalClose}
          onSuccess={onDeactivateModalClose}
          onActionClick={onDeactivateOrganization}
          isOpen={isDeactivateModalOpen}
          modalText={`Do you really want to deactivate this organization?`}
          modalHeader="Deactivate organization"
          actionButtonText="Deactivate"
        >
          {isDirty && renderUnsavedChangesWarning()}
        </ActionModal>
        <ActionModal
          onCancel={onReactivateModalClose}
          onSuccess={onReactivateModalClose}
          onActionClick={onReactivateOrganization}
          isOpen={isReactivateModalOpen}
          modalText={`Do you really want to reactivate this organization? ${
            conflictedCountries.length > 0
              ? `WARNING! The following countries are already assigned: ${conflictedCountries.join(
                  ', ',
                )}`
              : ''
          }`}
          modalHeader="Reactivate organization"
          actionButtonText="Reactivate"
          actionButtonColor="green"
        >
          {isDirty && renderUnsavedChangesWarning()}
        </ActionModal>
      </MainCard>
    </Stack>
  );
};
