import { GridItem, HStack, List, ListItem } from '@chakra-ui/layout';
import { useDisclosure } from '@chakra-ui/react';
import { Skeleton } from '@chakra-ui/skeleton';
import { FunctionComponent, useState } from 'react';
import {
  BoatIcon,
  LCLIcon,
  LocalIcon,
  PlaneIcon,
  TruckIcon,
} from '../../../components/Icons';
import { Card } from '../../../components/Layout';
import { RateType } from '../../../types';
import { ExpirationSummaryGroup } from '../../../types/ExpirationSummaryGroup';
import { CountHeading } from './CountHeading';
import { ExpiredRatesModal } from './ExpiredRatesModal';
import { RateCountDescription } from './RateCountDescription';

type RateCounts = Omit<ExpirationSummaryGroup, 'totalCount'>;

const getIconByRateType = (type: RateType, hasItems: boolean) => {
  var styleProps = {
    w: '5',
    h: '5',
    color: hasItems ? 'blue.500' : 'grey.200',
  };

  switch (type) {
    case RateType.Air:
      return <PlaneIcon {...styleProps} />;
    case RateType.SeaFcl:
      return <BoatIcon {...styleProps} />;
    case RateType.SeaLcl:
      return <LCLIcon {...styleProps} />;
    case RateType.Local:
      return <LocalIcon {...styleProps} />;
    case RateType.Trucking:
      return <TruckIcon {...styleProps} />;
    default:
      return null;
  }
};

export const getTextRateType = (type: RateType) => {
  switch (type) {
    case RateType.Air:
      return 'Air';
    case RateType.SeaFcl:
      return 'FCL';
    case RateType.SeaLcl:
      return 'LCL';
    case RateType.Local:
      return 'Local';
    case RateType.Trucking:
      return 'Trucking';
    default:
      return '';
  }
};

interface RateCountCardProps {
  isLoading: boolean;
  isExpired: boolean;
  data?: ExpirationSummaryGroup;
  refetch: () => void;
}

export const RateCountCard: FunctionComponent<RateCountCardProps> = ({
  isLoading,
  isExpired,
  data,
  refetch,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalRateType, setModalRateType] = useState<RateType | null>(null);

  const rateCounts: RateCounts = {
    airCount: data?.airCount ?? 0,
    fclCount: data?.fclCount ?? 0,
    lclCount: data?.lclCount ?? 0,
    localCount: data?.localCount ?? 0,
    truckingCount: data?.truckingCount ?? 0,
  };

  const onOpenModal = (rateType: RateType) => {
    setModalRateType(rateType);
    onOpen();
  };

  const onCloseModal = () => {
    setModalRateType(null);
    refetch();
    onClose();
  };

  const renderListItemContent = (rateType: RateType, value: number) => (
    <ListItem
      key={rateType}
      border="1px solid"
      borderColor="grey.500"
      my="2"
      px="8"
      py="4"
      borderRadius="4"
      _hover={
        value > 0
          ? {
              bg: 'grey.500',
              cursor: 'pointer',
            }
          : {}
      }
      onClick={value > 0 ? () => onOpenModal(rateType) : () => {}}
    >
      <HStack gap="8">
        {getIconByRateType(rateType, value > 0)}
        <RateCountDescription
          count={value}
          isExpired={isExpired}
          type={getTextRateType(rateType)}
        />
      </HStack>
    </ListItem>
  );

  return (
    <GridItem>
      <ExpiredRatesModal
        isExpired={isExpired}
        isOpen={isOpen}
        onClose={onCloseModal}
        rateType={modalRateType}
      />
      <Card spacing={8}>
        <Skeleton isLoaded={!isLoading}>
          <CountHeading
            count={data?.totalCount ?? 0}
            description={isExpired ? 'Expired rates' : 'Soon expiring rates'}
          />
        </Skeleton>
        <Skeleton isLoaded={!isLoading}>
          <List>
            {renderListItemContent(RateType.Local, rateCounts.localCount)}
            {renderListItemContent(RateType.Trucking, rateCounts.truckingCount)}
            {renderListItemContent(RateType.Air, rateCounts.airCount)}
            {renderListItemContent(RateType.SeaLcl, rateCounts.lclCount)}
            {renderListItemContent(RateType.SeaFcl, rateCounts.fclCount)}
          </List>
        </Skeleton>
      </Card>
    </GridItem>
  );
};
