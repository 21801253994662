import type { FunctionComponent } from 'react';
import { Card } from '../Layout/Card';

export const NavWrapper: FunctionComponent = ({ children }) => (
  <Card
    as="nav"
    minW="16.875rem"
    p="1.75rem"
    borderRadius="0"
    color="grey.100"
    justifyContent="space-between"
  >
    {children}
  </Card>
);
