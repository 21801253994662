import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { Column } from 'react-table';
import { Card, MainCard, HeadingCard } from '../../../components/Layout';
import { RateTable } from '../../../components/RateTable';
import { NoResult, NoFilters } from '../../../components/Result';
import { useAirFilters } from '../../../hooks/useAirFilters';
import { useErrorToast } from '../../../hooks/useErrorToast';
import { useApi } from '../../../providers/ApiProvider';
import { useAuthorization } from '../../../providers/AuthorizationProvider';
import { AirCharge, Currency, Port, RateType } from '../../../types';
import { toDisplayDateFormat } from '../../../utils/formatter';
import { getHttpStatusDescription } from '../../../utils/httpStatus';
import { formatChargeDate } from '../utils/dateUtils';
import { checkCanMutateRates } from '../utils/ratePermissions';
import { AirChargeFilter } from './components/AirChargeFilter';

export interface AirChargeFormValues {
  origin: Port;
  destination: Port;
  currency: Currency;
  min: number;
  below100kg: number;
  above100kg: number;
  above300kg: number;
  above500kg: number;
  above1000kg: number;
  validFrom: string;
  validTo: string;
}

export interface AirChargeResponse
  extends Omit<AirCharge, 'validTo' | 'validFrom'> {
  validFrom: string;
  validTo: string;
}

type EmptyRate = Omit<AirCharge, 'origin' | 'destination'>;

const emptyRate: EmptyRate = {
  above1000kg: null,
  above100kg: null,
  above300kg: null,
  above500kg: null,
  below100kg: null,
  currency: {
    currencyID: '',
    code: '',
    isConvertible: false,
    name: '',
  },
  minimumCharge: null,
  id: '',
  createTimestamp: new Date(),
  createUser: '',
  validFrom: new Date(),
  validTo: new Date(),
};

const errorTitle = 'Saving your changes failed, please try again';

export const AirPage: FunctionComponent = () => {
  const { deleteApi, getApi, putApi, postApi } = useApi();
  const [statusCode, setStatusCode] = useState<number>(0);
  const [fetchFailed, setFetchFailed] = useState<boolean>(false);
  const toast = useErrorToast();
  const { userCountries, userRole } = useAuthorization();

  const {
    originPortId,
    destinationPortId,
    originCountryId,
    destinationCountryId,
    ports,
    reset,
  } = useAirFilters((state) => ({
    originPortId: state.originPortId,
    destinationPortId: state.destinationPortId,
    originCountryId: state.originCountryId,
    destinationCountryId: state.destinationCountryId,
    ports: state.ports,
    reset: state.reset,
  }));

  const {
    isLoading,
    data: rates,
    refetch: refetchRates,
  } = useQuery<AirCharge[]>(
    [
      'air-charges',
      originPortId,
      originCountryId,
      destinationCountryId,
      destinationPortId,
    ],
    async () => {
      setFetchFailed(false);
      if (originCountryId && destinationCountryId) {
        let getRatesRoute = 'rates/air?maxCount=1000';

        if (originPortId !== undefined && originPortId !== '') {
          getRatesRoute = `${getRatesRoute}&originPortId=${originPortId}`;
        }
        if (destinationPortId !== undefined && destinationPortId !== '') {
          getRatesRoute = `${getRatesRoute}&destinationPortId=${destinationPortId}`;
        }
        if (originCountryId !== '') {
          getRatesRoute = `${getRatesRoute}&originCountryId=${originCountryId}`;
        }
        if (destinationCountryId !== '') {
          getRatesRoute = `${getRatesRoute}&destinationCountryId=${destinationCountryId}`;
        }

        const result = await getApi(getRatesRoute);
        setStatusCode(result.status);
        if (result.ok) {
          const airRates = (await result.json()) as AirCharge[];
          let allCombinations: AirCharge[] = [];
          ports
            .filter((port) =>
              originPortId
                ? port.portID === originPortId
                : port.country.countryID === originCountryId,
            )
            .forEach((originPort) => {
              ports
                .filter((port) =>
                  destinationPortId
                    ? port.portID === destinationPortId
                    : port.country.countryID === destinationCountryId,
                )
                .forEach((destinationPort) => {
                  const correspondingCharge = airRates.find(
                    (rate) =>
                      rate.origin?.portID === originPort.portID &&
                      rate.destination?.portID === destinationPort.portID,
                  );
                  const remainingProps: EmptyRate =
                    correspondingCharge ?? emptyRate;
                  if (originPort.portID !== destinationPort.portID) {
                    allCombinations.push({
                      origin: originPort,
                      destination: destinationPort,
                      ...remainingProps,
                    });
                  }
                });
            });

          return allCombinations;
        }

        setFetchFailed(true);
      }
      return [];
    },
  );

  useEffect(() => {
    refetchRates();
  }, [
    originPortId,
    destinationPortId,
    originCountryId,
    destinationCountryId,
    refetchRates,
  ]);

  useEffect(() => {
    reset();
  }, [reset]);

  const columns = useMemo<Column<AirCharge>[]>(
    () => [
      {
        Header: 'Origin',
        accessor: (rate) => rate.origin?.iataCode,
      },
      {
        Header: 'Dest',
        accessor: (rate) => rate.destination?.iataCode,
      },
      {
        Header: 'Min',
        accessor: (rate) => rate.minimumCharge,
      },
      {
        Header: 'Below 100Kg',
        accessor: (rate) => rate.below100kg,
      },
      {
        Header: 'Above 100Kg',
        accessor: (rate) => rate.above100kg,
      },
      {
        Header: 'Above 300Kg',
        accessor: (rate) => rate.above300kg,
      },
      {
        Header: 'Above 500Kg',
        accessor: (rate) => rate.above500kg,
      },
      {
        Header: 'Above 1000Kg',
        accessor: (rate) => rate.above1000kg,
      },
      {
        Header: 'Currency',
        accessor: (rate) => rate.currency.code,
      },
      {
        Header: 'Valid From',
        accessor: (rate) =>
          rate.id === '' ? '' : toDisplayDateFormat(rate.validFrom),
      },
      {
        Header: 'Valid To',
        accessor: (rate) =>
          rate.id === '' ? '' : toDisplayDateFormat(rate.validTo),
      },
    ],
    [],
  );

  const onEdit = useCallback(
    async (submitData: AirChargeFormValues, rate?: AirCharge) => {
      if (!rate) {
        return false;
      }

      let response: Response;

      if (rate.id !== '') {
        const updatedRate: AirChargeResponse = {
          ...rate,
          origin: submitData.origin!,
          destination: submitData.destination,
          currency: submitData.currency,
          minimumCharge: submitData.min,
          above100kg: submitData.above100kg,
          above300kg: submitData.above300kg,
          above500kg: submitData.above500kg,
          above1000kg: submitData.above1000kg,
          below100kg: submitData.below100kg,
          validFrom: formatChargeDate(submitData.validFrom),
          validTo: formatChargeDate(submitData.validTo),
        };
        try {
          response = await putApi(`rates/air/${updatedRate.id}`, updatedRate);
        } catch (e) {
          toast({ title: errorTitle });
          return false;
        }
      } else {
        const newRate: Omit<
          AirChargeResponse,
          'id' | 'createTimestamp' | 'createUser'
        > = {
          origin: submitData.origin!,
          destination: submitData.destination,
          currency: submitData.currency,
          minimumCharge: submitData.min,
          above100kg: submitData.above100kg,
          above300kg: submitData.above300kg,
          above500kg: submitData.above500kg,
          above1000kg: submitData.above1000kg,
          below100kg: submitData.below100kg,
          validFrom: formatChargeDate(submitData.validFrom),
          validTo: formatChargeDate(submitData.validTo),
        };
        try {
          response = await postApi('rates/air', newRate);
        } catch (e) {
          toast({ title: errorTitle });
          return false;
        }
      }

      if (response.ok) {
        await refetchRates();
        return true;
      }

      toast({ title: getHttpStatusDescription(response.status) });
      return false;
    },
    [postApi, putApi, refetchRates, toast],
  );

  const onDelete = useCallback(
    async (id: string) => {
      let response;
      try {
        response = await deleteApi(`rates/air/${id}`);
      } catch (e) {
        toast({ title: errorTitle });
        return false;
      }

      if (response.ok) {
        await refetchRates();
        return true;
      }

      toast({ title: getHttpStatusDescription(response.status) });
      return false;
    },
    [deleteApi, refetchRates, toast],
  );

  const canMutateRates = checkCanMutateRates(userRole, userCountries, [
    originCountryId,
    destinationCountryId,
  ]);

  return (
    <>
      <HeadingCard heading="Air Charges" />
      <Card>
        <AirChargeFilter />
      </Card>
      <MainCard heading={`${rates?.length ?? 0} Rates`}>
        <RateTable<AirCharge, AirChargeFormValues>
          rateType={RateType.Air}
          data={rates}
          columns={columns}
          isLoading={isLoading}
          fetchFailed={fetchFailed}
          statusCode={statusCode}
          onEdit={onEdit}
          onDelete={onDelete}
          empty={
            originCountryId && destinationCountryId ? (
              <NoResult />
            ) : (
              <NoFilters />
            )
          }
          showActionColumn={canMutateRates}
        />
      </MainCard>
    </>
  );
};
