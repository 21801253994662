import { Skeleton } from '@chakra-ui/react';
import type { ReactElement } from 'react';
import { ErrorResult, NoResult } from '../Result';
import { DataTableBody, DataTableBodyProps } from './DataTableBody';

interface DataTableProps<T extends object> extends DataTableBodyProps<T> {
  isLoading: boolean;
  fetchFailed: boolean;
  statusCode?: number;
  empty?: ReactElement;
  variant?: string;
  enableFilters?: boolean;
  showLoadAllButton?: boolean;
  sizeOfPage?: () => number | undefined;
  updatePageSize?: (newSize: number) => void;
  narrowRows?: boolean;
}

export const DataTable = <T extends object>({
  data,
  columns,
  isLoading,
  fetchFailed,
  statusCode,
  empty,
  variant,
  enableFilters,
  showLoadAllButton,
  sizeOfPage,
  updatePageSize,
  minHeight,
  narrowRows,
}: DataTableProps<T>) => {
  if (fetchFailed) {
    return <ErrorResult statusCode={statusCode} />;
  }

  if (isLoading) {
    return <Skeleton height="200px" />;
  }

  if (data == null || data.length === 0) {
    return empty ?? <NoResult />;
  }

  return (
    <DataTableBody
      data={data}
      columns={columns}
      variant={variant}
      enableFilters={enableFilters}
      showLoadAllButton={showLoadAllButton}
      sizeOfPage={sizeOfPage}
      updatePageSize={updatePageSize}
      minHeight={minHeight}
      narrowRows={narrowRows}
    />
  );
};
