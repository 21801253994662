import { Flex, FormLabel, Text } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { Country, Port } from '../../../../types';
import { MethodTariffAbbreviation } from '../../../../types/MethodTariffAbbreviation';
import { CreateTariffMetaValues } from '../../../../types/forms/CreateTariffMetaValues';
import { toDisplayDateFormat } from '../../../../utils/formatter';
import { getAssignedPorts } from '../../../../utils/model/getAssignedPorts';
import { getCalculatorType } from '../../../../utils/model/getCalculatorType';
import { getDirectionType } from '../../../../utils/model/getDirectionType';
import { getMethodType } from '../../../../utils/model/getMethodType';
import { getTransportType } from '../../../../utils/model/getTransportType';

interface TariffMetaProps {
  metaInformation: CreateTariffMetaValues;
  country: Country;
  ports: Port[];
}

export const TariffMeta: FunctionComponent<TariffMetaProps> = ({
  metaInformation,
  country,
  ports,
}) => (
  <Flex justifyContent="space-between">
    <Flex width="33.3%">
      <Flex flexDirection="column" width="50%">
        <FormLabel fontSize="sm" mb="2">
          Country
        </FormLabel>
        <FormLabel fontSize="sm" mb="2">
          Direction
        </FormLabel>
        <FormLabel fontSize="sm" mb="0">
          Transport Type
        </FormLabel>
      </Flex>
      <Flex flexDirection="column" width="50%">
        <Text fontSize="sm" mb="2">
          {country.name}
        </Text>
        <Text fontSize="sm" mb="2">
          {metaInformation.directions
            .map((type) => getDirectionType(type))
            .join(', ')}
        </Text>
        <Text fontSize="sm" mb="2">
          {getTransportType(metaInformation.transportType) || '-'}
        </Text>
      </Flex>
    </Flex>
    <Flex width="33.3%">
      <Flex flexDirection="column" width="50%">
        <FormLabel fontSize="sm" mb="2">
          Method
        </FormLabel>
        <FormLabel fontSize="sm" mb="2">
          Calculator
        </FormLabel>
        <FormLabel fontSize="sm" mb="0">
          AssignedPorts
        </FormLabel>
      </Flex>
      <Flex flexDirection="column" width="50%">
        <Text fontSize="sm" mb="2">
          {
            MethodTariffAbbreviation[
              getMethodType(
                metaInformation.method,
              ) as keyof typeof MethodTariffAbbreviation
            ]
          }
        </Text>
        <Text fontSize="sm" mb="2">
          {getCalculatorType(metaInformation.calculator)}
        </Text>
        <Text fontSize="sm">
          {getAssignedPorts(ports, metaInformation.transportType)}
        </Text>
      </Flex>
    </Flex>
    <Flex width="33.3%">
      <Flex flexDirection="column" width="50%">
        <FormLabel fontSize="sm" mb="2">
          Currency
        </FormLabel>
        <FormLabel fontSize="sm" mb="2">
          Valid from
        </FormLabel>
        <FormLabel fontSize="sm" mb="0">
          Valid to
        </FormLabel>
      </Flex>
      <Flex flexDirection="column" width="50%">
        <Text fontSize="sm" mb="2">
          {metaInformation.currency}
        </Text>
        <Text fontSize="sm" mb="2">
          {toDisplayDateFormat(metaInformation.validFrom)}
        </Text>
        <Text fontSize="sm">
          {toDisplayDateFormat(metaInformation.validTo)}
        </Text>
      </Flex>
    </Flex>
  </Flex>
);
