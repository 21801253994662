import type { FunctionComponent } from 'react';
import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useErrorToast } from '../../hooks/useErrorToast';
import { useSuccessToast } from '../../hooks/useSuccessToast';
import { useApi } from '../../providers/ApiProvider';
import type { AuthenticationRequest, ThirdParty } from '../../types';
import { getHttpStatusDescription } from '../../utils/httpStatus';
import { routes } from '../../utils/routes';
import { ThirdPartyApiForm } from '../ThirdPartyApiPage/ThirdPartyApiForm';

const errorTitle = 'Saving your changes failed, please try again';

export const ThirdPartyApiAddPage: FunctionComponent = () => {
  const { getApi, postApi } = useApi();
  const successToast = useSuccessToast();
  const errorToast = useErrorToast();
  const navigate = useNavigate();
  const [statusCode, setStatusCode] = useState<number>(0);

  const { isLoading: isLoadingThirdParties, data: thirdParties } = useQuery<
    ThirdParty[]
  >(
    ['third-parties'],
    async () => {
      const result = await getApi(`third-parties`);
      setStatusCode(result.status);
      if (result.ok) return result.json();
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const addThirdParty = useCallback(
    async (data: AuthenticationRequest) => {
      let response;
      try {
        response = await postApi('authentications', data);
      } catch (e) {
        errorToast({
          title: errorTitle,
        });
        return;
      }

      if (response.ok) {
        successToast({
          title: 'Third Party API has been added!',
        });
        navigate(routes.thirdPartyApi.base);
      } else {
        errorToast({
          title:
            response.status === 409
              ? await response.text()
              : getHttpStatusDescription(response.status),
        });
      }
    },
    [errorToast, navigate, postApi, successToast],
  );

  return (
    <ThirdPartyApiForm
      thirdParties={thirdParties}
      isLoading={isLoadingThirdParties}
      statusCode={statusCode}
      onSubmit={addThirdParty}
    />
  );
};
