import { FunctionComponent } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSingleTariffApi } from '../TruckingPage/hooks/useSingleTariffApi';
import { TariffPageContent } from './components/TariffPageContent';

export const TariffPage: FunctionComponent = () => {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const isEdit = !!searchParams.get('isEdit');
  const { rate, refetch } = useSingleTariffApi(id);

  const onEditSuccess = () => {
    refetch();
    // Incorrect ts error in the pipelines
    // @ts-ignore
    setSearchParams(undefined)
  };

  return (
    <>
      {rate && (
        <TariffPageContent
          rate={rate}
          initialEdit={isEdit}
          onSuccess={onEditSuccess}
        />
      )}
    </>
  );
};
