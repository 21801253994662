import { chakra, HTMLChakraProps } from '@chakra-ui/react';

export const SwitchIcon = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg viewBox="0 0 24 24" {...props}>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Icons/Swap">
        <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
        <path
          d="M7.62727273,12 L4,15.5 L7.62727273,19 L7.62727273,16.375 L14,16.375 L14,14.625 L7.62727273,14.625 L7.62727273,12 Z M20,9.5 L16.3727273,6 L16.3727273,8.625 L10,8.625 L10,10.375 L16.3727273,10.375 L16.3727273,13 L20,9.5 Z"
          id="icon/action/swap_horiz_24px"
          fill="currentColor"
        ></path>
      </g>
    </g>
  </chakra.svg>
);
