import { Box } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import { Logo } from '../Logo';
import { NavItems } from './NavItems';
import { NavWrapper } from './NavWrapper';
import { SuggestButton } from './SuggestButton';

export const Navbar: FunctionComponent = () => (
  <NavWrapper>
    <Box as="span">
      <Logo />
      <NavItems />
    </Box>
    <SuggestButton />
  </NavWrapper>
);
