import { AddIcon } from '@chakra-ui/icons';
import { Button, Stack, useDisclosure } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useDebounce } from 'use-debounce';
import { Card, HeadingCard, MainCard } from '../../components/Layout';
import { useApi } from '../../providers/ApiProvider';
import type { Organization } from '../../types';
import { CreateOrganizationModal } from './components/CreateOrganizationModal';
import { OrganizationsTable } from './components/OrganizationsTable';
import { PendingOrganizationsTable } from './components/PendingOrganizationsTable';

export const OrganizationsPage: FunctionComponent = () => {
  const { getApi } = useApi();
  const [statusCode, setStatusCode] = useState<number>(0);
  const [fetchFailed, setFetchFailed] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [debouncedSearchQuery] = useDebounce(searchQuery, 500);
  const [showDeactivatedOrgs, setShowDeactivatedOrgs] = useState(false);
  const {
    isOpen: isCreateOpen,
    onOpen: onCreateOpen,
    onClose: onCreateClose,
  } = useDisclosure();

  const { isLoading, data, refetch } = useQuery<Organization[]>(
    ['organizations', { debouncedSearchQuery, showDeactivatedOrgs }],
    async () => {
      setFetchFailed(false);
      const result = await getApi(
        `organizations?query=${debouncedSearchQuery}&onlyActive=${!showDeactivatedOrgs}`,
      );
      setStatusCode(result.status);
      if (result.ok) return result.json();
      setFetchFailed(true);
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <Stack>
      <HeadingCard
        heading="Organizations"
        direction="row"
        align="center"
        justify="space-between"
      >
        <Button
          colorScheme="secondary"
          size="xs"
          overflow="wrap"
          leftIcon={<AddIcon />}
          onClick={onCreateOpen}
        >
          Create new Organization
        </Button>
      </HeadingCard>
      <Card spacing="7">
        <PendingOrganizationsTable refetchOrganizations={refetch} />
      </Card>
      <MainCard spacing="7">
        <OrganizationsTable
          isLoading={isLoading}
          data={data?.sort((org1, org2) => org1.name.localeCompare(org2.name))}
          fetchFailed={fetchFailed}
          statusCode={statusCode}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          showDeactivatedOrgs={showDeactivatedOrgs}
          setShowDeactivatedOrgs={setShowDeactivatedOrgs}
        />
      </MainCard>
      <CreateOrganizationModal
        onClose={() => {
          onCreateClose();
          refetch();
        }}
        isOpen={isCreateOpen}
      />
    </Stack>
  );
};
