import { TariffRateDto } from '../../../../types/api/TariffRateDto';
import { EMPTY_CELL } from '../constants/emptyCell';

export const getPrefilledRows = (
  rowsList: TariffRateDto[][],
  columnCount: number,
  rowCount: number,
): TariffRateDto[][] => {
  const tempRows: TariffRateDto[][] = [];
  for (let i = 0; i < rowCount; i += 1) {
    const tempCells = [];
    for (let j = 0; j < columnCount; j += 1) {
      const selectedRow = rowsList[i];
      // Get an empty cell if there is no row
      const selectedRowCell =
        selectedRow && selectedRow[j] ? selectedRow[j] : EMPTY_CELL;

      tempCells.push({
        ...selectedRowCell,
        bracketWeight: {
          ...selectedRowCell.bracketWeight,
          order: j,
          isMinValue: j === 0,
        },
      });
    }
    tempRows.push(tempCells);
  }
  return tempRows;
};
