import { ComponentStyleConfig } from '@chakra-ui/react';

export const Input: ComponentStyleConfig = {
  variants: {
    outline: {
      field: {
        borderRadius: '8px',
        fontSize: 'xs',
        borderColor: 'grey.700',
        backgroundColor: 'grey.900',
        color: 'grey.100',
        _hover: {
          borderColor: 'grey.400',
        },
        _focus: {
          borderColor: 'blue.500',
          boxShadow: `0 0 0 1px blue.500`,
        },
      },
    },
  },
};
