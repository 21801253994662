import { chakra, HTMLChakraProps } from '@chakra-ui/system';

export const MasterDataIcon = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg viewBox="0 0 24 24" {...props}>
    <g
      id="Icons/Customer"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M2,20 L22,20 L22,16 L2,16 L2,20 Z M4,17 L6,17 L6,19 L4,19 L4,17 Z M2,4 L2,8 L22,8 L22,4 L2,4 Z M6,7 L4,7 L4,5 L6,5 L6,7 Z M2,14 L22,14 L22,10 L2,10 L2,14 Z M4,11 L6,11 L6,13 L4,13 L4,11 Z"
        id="Shape"
        fill="currentColor"
      ></path>
    </g>
  </chakra.svg>
);
