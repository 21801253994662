export const getHttpStatusTitle = (statusCode?: number) => {
  switch (statusCode) {
    case 401:
      return 'Unauthorized Request';
    case 403:
      return 'Access Denied';
    case 404:
      return 'Not Found';
    default:
      return 'Something went wrong';
  }
};

export const getHttpStatusDescription = (statusCode?: number) => {
  switch (statusCode) {
    case 401:
      return 'You need to be logged-in to execute this request.';
    case 403:
      return 'You do not have access to requested resource.';
    case 404:
      return 'The requested resource cannot be found.';
    default:
      return 'There was an error processing your request. Please try again.';
  }
};
