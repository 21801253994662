import { useTheme } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import ReactSelect, { Props, StylesConfig } from 'react-select';

interface MultiSelectProps extends Props {
  currentValue: boolean;
  error: boolean;
  forwardRef?: any;
}

export const MultiSelect: FunctionComponent<MultiSelectProps> = ({
  currentValue,
  isMulti = true,
  isDisabled = false,
  error,
  forwardRef,
  ...props
}) => {
  const theme = useTheme();
  /*
   * Not the preferred way of styling in Chakra but since react-select
   * doesn't support Chakra theming we have to do it this way
   * at least until there's a multi-select component in Chakra
   */

  const styles: StylesConfig = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '8px',
      backgroundColor: theme.colors.grey[900],
      borderColor: error
        ? theme.colors.red[500]
        : state.isFocused
        ? theme.colors.blue[500]
        : theme.colors.grey[700],
      '&:hover': {
        borderColor: error
          ? theme.colors.red[500]
          : state.isFocused
          ? theme.colors.blue[500]
          : theme.colors.grey[400],
      },
      paddingLeft: currentValue ? '25px' : '0',
      opacity: isDisabled ? '0.4' : '1',
      fontSize: theme.fontSizes.xs,
      fontWeight: 500,
      minWidth: '10rem',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? theme.colors.blue[500] : '',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: theme.colors.grey[100],
    }),
    menuList: (provided) => ({
      ...provided,
      backgroundColor: theme.colors.grey[500],
      color: theme.colors.grey[100],
      fontSize: theme.fontSizes.xs,
      fontWeight: 500,
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: theme.colors.grey[100],
      position: 'absolute',
      left: '6px',
      background: theme.colors.grey[500],
      borderRadius: '2px',
      padding: '2px',
      '&:hover': {
        color: theme.colors.blue[700],
        cursor: 'pointer',
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: theme.colors.blue[500],
    }),
    input: (provided) => ({
      ...provided,
      color: theme.colors.grey[100],
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme.colors.grey[100],
    }),
    multiValue: (multiStyles) => ({
      ...multiStyles,
      backgroundColor: theme.colors.grey[500],
    }),
    multiValueLabel: (multiStyles) => ({
      ...multiStyles,
      color: theme.colors.grey[100],
    }),
    multiValueRemove: (multiStyles) => ({
      ...multiStyles,
      color: theme.colors.grey[100],
      ':hover': {
        color: theme.colors.blue[500],
      },
    }),
  };

  return (
    <ReactSelect
      ref={forwardRef}
      isMulti={isMulti}
      styles={styles}
      closeMenuOnSelect={isMulti ? false : true}
      isDisabled={isDisabled}
      {...props}
    />
  );
};
