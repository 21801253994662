import { FunctionComponent } from 'react';
import { ActionModal } from '../ActionModal';

interface CancelModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onAction: () => void;
  isLoading: boolean;
}

export const DuplicateModal: FunctionComponent<CancelModalProps> = ({
  isOpen,
  onCancel,
  onAction,
  isLoading,
}) => {
  const handleOnCancel = () => {
    onCancel();
  };
  const handleActionClick = () => {
    onAction();
  };

  return (
    <ActionModal
      onCancel={handleOnCancel}
      onActionClick={handleActionClick}
      isOpen={isOpen}
      parentLoading={isLoading}
      modalHeader="Duplicate Resource"
      modalText="This will create a copy of the current resource, with the same name appended with '(Copy)', do you want to continue?"
      actionButtonText="Yes"
      actionButtonColor="blue"
    />
  );
};
