import { Stack } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import { HeadingCard, MainCard } from '../../components/Layout';
import { UsersTable } from './UsersTable';

export const UserManagementPage: FunctionComponent = () => (
  <Stack>
    <HeadingCard heading="User Management" />
    <MainCard spacing="7">
      <UsersTable />
    </MainCard>
  </Stack>
);
