export const generateParamString = (obj?: Record<string, unknown>) => {
  const pairs = [];
  for (const prop in obj) {
    if (
      !obj.hasOwnProperty(prop) ||
      obj[prop] === undefined ||
      obj[prop] === ''
    ) {
      continue;
    }
    pairs.push(prop + '=' + obj[prop]);
  }
  return pairs.join('&');
};
