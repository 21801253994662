export const ListHeading = {
  parts: ['heading', 'text'],
  baseStyle: {
    heading: {
      fontWeight: 'bold',
      fontSize: 'xs',
      color: 'grey.100',
      my: 2,
      position: 'relative',

      _after: {
        content: '""',
        position: 'absolute',
        bottom: '50%',
        left: 0,
        right: 0,
        height: '1px',
        borderTop: `1px solid grey.400`,
        zIndex: 99,
      },
    },
    text: {
      pr: 3,
      position: 'relative',
      zIndex: 100,
    },
  },
};
