import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useApi } from '../../../../providers/ApiProvider';
import { useData } from '../../../../providers/DataProvider';
import { Port, PortType } from '../../../../types';
import { MultiselectOption } from '../../../../types/MultiSelectOption';
import { formatAirportsToOptions } from '../../../../utils/model/formatAirportsToOptions';
import { formatCurrenciesToOptions } from '../../../../utils/model/formatCurrenciesToOptions';
import { formatPortsToOptions } from '../../../../utils/model/formatPortsToOptions';

export const useTariffEditOptions = (countryId: string) => {
  const { getApi } = useApi();
  const { currencies, countries } = useData();

  const [formattedCurrencies, setFormattedCurrencies] = useState<
    MultiselectOption[]
  >([]);
  const [formattedPorts, setFormattedPorts] = useState<MultiselectOption[]>([]);
  const [formattedAirports, setFormattedAirports] = useState<
    MultiselectOption[]
  >([]);

  const selectedCountry = countries.data?.find(
    (item) => item.countryID === countryId,
  );

  const { data: ports } = useQuery<Port[]>(['ports'], async () => {
    const result = await getApi(`ports?portType=${PortType.Sea}`);
    if (result.ok) {
      return (await result.json()) as Port[];
    }
    return [];
  });

  const { data: airports } = useQuery<Port[]>(['airports'], async () => {
    const result = await getApi(`ports?portType=${PortType.Air}`);
    if (result.ok) {
      return (await result.json()) as Port[];
    }
    return [];
  });

  useEffect(() => {
    setFormattedPorts(formatPortsToOptions(ports, selectedCountry));
  }, [ports, selectedCountry]);

  useEffect(() => {
    setFormattedAirports(formatAirportsToOptions(airports, selectedCountry));
  }, [airports, selectedCountry]);

  useEffect(() => {
    setFormattedCurrencies(formatCurrenciesToOptions(currencies));
  }, [currencies]);

  return {
    ports,
    airports,
    formattedCurrencies,
    formattedPorts,
    formattedAirports,
    selectedCountry,
  };
};
