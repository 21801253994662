import { AlertProps } from '@chakra-ui/alert';
import type { FunctionComponent } from 'react';
import { Result } from '.';

export const NoFilters: FunctionComponent<AlertProps> = ({ ...rest }) => (
  <Result
    status="info"
    title="No filters set!"
    description="Please select at least an origin and a destination country to get some results."
    bg="gray.700"
    {...rest}
  />
);
