import { FormLabel, Grid, GridItem, HStack } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import { Address, isAddress, isPort, Port } from '../../../types';

interface PlaceDetailsProps {
  place?: Address | Port;
}

export const PlaceDetails: FunctionComponent<PlaceDetailsProps> = ({
  place,
}) => {
  let country = '-';
  let zipCode = '-';
  let street = '-';

  if (isAddress(place)) {
    country = (place as Address).countryCode;
    zipCode = (place as Address).zipCode;
    street = (place as Address).street;
  } else if (isPort(place)) {
    country = (place as Port).country.code;
  }

  return (
    <HStack spacing={10}>
      <Grid
        templateRows="repeat(3, 1fr)"
        templateColumns="repeat(1, 1fr)"
      >
        <GridItem>
          <FormLabel fontSize="xs">Country</FormLabel>
        </GridItem>
        <GridItem>
          <FormLabel fontSize="xs">ZIP Code</FormLabel>
        </GridItem>
        <GridItem>
          <FormLabel fontSize="xs">Street</FormLabel>
        </GridItem>
      </Grid>
      <Grid
        templateRows="repeat(3, 1fr)"
        templateColumns="repeat(1, 1fr)"
      >
        <GridItem>
          <FormLabel fontSize="xs">{country}</FormLabel>
        </GridItem>
        <GridItem>
          <FormLabel fontSize="xs">{zipCode}</FormLabel>
        </GridItem>
        <GridItem>
          <FormLabel fontSize="xs">{street}</FormLabel>
        </GridItem>
      </Grid>
    </HStack>
  );
};
