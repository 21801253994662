import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import shallow from 'zustand/shallow';
import { useTruckingFilters } from '../../../../hooks/useTruckingFilters';
import { useApi } from '../../../../providers/ApiProvider';
import { Country } from '../../../../types';
import { TariffTruckingDto } from '../../../../types/api/TariffTruckingDto';
import { API_ROUTES } from '../../../../utils/apiRoutes';

export const useTariffsTruckingApi = (userCountries: Country[]) => {
  const [fetchFailed, setFetchFailed] = useState<boolean>(false);
  const [statusCode, setStatusCode] = useState<number>(0);
  const { getApi } = useApi();

  const { setCountryId, countryId, reset, transportTypes, directionTypes } =
    useTruckingFilters(
      (state) => ({
        countryId: state.countryId,
        setCountryId: state.setCountryId,
        reset: state.reset,
        transportTypes: state.transportTypes,
        directionTypes: state.directionTypes,
      }),
      shallow,
    );

  useEffect(() => {
    if(!countryId){
      reset();
      setCountryId(userCountries[0]?.countryID ?? undefined);
    }
    // Has to be disabled as it sets countryId if it isn't already set
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, setCountryId, userCountries]);

  const { isLoading, data: rates } = useQuery<TariffTruckingDto[]>(
    ['trucking-charges', countryId, transportTypes, directionTypes],
    async () => {
      setFetchFailed(false);
      if (countryId === undefined) return [];
      const result = await getApi(
        API_ROUTES.tariffsTrucking({
          countryID: countryId,
          transportType: transportTypes.join(','),
          direction: directionTypes.join(','),
        }),
      );
      setStatusCode(result.status);
      if (result.ok) {
        return (await result.json()) as TariffTruckingDto[];
      }
      setFetchFailed(true);

      return [];
    },
  );

  return { isLoading, rates, fetchFailed, statusCode, countryId };
};
