import { FormLabel, HStack, Text, VStack } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { TransportMode } from '../../../../types';

interface ITruckingSurchargeSettingsProps {
  percentFuel?: number;
  transportType: TransportMode;
  chargeableWeightRatio?: string | number;
}

const defaultSurcharges = {
  fuelSurcharge: 0,
  chargeableWeightRatio: {
    AIR: 166.67,
    LCL: 166.67,
  },
};

export const TruckingSurchargeSettings: FunctionComponent<
  ITruckingSurchargeSettingsProps
> = ({ percentFuel, transportType, chargeableWeightRatio }) => {
  const defaultChargeableWeightRatio =
    transportType === TransportMode.Air
      ? defaultSurcharges.chargeableWeightRatio.AIR
      : defaultSurcharges.chargeableWeightRatio.LCL;

  return (
    <HStack gap="8">
      <VStack alignItems="flex-start">
        <FormLabel mb="0">Fuel Surcharge</FormLabel>
        <Text fontSize="sm">
          {percentFuel ?? defaultSurcharges.fuelSurcharge} %
        </Text>
      </VStack>
      <VStack alignItems="flex-start">
        <FormLabel mb="0">Chargeable Weight Ratio</FormLabel>
        <HStack>
          <Text color="grey.300">1 cbm =</Text>
          <Text fontSize="sm">
            {chargeableWeightRatio ?? defaultChargeableWeightRatio} kg
          </Text>
        </HStack>
      </VStack>
    </HStack>
  );
};
