import type { Country } from './Country';
import type { Organization } from './Organization';

export interface Role {
  id: string;
  role: UserRole;
  name: string;
}

export enum UserRole {
  NONE = -1,
  STANDARD_USER,
  RATE_MANAGER,
  REGION_MANAGER,
  ORGANIZATION_MANAGER,
  GLOBAL_ADMINISTRATOR,
}

export enum ApprovalStatus {
  NONE,
  PENDING,
  APPROVED,
  DECLINED,
}

export interface User {
  userID: string;
  identityProviderID: string;
  name: string;
  email: string;
  organization?: Organization | null;
  userCountry?: Country;
  assignedCountries: Country[];
  role: Role;
  approvalStatus: ApprovalStatus;
}
