import {
  Button,
  Center,
  HStack,
  Spinner,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import {
  addEventListener,
  cancelIntent,
  completeIntent,
  startIntent,
} from 'user-intents/lib/src';
import { Card, HeadingCard } from '../../components/Layout';
import { QuoteSatisfactionModal } from '../../components/Modals/QuoteSatisfactionModal';
import { useQuotationState } from '../../hooks/useQuotationState';
import { useApi } from '../../providers/ApiProvider';
import type { Unit } from '../../types';
import { ContainerSizeType, MainLegSource } from '../../types';
import { QuotationDetails } from './components/QuotationDetails';
import { QuotationForm } from './components/QuotationForm';

const containerSizeTypes: Unit[] = [
  {
    name: '20GP',
    code: ContainerSizeType['20GP'],
  },
  {
    name: '40GP',
    code: ContainerSizeType['40GP'],
  },
  {
    name: '40HC',
    code: ContainerSizeType['40HC'],
  },
];

export const QuotationPage: FunctionComponent = () => {
  const { getApi } = useApi();
  const { quotationPage, showQuotationDetails } = useQuotationState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const { onClose } = useDisclosure();

  const isModalTriggeredRef = useRef(false);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
    isModalTriggeredRef.current = true;
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    onClose();
  };
  useEffect(() => {
    setWasSubmitted(false);
  }, [quotationPage]);

  useEffect(() => {
    if (!quotationPage.showQuotation) {
      return;
    }
    const handleIntent = () => {
      if (!isModalTriggeredRef.current) {
        handleOpenModal();
        isModalTriggeredRef.current = true;
      }
    };

    addEventListener('timedout', handleIntent);
    addEventListener('completed', handleIntent);

    startIntent('show-modal', 35000);

    const handleMouseLeave = () => {
      if (!quotationPage.showQuotation) {
        return;
      }
      completeIntent('show-modal');
    };

    const showModalTimeout: NodeJS.Timeout = setTimeout(() => {
      document.addEventListener('mouseleave', handleMouseLeave);
    }, 20000);

    return () => {
      // eslint-disable-next-line no-restricted-globals
      removeEventListener('timedout', handleIntent);
      // eslint-disable-next-line no-restricted-globals
      removeEventListener('completed', handleIntent);
      cancelIntent('show-modal');
      clearTimeout(showModalTimeout);
      document.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [handleOpenModal, quotationPage]);

  useEffect(() => {
    const sidebarElement = document.getElementsByTagName('nav').item(0);
    const handleMouseOverSidebar = () => {
      if (!quotationPage.showQuotation) {
        return;
      }
      completeIntent('show-modal');
      sidebarElement?.removeEventListener('mouseover', handleMouseOverSidebar);
    };
    let sidebarElementTimeout: NodeJS.Timeout;
    if (sidebarElement) {
      sidebarElementTimeout = setTimeout(() => {
        sidebarElement.addEventListener('mouseover', handleMouseOverSidebar);
      }, 20000);
    }

    return () => {
      clearTimeout(sidebarElementTimeout);
      sidebarElement?.removeEventListener('mouseover', handleMouseOverSidebar);
    };
  }, [quotationPage.showQuotation]);

  const handleQuotationBack = () => {
    showQuotationDetails(false);
    setWasSubmitted(false);
    cancelIntent('show-modal');
    isModalTriggeredRef.current = false;
  };

  const { isLoading: isLoadingLengthUnits, data: lengthUnits } = useQuery<
    Unit[]
  >(
    'units-length',
    async () => {
      const result = await getApi('units/length');
      if (result.ok) {
        return result.json();
      }
    },
    { refetchOnWindowFocus: false },
  );

  const { isLoading: isLoadingWeightUnits, data: weightUnits } = useQuery<
    Unit[]
  >(
    'units-weight',
    async () => {
      const result = await getApi('units/weight');
      if (result.ok) {
        return result.json();
      }
    },
    { refetchOnWindowFocus: false },
  );

  if (isLoadingLengthUnits || isLoadingWeightUnits) {
    return (
      <Stack>
        <Card>
          <Center>
            <Spinner size="xl" />
          </Center>
        </Card>
      </Stack>
    );
  }

  if (quotationPage.showQuotation && quotationPage.quotation != null) {
    return (
      <>
        <Stack>
          <HeadingCard
            heading={'Quotation - ' + quotationPage.quotation.quoteNumber}
            onBack={handleQuotationBack}
            direction="row"
            align="center"
            justify="space-between"
          >
            <HStack>
              <Button
                leftIcon={
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: '16px' }}
                  >
                    reviews
                  </span>
                }
                onClick={handleOpenModal}
                size="xs"
                colorScheme="secondary"
                className="chakra-button"
                mr="1rem"
                justifyContent="center"
                style={{ boxShadow: 'none' }}
                isDisabled={wasSubmitted && quotationPage.showQuotation}
              >
                Rate Quote
              </Button>
            </HStack>
          </HeadingCard>
          {isModalOpen && (
            <QuoteSatisfactionModal
              isOpen={isModalOpen}
              handleModalClose={handleCloseModal}
              quoteId={quotationPage.quotation?.quoteID ?? ''}
              wasSubmitted={wasSubmitted}
              setWasSubmitted={setWasSubmitted}
            />
          )}
          <QuotationDetails
            quotation={quotationPage.quotation}
            weightUnits={weightUnits || []}
            quotationSource={
              quotationPage.quotationSource ?? MainLegSource.Undefined
            }
            ifApplicables={quotationPage.ifApplicables}
            rateType={quotationPage.rateType}
          />
        </Stack>
      </>
    );
  }

  return (
    <Stack>
      <HeadingCard heading="Internal Quotation" />
      <QuotationForm
        lengthUnits={lengthUnits || []}
        weightUnits={weightUnits || []}
        containerSizeTypes={containerSizeTypes}
      />
    </Stack>
  );
};
