export const routes = {
  base: '/',
  quotations: '/quotations',
  charges: {
    base: '/charges',
    local: '/charges/local',
    air: '/charges/air',
    trucking: '/charges/trucking',
    tariffCreate: '/charges/trucking/create',
    tariffCreateRates: '/charges/trucking/create/rates',
    tariffDetails: (id: string): string => `/charges/trucking/${id}`,
    truckingOld: '/charges/truckingOld',
    lcl: '/charges/lcl',
    fcl: '/charges/fcl',
  },
  account: '/account',
  userManagement: {
    base: '/user-management',
    details: (id: string): string => `/user-management/${id}`,
    edit: (id: string): string => `/user-management/edit/${id}`,
  },
  organizations: {
    base: '/organizations',
    details: (id: string): string => `/organizations/${id}`,
    edit: (id: string): string => `/organizations/edit/${id}`,
  },
  masterData: '/masterdata',
  thirdPartyApi: {
    base: '/thirdPartyApi',
    add: '/thirdPartyApi/add',
    edit: (id: string): string => `/thirdPartyApi/edit/${id}`,
  },
  onboarding: '/onboarding',
  imprint: '/imprint',
  dataPrivacy: '/data-privacy',
  conditionsOfUsage: '/conditions-of-usage',
  deactivatedAccount: '/deactivatedAccount',
  deactivatedOrganization: '/deactivatedOrganization',
};
