import { createContext, useCallback, useContext, useMemo } from 'react';
import type { FunctionComponent } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { ApprovalStatus, Country, UserRole } from '../types';
import { routes } from '../utils/routes';
import { useAuthentication } from './AuthenticationProvider';

interface AuthorizationContextProps {
  approvalStatus: ApprovalStatus;
  userRole: UserRole;
  hasRole: (roles: UserRole[]) => boolean;
  userCountries: Country[];
  isOrgDeactivated: boolean;
}

const AuthorizationContext = createContext({
  approvalStatus: ApprovalStatus.NONE,
  userRole: UserRole.NONE,
  hasRole: (roles: UserRole[]) => roles == null,
  userCountries: [] as Country[],
  isOrgDeactivated: true,
});

export const useAuthorization = () => useContext(AuthorizationContext);

export const AuthorizationProvider: FunctionComponent = ({ children }) => {
  const { user } = useAuthentication();
  const location = useLocation();

  const isOnboardingPage = useMemo(
    () => location.pathname.replace(/\/+$/, '') === routes.onboarding,
    [location],
  );

  const isDeactivatedPage = useMemo(
    () =>
      location.pathname.replace(/\/+$/, '') === routes.deactivatedAccount ||
      location.pathname.replace(/\/+$/, '') === routes.deactivatedOrganization,
    [location],
  );

  const approvalStatus = useMemo(
    () => user?.approvalStatus ?? ApprovalStatus.NONE,
    [user],
  );

  const userRole = useMemo(() => user?.role.role ?? UserRole.NONE, [user]);

  const hasRole = useCallback(
    (roles: UserRole[]) => {
      if (roles.length === 0) return true;

      return roles.includes(userRole);
    },
    [userRole],
  );

  const userCountries = useMemo(
    () =>
      (
        (hasRole([UserRole.ORGANIZATION_MANAGER])
          ? user?.organization?.assignedCountries
          : user?.assignedCountries) ?? []
      ).sort((a, b) => (a.name > b.name ? 1 : -1)),
    [hasRole, user],
  );

  if (
    !user?.organization?.isActive &&
    user?.role.role !== UserRole.GLOBAL_ADMINISTRATOR &&
    !isDeactivatedPage
  ) {
    return <Navigate to={routes.deactivatedOrganization} />;
  }

  if (userRole === UserRole.NONE && !isDeactivatedPage) {
    return <Navigate to={routes.deactivatedAccount} />;
  }

  if (
    userRole !== UserRole.NONE &&
    user?.organization?.isActive &&
    isDeactivatedPage
  ) {
    return <Navigate to={routes.base} />;
  }

  if (approvalStatus !== ApprovalStatus.APPROVED && !isOnboardingPage) {
    return <Navigate to={routes.onboarding} />;
  }

  if (approvalStatus === ApprovalStatus.APPROVED && isOnboardingPage) {
    return <Navigate to={routes.base} />;
  }

  const values: AuthorizationContextProps = {
    approvalStatus,
    userRole,
    hasRole,
    userCountries,
    isOrgDeactivated:
      !user?.organization?.isActive &&
      userRole !== UserRole.GLOBAL_ADMINISTRATOR,
  };

  return (
    <AuthorizationContext.Provider value={values}>
      {children}
    </AuthorizationContext.Provider>
  );
};
