export interface GetTextWidthProps {
    canvasContext: CanvasRenderingContext2D | null;
    text: string;
    minLength: number | 0;
  }

export const getTextWidth = 
    (input: GetTextWidthProps) => {
      if (input.canvasContext) {

        const words = input.text.match(/\b(\w+\.*)+/g);
        if (!words) return 0;

        const maxWordLength = Math.max(
          ...words.map(
            (word) => Math.floor(input.canvasContext!.measureText(word).width) + 28,
          ),
        );

        return maxWordLength > input.minLength ? maxWordLength : input.minLength;
      }
      return 0;
    }

