import type { FunctionComponent } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import type { Column } from 'react-table';
import { Card, MainCard, HeadingCard } from '../../../components/Layout';
import { RateTable } from '../../../components/RateTable';
import { NoResult, NoFilters } from '../../../components/Result';
import { useErrorToast } from '../../../hooks/useErrorToast';
import { useSeaLclFilters } from '../../../hooks/useSeaLclFilters';
import { useApi } from '../../../providers/ApiProvider';
import { useAuthorization } from '../../../providers/AuthorizationProvider';
import { Currency, SeaFclCharge, Port, RateType } from '../../../types';
import { toDisplayDateFormat } from '../../../utils/formatter';
import { getHttpStatusDescription } from '../../../utils/httpStatus';
import { formatChargeDate } from '../utils/dateUtils';
import { checkCanMutateRates } from '../utils/ratePermissions';
import { SeaFclChargeFilter } from './components/SeaFclChargeFilter';

export interface SeaFclChargeFormValues {
  origin: Port;
  destination: Port;
  currency: Currency;
  per40GP: number;
  per20GP: number;
  per40HC: number;
  validFrom: string;
  validTo: string;
}

//ToDo: Check why we omit validTo/From and add them manually #techReview
export interface SeaFclChargeResponse
  extends Omit<SeaFclCharge, 'validTo' | 'validFrom'> {
  validFrom: string;
  validTo: string;
}

const emptyRate: Omit<SeaFclCharge, 'origin' | 'destination'> = {
  per20GP: null,
  per40GP: null,
  per40HC: null,
  currency: {
    currencyID: '',
    code: '',
    isConvertible: false,
    name: '',
  },
  id: '',
  createTimestamp: new Date(),
  createUser: '',
  validFrom: new Date(),
  validTo: new Date(),
};

const errorTitle = 'Saving your changes failed, please try again';

export const FclPage: FunctionComponent = () => {
  const { deleteApi, getApi, putApi, postApi } = useApi();
  const { userCountries, userRole } = useAuthorization();
  const [statusCode, setStatusCode] = useState<number>(0);
  const [fetchFailed, setFetchFailed] = useState<boolean>(false);
  const errorToast = useErrorToast();

  const {
    originPortId,
    destinationPortId,
    originCountryId,
    destinationCountryId,
    ports,
    reset,
  } = useSeaLclFilters((state) => ({
    originPortId: state.originPortId,
    destinationPortId: state.destinationPortId,
    originCountryId: state.originCountryId,
    destinationCountryId: state.destinationCountryId,
    ports: state.ports,
    reset: state.reset,
  }));

  const {
    isLoading,
    data: rates,
    refetch: refetchRates,
  } = useQuery<SeaFclCharge[]>(
    [
      'sea-fcl-charges',
      originPortId,
      destinationPortId,
      originCountryId,
      destinationCountryId,
      ports,
    ],
    async () => {
      setFetchFailed(false);
      let getRatesRoute = 'rates/sea-fcl?maxCount=1000';

      if (originPortId !== undefined && originPortId !== '') {
        getRatesRoute = `${getRatesRoute}&originPortId=${originPortId}`;
      }
      if (destinationPortId !== undefined && destinationPortId !== '') {
        getRatesRoute = `${getRatesRoute}&destinationPortId=${destinationPortId}`;
      }
      if (originCountryId !== undefined && originCountryId !== '') {
        getRatesRoute = `${getRatesRoute}&originCountryId=${originCountryId}`;
      }
      if (destinationCountryId !== undefined && destinationCountryId !== '') {
        getRatesRoute = `${getRatesRoute}&destinationCountryId=${destinationCountryId}`;
      }

      const result = await getApi(getRatesRoute);

      setStatusCode(result.status);
      if (result.ok) {
        const fclRates = (await result.json()) as SeaFclCharge[];
        let allCombinations: SeaFclCharge[] = [];
        ports
          .filter((port) =>
            originPortId
              ? port.portID === originPortId
              : port.country.countryID === originCountryId,
          )
          .forEach((originPort) => {
            ports
              .filter((port) =>
                destinationPortId
                  ? port.portID === destinationPortId
                  : port.country.countryID === destinationCountryId,
              )
              .forEach((destinationPort) => {
                const correspondingCharge = fclRates.find(
                  (rate) =>
                    rate.origin?.portID === originPort.portID &&
                    rate.destination?.portID === destinationPort.portID,
                );
                const remainingProps: Omit<
                  SeaFclCharge,
                  'origin' | 'destination'
                > = correspondingCharge ?? emptyRate;
                if (originPort.portID !== destinationPort.portID) {
                  allCombinations.push({
                    origin: originPort,
                    destination: destinationPort,
                    ...remainingProps,
                  });
                }
              });
          });

        return allCombinations;
      }
      setFetchFailed(true);

      return [];
    },
  );

  useEffect(() => {
    refetchRates();
  }, [
    originPortId,
    destinationPortId,
    originCountryId,
    destinationCountryId,
    refetchRates,
  ]);

  useEffect(() => {
    reset();
  }, [reset]);

  const columns = useMemo<Column<SeaFclCharge>[]>(
    () => [
      {
        Header: 'Origin',
        accessor: (rate) => rate.origin?.unloCode,
      },
      {
        Header: 'Dest',
        accessor: (rate) => rate.destination?.unloCode,
      },
      {
        Header: 'PER 20GP',
        accessor: (rate) => rate.per20GP,
      },
      {
        Header: 'PER 40GP',
        accessor: (rate) => rate.per40GP,
      },
      {
        Header: 'PER 40HC',
        accessor: (rate) => rate.per40HC,
      },
      {
        Header: 'Currency',
        accessor: (rate) => rate.currency.code,
      },
      {
        Header: 'Valid From',
        accessor: (rate) =>
          rate.id === '' ? '' : toDisplayDateFormat(rate.validFrom),
      },
      {
        Header: 'Valid To',
        accessor: (rate) =>
          rate.id === '' ? '' : toDisplayDateFormat(rate.validTo),
      },
    ],
    [],
  );

  const onEdit = useCallback(
    async (submitData: SeaFclChargeFormValues, rate?: SeaFclCharge) => {
      if (!rate) {
        return false;
      }

      let response: Response;

      if (rate.id !== '') {
        const updatedRate: SeaFclChargeResponse = {
          ...rate,
          origin: submitData.origin!,
          destination: submitData.destination,
          currency: submitData.currency,
          per20GP: submitData.per20GP,
          per40GP: submitData.per40GP,
          per40HC: submitData.per40HC,
          validFrom: formatChargeDate(submitData.validFrom),
          validTo: formatChargeDate(submitData.validTo),
        };
        try {
          response = await putApi(
            `rates/sea-fcl/${updatedRate.id}`,
            updatedRate,
          );
        } catch (e) {
          errorToast({ title: errorTitle });
          return false;
        }
      } else {
        const newRate: Omit<
          SeaFclChargeResponse,
          'id' | 'createTimestamp' | 'createUser'
        > = {
          origin: submitData.origin!,
          destination: submitData.destination,
          currency: submitData.currency,
          per20GP: submitData.per20GP,
          per40GP: submitData.per40GP,
          per40HC: submitData.per40HC,
          validFrom: new Date(submitData.validFrom).toISOString(),
          validTo: new Date(submitData.validTo).toISOString(),
        };
        try {
          response = await postApi('rates/sea-fcl', newRate);
        } catch (e) {
          errorToast({ title: errorTitle });
          return false;
        }
      }

      if (response.ok) {
        await refetchRates();
        return true;
      }

      errorToast({ title: getHttpStatusDescription(response.status) });
      return false;
    },
    [postApi, putApi, refetchRates, errorToast],
  );

  const onDelete = useCallback(
    async (id: string) => {
      let response;
      try {
        response = await deleteApi(`rates/sea-fcl/${id}`);
      } catch (e) {
        errorToast({ title: errorTitle });
        return false;
      }

      if (response.ok) {
        await refetchRates();
        return true;
      }

      errorToast({ title: getHttpStatusDescription(response.status) });
      return false;
    },
    [deleteApi, refetchRates, errorToast],
  );

  const canMutateRates = checkCanMutateRates(userRole, userCountries, [
    originCountryId,
    destinationCountryId,
  ]);

  return (
    <>
      <HeadingCard heading="Sea Freight FCL" />
      <Card>
        <SeaFclChargeFilter />
      </Card>
      <MainCard heading={`${rates?.length ?? 0} Rates`}>
        <RateTable<SeaFclCharge, SeaFclChargeFormValues>
          rateType={RateType.SeaFcl}
          data={rates}
          columns={columns}
          isLoading={isLoading}
          fetchFailed={fetchFailed}
          statusCode={statusCode}
          onEdit={onEdit}
          onDelete={onDelete}
          empty={
            originCountryId && destinationCountryId ? (
              <NoResult />
            ) : (
              <NoFilters />
            )
          }
          showActionColumn={canMutateRates}
        />
      </MainCard>
    </>
  );
};
