import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  HStack,
  Text,
} from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { rateTypeIcons } from '../../../components/Icons/RateTypeIcons';
import { LocalChargeFormValues } from '../../../components/RateTable/UpsertRateModal/LocalChargeFields';
import {
  AirCharge,
  LocalCharge,
  RateType,
  SeaFclCharge,
  SeaLclCharge,
} from '../../../types';
import { API_ROUTES } from '../../../utils/apiRoutes';
import {
  AirChargeFormValues,
  AirChargeResponse,
} from '../../ChargesPage/AirPage';
import {
  SeaFclChargeFormValues,
  SeaFclChargeResponse,
} from '../../ChargesPage/FclPage';
import {
  SeaLclChargeFormValues,
  SeaLclChargeResponse,
} from '../../ChargesPage/LclPage';
import { LocalChargeResponse } from '../../ChargesPage/LocalPage';
import { ExpiredRatesModalBody } from './ExpiredRatesModalBody';
import {
  columnsLcl,
  createUpdateRateLcl,
  columnsFcl,
  createUpdateRateFcl,
  columnsAir,
  createUpdateRateAir,
  columnsLocal,
  createUpdateRateLocal,
} from './ExpiredRatesValues';
import { ExpiredTruckingTariffModalBody } from './ExpiredTruckingTariffModalBody';
import { getTextRateType } from './RateCountCard';

const getModalHeader = (isExpired: boolean, rateType: RateType | null) => {
  const prefix = isExpired ? 'Expired' : 'Soon expiring';

  if (rateType == null) {
    return prefix;
  }

  const typeText = getTextRateType(rateType);

  return `${prefix} ${typeText} Charges`;
};

interface IExpiredRatesModal {
  rateType: RateType | null;
  isExpired: boolean;
  onClose: () => void;
  isOpen: boolean;
}

export const ExpiredRatesModal: FunctionComponent<IExpiredRatesModal> = ({
  isOpen,
  rateType,
  onClose,
  isExpired,
}) => {
  const renderTable = () => {
    switch (rateType) {
      case RateType.SeaLcl:
        return (
          <ExpiredRatesModalBody<
            SeaLclCharge,
            SeaLclChargeFormValues,
            SeaLclChargeResponse
          >
            isExpired={isExpired}
            additionalColumns={columnsLcl}
            typeEndpoint={API_ROUTES.ratesSeaLCLExpired}
            createUpdatedRate={createUpdateRateLcl}
            rateType={rateType}
          />
        );
      case RateType.SeaFcl:
        return (
          <ExpiredRatesModalBody<
            SeaFclCharge,
            SeaFclChargeFormValues,
            SeaFclChargeResponse
          >
            isExpired={isExpired}
            additionalColumns={columnsFcl}
            typeEndpoint={API_ROUTES.ratesSeaFCLExpired}
            createUpdatedRate={createUpdateRateFcl}
            rateType={rateType}
          />
        );
      case RateType.Air:
        return (
          <ExpiredRatesModalBody<
            AirCharge,
            AirChargeFormValues,
            AirChargeResponse
          >
            isExpired={isExpired}
            additionalColumns={columnsAir}
            typeEndpoint={API_ROUTES.ratesAirExpired}
            createUpdatedRate={createUpdateRateAir}
            rateType={rateType}
          />
        );
      case RateType.Local:
        return (
          <ExpiredRatesModalBody<
            LocalCharge,
            LocalChargeFormValues,
            LocalChargeResponse
          >
            isExpired={isExpired}
            additionalColumns={columnsLocal}
            typeEndpoint={API_ROUTES.ratesLocalChargesExpired}
            createUpdatedRate={createUpdateRateLocal}
            rateType={rateType}
          />
        );
      case RateType.Trucking:
        return <ExpiredTruckingTariffModalBody isExpired={isExpired} />;
      default:
        return null;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="grey.800" minWidth={{ base: '20rem', md: '80%' }}>
        <ModalCloseButton />
        <ModalHeader
          textAlign="center"
          borderBottom="4px"
          borderColor="grey.900"
        >
          <HStack justifyContent="center">
            {rateType && rateTypeIcons[rateType]}
            <Text fontSize="xl">{getModalHeader(isExpired, rateType)}</Text>
          </HStack>
        </ModalHeader>
        <ModalBody
          pb="8"
          mb="8"
          px={{ base: '7', md: '14' }}
          maxH="70vh"
          overflowY="scroll"
        >
          {renderTable()}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
