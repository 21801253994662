import {AddIcon, ChevronRightIcon, SearchIcon} from '@chakra-ui/icons';
import {
    Button,
    Divider,
    Flex,
    FormLabel,
    Heading,
    HStack,
    Input,
    InputGroup,
    InputRightElement,
    Spacer,
    Stack,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import {useMemo, useState} from 'react';
import type {FunctionComponent} from 'react';
import {useQuery} from 'react-query';
import {Link as RouterLink} from 'react-router-dom';
import type {Column} from 'react-table';
import {useDebounce} from 'use-debounce';
import {DataTable} from '../../components/DataTable';
import {SelectColumnFilter} from "../../components/DataTable/SelectColumnFilter";
import {TextColumnFilter} from "../../components/DataTable/TextColumnFilter";
import {useApi} from '../../providers/ApiProvider';
import {useAuthorization} from '../../providers/AuthorizationProvider';
import {TableActionButtonProps} from '../../theme/components';
import {UserRole} from '../../types';
import type {User} from '../../types';
import {routes} from '../../utils/routes';
import {InviteUserModal} from './InviteUserModal';

interface UsersTableProps {
    organizationId?: string;
    backTo?: string;
}

export const UsersTable: FunctionComponent<UsersTableProps> = ({
                                                                   organizationId,
                                                                   backTo,
                                                               }) => {
    const {getApi} = useApi();
    const {hasRole} = useAuthorization();
    const [fetchFailed, setFetchFailed] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [statusCode, setStatusCode] = useState<number>(0);
    const [debouncedSearchQuery] = useDebounce(searchQuery, 500);
    const {
        isOpen: isInviteOpen,
        onOpen: onInviteOpen,
        onClose: onInviteClose,
    } = useDisclosure();

    const columns = useMemo<Column<User>[]>(
        () => [
            {
                Header: 'Name',
                accessor: (user) => user.name,
                disableFilters: true,
            },
            {
                Header: 'Email',
                accessor: (user) => <Text wordBreak="break-all">{user.email}</Text>,
                disableFilters: true,
            },
            {
                Header: 'Country',
                accessor: (user) => user.userCountry?.name,
                Filter: SelectColumnFilter,
            },
            {
                Header: 'Assigned Countries',
                accessor: (user) => {
                    if (user.assignedCountries.length > 2) {
                        return `${user.assignedCountries
                            .slice(0, 2)
                            .map((country) => country.name)
                            .join(', ')}, (+${user.assignedCountries.length - 2})`;
                    }

                    return user.assignedCountries
                        .map((country) => country.name)
                        .join(', ');
                },
                Filter: TextColumnFilter,
            },
            {
                Header: 'Role',
                accessor: (user) => user.role.name,
                Filter: SelectColumnFilter,
            },
            {
                id: 'action',
                accessor: (user) => (
                    <Flex w="100%" justifyContent="flex-end">
                        <Button
                            as={RouterLink}
                            to={routes.userManagement.details(user.userID)}
                            state={{backTo}}
                            {...TableActionButtonProps}
                            rightIcon={<ChevronRightIcon w="4" h="4"/>}
                        >
                            Details
                        </Button>
                    </Flex>
                ),
                disableFilters: true,
            },
        ],
        [backTo],
    );

    const {
        isLoading,
        data: users,
        refetch,
    } = useQuery<User[]>(
        ['users', {organizationId, debouncedSearchQuery}],
        async () => {
            setFetchFailed(false);
            const result = await getApi(
                `users?query=${debouncedSearchQuery}${
                    organizationId ? `&organizationId=${organizationId}` : ''
                }`,
            );
            setStatusCode(result.status);
            if (result.ok) return result.json();
            setFetchFailed(true);
        },
        {
            refetchOnWindowFocus: false,
        },
    );

    const [pageSize, setPageSize] = useState<number | undefined>(
        columns.length === 11 ? 11 : 10,
    );

    return (
        <>
            <Stack spacing="2">
                <FormLabel>Search for user</FormLabel>
                <InputGroup width={{base: 'full', lg: '40%'}}>
                    <Input
                        placeholder="Search for user..."
                        defaultValue={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <InputRightElement
                        pointerEvents="none"
                        children={<SearchIcon color="blue.500"/>}
                    />
                </InputGroup>
            </Stack>
            <Divider borderColor="grey.900" borderBottomWidth="2px" opacity="unset"/>
            <HStack>
                <Heading as="h2" variant="h2">
                    Users ({users?.length ?? 0})
                </Heading>
                <Spacer/>
                {hasRole([
                    UserRole.GLOBAL_ADMINISTRATOR,
                    UserRole.ORGANIZATION_MANAGER,
                ]) && (
                    <>
                        <Button
                            colorScheme="secondary"
                            size="xs"
                            leftIcon={<AddIcon/>}
                            onClick={onInviteOpen}
                        >
                            Invite User
                        </Button>
                        <InviteUserModal
                            onClose={() => {
                                onInviteClose();
                                refetch();
                            }}
                            isOpen={isInviteOpen}
                            organizationId={organizationId}
                        />
                    </>
                )}
            </HStack>
            <DataTable
                data={users}
                columns={columns}
                isLoading={isLoading}
                fetchFailed={fetchFailed}
                statusCode={statusCode}
                enableFilters={true}
                variant="sticky"
                updatePageSize={setPageSize}
                sizeOfPage={() => pageSize}
                showLoadAllButton={true}
            />
        </>
    );
};
