import { BracketWeightDto } from '../../../../types/api/BracketWeightDto';

export const EMPTY_BRACKET_PLACEHOLDERS: BracketWeightDto = {
  bracketWeightID: '00000000-0000-0000-0000-000000000000',
  isMinValue: false,
  order: 0,
  tariffTrucking: null,
  tariffTruckingID: '00000000-0000-0000-0000-000000000000',
  value: 0,
};
