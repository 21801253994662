import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
} from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import type { Control } from 'react-hook-form';
import type { Props } from 'react-select';
import type { FormInputProps } from './FormInput';
import { MultiSelect } from './MultiSelect';
import { SelectInputOption } from './MultiSelectFormInput';

interface SelectFormInputProps
  extends Omit<FormInputProps, 'register' | 'errors'>,
    Props {
  control: Control<any>;
  controllerDefaultValue?: any;
  isRequired: boolean;
  labelProps?: FormLabelProps;
  isClearable?: boolean;
  wrapperProps?: FormControlProps;
  forwardRef?: any;
}

export const SelectFormInput: FunctionComponent<SelectFormInputProps> = ({
  label,
  accessor,
  isRequired,
  isDisabled,
  control,
  controllerDefaultValue,
  labelProps,
  isClearable = false,
  wrapperProps,
  forwardRef,
  ...rest
}: SelectFormInputProps) => {
  const formValue = useWatch({ control, name: accessor });

  return (
    <Controller
      name={accessor}
      control={control}
      defaultValue={controllerDefaultValue}
      rules={{
        required: isRequired && 'This field is required',
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <FormControl
          isInvalid={!!error}
          isRequired={isRequired}
          maxWidth="full"
          {...wrapperProps}
        >
          <FormLabel htmlFor={accessor} {...labelProps}>
            {label}
          </FormLabel>
          <MultiSelect
            forwardRef={forwardRef}
            isMulti={false}
            isClearable={isClearable}
            isDisabled={isDisabled}
            onChange={(value) =>
              onChange((value as SelectInputOption)?.value ?? undefined)
            }
            currentValue={isClearable && formValue}
            error={!!error}
            aria-label={accessor}
            {...rest}
          />
          <FormErrorMessage>{error && error.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
};
