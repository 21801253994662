import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import { HStack, useDisclosure } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { CargonerdsIcon } from '../Icons';
import { NavItems } from './NavItems';
import { NavWrapper } from './NavWrapper';
import { SuggestButton } from './SuggestButton';

export const MobileNavbar: FunctionComponent = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const location = useLocation();

  useEffect(() => {
    onClose();
  }, [location, onClose]);

  return (
    <NavWrapper>
      <HStack p="5" justify="space-between">
        <CargonerdsIcon w="8" h="8" color="blue.400" />
        {isOpen ? (
          <CloseIcon w="6" h="6" onClick={onClose} />
        ) : (
          <HamburgerIcon w="8" h="8" onClick={onOpen} />
        )}
      </HStack>
      {isOpen && (
        <>
          <NavItems />
          <SuggestButton />
        </>
      )}
    </NavWrapper>
  );
};
