import { FunctionComponent } from 'react';
import { useAuthorization } from '../providers/AuthorizationProvider';
import { UserRole } from '../types';
import { AccessDenied } from './Layout/AccessDenied';

interface ProtectedRouteProps {
  roles: UserRole[];
}

export const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = ({
  roles,
  children,
}) => {
  const { hasRole } = useAuthorization();
  return hasRole(roles) ? <>{children}</> : <AccessDenied />;
};
