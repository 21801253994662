import { useMemo } from 'react';
import ReactSelect from 'react-select';
import { ColumnInstance } from 'react-table';
import { useReactSelectStylings } from '../../hooks/useReactSelectStylings';

interface SelectColumnFilterProps<T extends object> {
  column: ColumnInstance<T>;
  alternativeDataSource?: T[];
  alternativeDataSourceAccessor?: keyof T;
  alternativeDataSourceTransformer?: (data: any) => any;
}

interface SelectOption {
  value: string;
  label: string;
}

export const SelectColumnFilter = <T extends object>({
  column,
  alternativeDataSource,
  alternativeDataSourceAccessor,
  alternativeDataSourceTransformer = (data: any) => data,
}: SelectColumnFilterProps<T>) => {
  const styles = useReactSelectStylings(
    '100%',
    !!column.filterValue,
    false,
    '12rem',
  );

  const options = useMemo(() => {
    let optionSet = new Set<string>();
    if (
      alternativeDataSource != null &&
      alternativeDataSourceAccessor != null
    ) {
      optionSet = alternativeDataSource.reduce(
        (prev, row) =>
          prev.add(
            String(
              alternativeDataSourceTransformer(
                row[alternativeDataSourceAccessor],
              ),
            ),
          ),
        new Set<string>(),
      );
    } else {
      optionSet = column.preFilteredRows.reduce(
        (prev, row) => prev.add(String(row.values[column.id])),
        new Set<string>(),
      );
    }

    let optionsArray: SelectOption[] = [];
    let hasUndefined = false;
    optionSet.forEach((option) => {
      if (option === 'undefined') {
        hasUndefined = true;
      } else {
        optionsArray.push({ label: option, value: option });
      }
    });

    const sortedArray = optionsArray.sort((first, second) =>
      first.label.localeCompare(second.label),
    );

    if (hasUndefined) {
      sortedArray.unshift({ label: 'None', value: 'undefined' });
    }

    return sortedArray;
  }, [
    alternativeDataSource,
    alternativeDataSourceAccessor,
    alternativeDataSourceTransformer,
    column.id,
    column.preFilteredRows,
  ]);

  return (
    <ReactSelect
      id={column.id}
      styles={styles}
      options={options}
      value={options.filter((option) => option.value === column.filterValue)}
      onChange={(value) => {
        column.setFilter((value as SelectOption)?.value ?? undefined);
      }}
      isClearable={true}
      placeholder="Filter"
    />
  );
};
