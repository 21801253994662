import { LengthUnit, WeightUnit } from '../types';

/**
 * Converts the input to cm
 * @param input input as unit
 * @param sourceUnit unit of the input, eg 5 ft
 */
//Values taken from ISO/IEC 80000
const toCm = (input: number, sourceUnit: LengthUnit): number => {
  switch (sourceUnit) {
    case LengthUnit.cm:
      return input;
    case LengthUnit.m:
      return 100 * input;
    case LengthUnit.inch:
      return input * 2.54;
    case LengthUnit.ft:
      return input * 30.48;
  }
  return 0;
};

/**
 * Converts the input to the targetUnit
 * @param input input in cm
 * @param targetUnit unit to which the input should be converted to
 */
//Values taken from ISO/IEC 80000
const fromCm = (input: number, targetUnit: LengthUnit): number => {
  switch (targetUnit) {
    case LengthUnit.cm:
      return input;
    case LengthUnit.m:
      return input / 100;
    case LengthUnit.inch:
      return input / 2.54;
    case LengthUnit.ft:
      return input / 30.48;
  }
  return 0;
};

/**
 * Converts the input to kg
 * @param input input as unit
 * @param sourceUnit unit of the input, eg 5 lbs
 */
//Values taken from ISO/IEC 80000
const toKG = (input: number, sourceUnit: WeightUnit): number => {
  switch (sourceUnit) {
    case WeightUnit.kg:
      return input;
    case WeightUnit.lbs:
      return input / 2.205;
    case WeightUnit.t:
      return input * 1000;
  }
  return 0;
};

/**
 * Converts the input to the targetUnit
 * @param input input in KG
 * @param targetUnit unit to which the input should be converted to
 */
//Values taken from ISO/IEC 80000
const fromKG = (input: number, targetUnit: WeightUnit): number => {
  switch (targetUnit) {
    case WeightUnit.kg:
      return input;
    case WeightUnit.t:
      return input / 1000;
    case WeightUnit.lbs:
      return input * 2.205;
  }
  return 0;
};

/**
 * Converts length input number to desired unit
 * @param input unit value
 * @param fromUnit unit of input
 * @param toUnit unit of output
 */
export const convertLengthUnit = (
  input: number,
  fromUnit: LengthUnit,
  toUnit: LengthUnit,
): number => fromCm(toCm(input, fromUnit), toUnit);

/**
 * Converts weight input number to desired unit
 * @param input unit value
 * @param fromUnit unit of input
 * @param toUnit unit of output
 */
export const convertWeightUnit = (
  input: number,
  fromUnit: WeightUnit,
  toUnit: WeightUnit,
): number => fromKG(toKG(input, fromUnit), toUnit);
