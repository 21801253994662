import { Center, Checkbox, GridItem, Spinner } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import type {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';
import { useQuery } from 'react-query';
import { FormInput, SelectFormInput } from '../../../components/Input';
import { useApi } from '../../../providers/ApiProvider';
import type { Country, Region } from '../../../types';

interface CountryFieldsProps {
  register: UseFormRegister<Country>;
  errors: FieldErrors<Country>;
  control: Control<any>;
  watch: UseFormWatch<Country>;
}

export const CountryFields: FunctionComponent<CountryFieldsProps> = ({
  register,
  errors,
  control,
  watch,
}) => {
  const { getApi } = useApi();
  const { isLoading, data: regions } = useQuery<Region[]>(
    ['regions'],
    async () => {
      const result = await getApi('regions');

      if (result.ok) {
        return (await result.json()) as Region[];
      }
      return [];
    },
  );

  const regionId = watch('region') == null ? null : watch('region').regionID;

  if (isLoading) {
    return (
      <GridItem colSpan={2}>
        <Center>
          <Spinner size="xl" />
        </Center>
      </GridItem>
    );
  }

  return (
    <>
      <GridItem>
        <FormInput
          label="Name"
          register={register}
          errors={errors}
          accessor={'name'}
          isRequired={true}
          registerOptions={{
            maxLength: { value: 50, message: 'Maximum of 50 characters' },
          }}
        />
      </GridItem>
      <GridItem>
        <FormInput
          label="Code"
          register={register}
          errors={errors}
          accessor={'code'}
          isRequired={true}
          registerOptions={{
            maxLength: { value: 5, message: 'Maximum of 5 characters' },
          }}
        />
      </GridItem>
      <GridItem>
        <SelectFormInput
          label="Region"
          accessor={'region.regionID'}
          control={control}
          placeholder="Select a Region"
          isRequired={true}
          defaultValue={
            regionId && {
              label: regions?.find((region) => region.regionID === regionId)
                ?.name,
              value: regionId,
            }
          }
          options={regions?.map((region) => ({
            label: region.name,
            value: region.regionID,
          }))}
        />
      </GridItem>
      <GridItem colSpan={2}>
        <Checkbox {...register('isActive')}>Is Active</Checkbox>
      </GridItem>
    </>
  );
};
