import { Center, Checkbox, GridItem, Spinner } from '@chakra-ui/react';
import { StringOrNumber } from '@chakra-ui/utils';
import { FunctionComponent } from 'react';
import type {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { FormInput, SelectFormInput } from '../../../components/Input';
import { useData } from '../../../providers/DataProvider';
import { Port, PortType } from '../../../types';

interface PortFieldsProps {
  register: UseFormRegister<Port>;
  errors: FieldErrors<Port>;
  setValue: UseFormSetValue<Port>;
  control: Control<any>;
  watch: UseFormWatch<Port>;
}

export const PortFields: FunctionComponent<PortFieldsProps> = ({
  register,
  errors,
  control,
  watch,
  setValue,
}) => {
  const { countries } = useData();

  const portType = watch('portType') in PortType ? watch('portType') : null;
  const countryId =
    watch('country') == null ? null : watch('country').countryID;

  if (countries.isLoading) {
    return (
      <GridItem colSpan={2}>
        <Center>
          <Spinner size="xl" />
        </Center>
      </GridItem>
    );
  }

  return (
    <>
      <GridItem>
        <FormInput
          label="Name"
          register={register}
          errors={errors}
          accessor={'name'}
          isRequired={true}
          registerOptions={{
            maxLength: { value: 50, message: 'Maximum of 50 characters' },
          }}
        />
      </GridItem>
      <GridItem>
        <SelectFormInput
          label="Type"
          accessor={'portType'}
          placeholder="Select a Type"
          isRequired={true}
          control={control}
          options={[
            { label: 'Air', value: '4' },
            { label: 'Sea', value: 1 },
          ]}
          defaultValue={
            portType && {
              label: PortType[portType],
              value: portType,
            }
          }
          onChange={(value) => {
            setValue(
              'portType',
              Number.parseInt(
                (
                  value as { label: string; value: StringOrNumber }
                ).value.toString(),
              ),
            );
            if (
              Number.parseInt(
                (
                  value as { label: string; value: StringOrNumber }
                ).value.toString(),
              ) !== PortType.Air
            ) {
              setValue('iataCode', '');
            }
          }}
        />
      </GridItem>
      <GridItem>
        <SelectFormInput
          label="Country"
          accessor={'country.countryID'}
          control={control}
          placeholder="Select a Country"
          isRequired={true}
          defaultValue={
            countryId && {
              label: countries.data?.find(
                (country) => country.countryID === countryId,
              )?.name,
              value: countryId,
            }
          }
          options={countries.data?.map((country) => ({
            label: country.name,
            value: country.countryID,
          }))}
        />
      </GridItem>
      <GridItem>
        <FormInput
          label="UNLO Code"
          register={register}
          errors={errors}
          accessor={'unloCode'}
          isRequired={true}
          registerOptions={{
            maxLength: { value: 5, message: 'Maximum of 5 characters' },
          }}
        />
      </GridItem>
      <GridItem>
        <FormInput
          label="Location String"
          register={register}
          errors={errors}
          accessor={'locationString'}
          placeholder="e.g. 53.5446991423739, 9.996696213565075"
          isRequired={true}
        />
      </GridItem>
      <GridItem>
        <FormInput
          label="City"
          register={register}
          errors={errors}
          accessor={'city'}
          isRequired={true}
        />
      </GridItem>
      <GridItem>
        <FormInput
          label="Location Code"
          register={register}
          errors={errors}
          accessor={'locationCode'}
          registerOptions={{
            maxLength: { value: 5, message: 'Maximum of 5 characters' },
          }}
        />
      </GridItem>
      {Number.parseInt(watch('portType').toString()) === PortType.Air && (
        <GridItem>
          <FormInput
            label="IATA Code"
            register={register}
            errors={errors}
            accessor={'iataCode'}
            registerOptions={{
              maxLength: { value: 3, message: 'Maximum of 3 characters' },
            }}
          />
        </GridItem>
      )}

      <GridItem colSpan={2}>
        <Checkbox {...register('isActive')}>Is Active</Checkbox>
      </GridItem>
    </>
  );
};
