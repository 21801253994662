import { Grid } from '@chakra-ui/layout';
import type { FunctionComponent } from 'react';
import { useQuery } from 'react-query';
import { useErrorToast } from '../../../hooks/useErrorToast';
import { useApi } from '../../../providers/ApiProvider';
import { ExpirationSummaryData } from '../../../types/ExpirationSummaryData';
import { getHttpStatusDescription } from '../../../utils/httpStatus';
import { RateCountCard } from './RateCountCard';

export const DashboardGrid: FunctionComponent = () => {
  const { getApi } = useApi();
  const errorToast = useErrorToast();

  const { isLoading, data, refetch } = useQuery(
    'expirationSummary',
    async () => {
      const result = await getApi('rates/expirationSummary');

      if (result.ok) {
        const resultData: ExpirationSummaryData = await result.json();
        return resultData;
      }

      errorToast({
        title: getHttpStatusDescription(result.status),
      });
    },
  );

  return (
    <Grid
      templateRows="repeat(2, auto)"
      templateColumns={{ base: 'repeat(1, auto)', md: 'repeat(2, 1fr)' }}
      gap="2"
    >
      <RateCountCard
        isLoading={isLoading}
        isExpired={false}
        data={data?.expiringSoon}
        refetch={refetch}
      />
      <RateCountCard
        isLoading={isLoading}
        isExpired={true}
        data={data?.expired}
        refetch={refetch}
      />
    </Grid>
  );
};
