import { IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import {
  Box,
  Button,
  Center,
  Flex,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import type { FunctionComponent } from 'react';
import type { User } from '../types';
import { deleteCookie } from '../utils/cookie';
import { useApi } from './ApiProvider';

interface AuthenticationContextProps {
  claims: TokenClaims | null;
  client: IPublicClientApplication | null;
  user: User | null;
  reloadUser: () => Promise<void>;
}

export interface TokenClaims {
  sub: string;
  oid: string;
  exp: number;
  name: string;
  email: string;
  country: string;
  extension_Comment: string;
  extension_Company: string;
}

const AuthenticationContext = createContext<AuthenticationContextProps>({
  claims: null,
  client: null,
  user: null,
  reloadUser: () => Promise.resolve(),
});

export const useAuthentication = () => useContext(AuthenticationContext);

export const AuthenticationProvider: FunctionComponent = ({ children }) => {
  const [values, setValues] = useState<AuthenticationContextProps | null>(null);
  const [initialAuth, setInitialAuth] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [isResetting, setIsResetting] = useState<boolean>(false);
  const { instance, accounts, inProgress } = useMsal();
  const { getApi } = useApi();

  const fetchUser = useCallback(async () => {
    const result = await getApi('users/me');
    return result.ok ? result.json() : null;
  }, [getApi]);

  const reloadUser = useCallback(async () => {
    const user = await fetchUser();

    setValues({
      claims: values?.claims ?? null,
      client: instance,
      user,
      reloadUser,
    });
  }, [fetchUser, instance, values]);

  const authenticate = useCallback(async () => {
    if (initialAuth) return;

    if (accounts.length > 0) {
      setInitialAuth(true);
      // TODO: revisit this line, as it should work well without casting to "unknown"
      const claims = accounts[0].idTokenClaims as unknown as TokenClaims;
      claims.email = accounts[0].username;
      const user = await fetchUser();

      setValues({
        claims,
        client: instance,
        user,
        reloadUser,
      });
    } else if (inProgress === 'none') {
      try {
        const params = new URLSearchParams(window.location.search);
        const email = params.get('email') ?? '';
        await instance.loginRedirect({
          scopes: ['openid', 'profile', 'offline_access'],
          extraQueryParameters: { login_hint: encodeURIComponent(email) },
        });
      } catch (err) {
        setInitialAuth(true);
        setHasError(true);
      }
    }
  }, [accounts, inProgress, initialAuth, fetchUser, instance, reloadUser]);

  const onResetAuth = useCallback(() => {
    setIsResetting(true);
    deleteCookie('msal.interaction.status');
    window.location.reload();
  }, []);

  useEffect(() => {
    authenticate();
  }, [authenticate]);

  if (values != null) {
    return (
      <AuthenticationContext.Provider value={values}>
        {children}
      </AuthenticationContext.Provider>
    );
  }

  return (
    <Flex
      minH="100vh"
      justifyContent="center"
      textAlign="center"
      bgImage="url('/assets/LoadingBackground.jpg')"
      bgSize="cover"
    >
      <Stack maxW="xl" py="4rem" spacing={10}>
        <Box>
          <Text fontSize="s">Welcome to</Text>
          <Text fontSize="xl" fontWeight="900">
            Pricing Manager
          </Text>
        </Box>
        <Center>
          <Spinner size="xl" />
        </Center>
        {hasError && (
          <Stack spacing={10} p="6" borderRadius="20" bg="blackAlpha.800">
            <Text>
              Cannot initiate the authentication process. Another authentication
              request is probably on-going, please make sure you finish the
              previous request before retrying. You can also reset the
              authentication process using the following button:
            </Text>
            <Button
              colorScheme="blue"
              isLoading={isResetting}
              onClick={onResetAuth}
            >
              Reset Authentication
            </Button>
          </Stack>
        )}
      </Stack>
    </Flex>
  );
};
