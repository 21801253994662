import { AlertProps } from '@chakra-ui/alert';
import type { FunctionComponent } from 'react';
import { Result } from '.';

export const NoResult: FunctionComponent<AlertProps> = ({ ...rest }) => (
  <Result
    status="info"
    title="No results!"
    description="No results were found. Adjust your search criteria and try again."
    bg="gray.700"
    {...rest}
  />
);
