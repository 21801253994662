import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Button } from '@chakra-ui/react';

export const SuggestButton = () => (
  <Button
    as="a"
    colorScheme="secondary"
    target="_blank"
    href="https://pricingmanager.featurebase.app/"
    rightIcon={<ArrowForwardIcon />}
  >
    Suggest A Feature
  </Button>
);
