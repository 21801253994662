import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Radio, RadioGroup } from '@chakra-ui/radio';
import { SimpleGrid, Stack, useBreakpointValue } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import shallow from 'zustand/shallow';
import { DeselectableSelectInput } from '../../../../components/Input/DeselectableSelectInput';
import { useLocalFilters } from '../../../../hooks/useLocalFilters';
import { useData } from '../../../../providers/DataProvider';
import { TransportMode } from '../../../../types';

export const LocalChargeFilter: FunctionComponent = () => {
  const { countries } = useData();
  const onMobile = useBreakpointValue({ base: true, xl: false });

  const {
    countryId,
    transport,
    category,
    setCategory,
    setTransportMode,
    setCountry,
  } = useLocalFilters(
    (state) => ({
      countryId: state.countryId,
      transport: state.transportMode,
      category: state.category,
      setCountry: state.setCountry,
      setTransportMode: state.setTransportMode,
      setCategory: state.setCategory,
    }),
    shallow,
  );

  return (
    <SimpleGrid columns={{ base: 1, xl: 3 }} spacing={{ base: '8', xl: '12' }}>
      <DeselectableSelectInput
        label="Country"
        accessor="country"
        currentValue={countryId}
        onSelectionChange={(selected) => {
          setCountry(selected?.value);
        }}
        width={onMobile ? '100%' : '13rem'}
        options={
          countries.data?.map((country) => ({
            label: country.name,
            value: country.countryID,
          })) ?? []
        }
        noOptionsMessage="You don't have any assigned countries"
      />
      <FormControl>
        <FormLabel htmlFor="transport">Transport Type</FormLabel>
        <RadioGroup
          id="transport"
          value={transport}
          onChange={(value) => {
            setTransportMode(parseInt(value) as TransportMode);
          }}
        >
          <Stack direction="row" h="2.5rem" spacing={{ base: '4', xl: '8' }}>
            <Radio value={TransportMode.Air}>Air</Radio>
            <Radio value={TransportMode.LCL}>LCL</Radio>
            <Radio value={TransportMode.FCL}>FCL</Radio>
          </Stack>
        </RadioGroup>
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="category">Category</FormLabel>
        <RadioGroup
          id="category"
          value={category}
          onChange={(value: 'Import' | 'Export') => {
            setCategory(value);
          }}
        >
          <Stack direction="row" h="2.5rem" spacing={{ base: '4', xl: '8' }}>
            <Radio value="Export">Origin</Radio>
            <Radio value="Import">Destination</Radio>
          </Stack>
        </RadioGroup>
      </FormControl>
    </SimpleGrid>
  );
};
