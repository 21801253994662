import {
  Button,
  FormLabel,
  HStack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { useQuery } from 'react-query';
import { EditIcon } from '../../../../components/Icons';
import { useApi } from '../../../../providers/ApiProvider';
import { useData } from '../../../../providers/DataProvider';
import { TransportMode } from '../../../../types';
import { TruckingSurcharge } from '../../../../types/TruckingSurcharge';
import {
  EditTruckingSurchargeValues,
  UpsertSurchargeSettingsModal,
} from './UpsertSurchargeSettingsModal';

interface ITruckingSurchargeSettingsProps {
  countryId?: string;
  transportType: TransportMode;
  canMutateSettings: boolean;
}

const defaultSurcharges = {
  fuelSurcharge: 0,
  chargeableWeightRatio: {
    Air: 166.67,
    Lcl: 166.67,
  },
};

export const TruckingSurchargeSettings: FunctionComponent<ITruckingSurchargeSettingsProps> =
  ({ countryId, transportType, canMutateSettings }) => {
    const { getApi, postApi, putApi } = useApi();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { countries } = useData();
    const toast = useToast();

    const { data: truckingSurchargeRate, refetch } = useQuery<
      TruckingSurcharge | undefined
    >(['trucking-surcharge', countryId, transportType], async () => {
      if (countryId === undefined) return undefined;
      const result = await getApi(
        `rates/trucking-surcharge?countryID=${countryId}&transportType=${transportType}`,
      );
      if (result.ok) {
        const truckingSurcharge = (await result.json()) as TruckingSurcharge;
        return truckingSurcharge;
      }

      return undefined;
    });

    const onSave = async (data: EditTruckingSurchargeValues) => {
      const requestData = {
        ...data,
        country: countries.data!.find(
          (country) => country.countryID === countryId,
        ),
        transportMode: transportType,
      };
      try {
        if (truckingSurchargeRate != null) {
          await putApi(
            `rates/trucking-surcharge/${truckingSurchargeRate.id}`,
            requestData,
          );
        } else {
          await postApi('rates/trucking-surcharge', requestData);
        }
      } catch (e) {
        toast({ title: 'Something went wrong' });
        return false;
      }
      refetch();
      return true;
    };

    const defaultChargeableWeightRatio =
      transportType === TransportMode.Air
        ? defaultSurcharges.chargeableWeightRatio.Air
        : defaultSurcharges.chargeableWeightRatio.Lcl;

    return (
      <HStack gap="8">
        <VStack alignItems="flex-start">
          <FormLabel mb="0">Fuel Surcharge</FormLabel>
          <Text fontSize="sm">
            {truckingSurchargeRate?.percentFuel ??
              defaultSurcharges.fuelSurcharge}{' '}
            %
          </Text>
        </VStack>
        <VStack alignItems="flex-start">
          <FormLabel mb="0">Chargeable Weight Ratio</FormLabel>
          <HStack>
            <Text color="grey.300">1 cbm =</Text>
            <Text fontSize="sm">
              {truckingSurchargeRate?.chargeableWeightRatio ??
                defaultChargeableWeightRatio}{' '}
              kg
            </Text>
          </HStack>
        </VStack>
        {canMutateSettings && (
          <Button
            colorScheme="blue"
            fontSize="sm"
            rightIcon={<EditIcon w="4" h="4" />}
            onClick={onOpen}
          >
            Edit
          </Button>
        )}
        <UpsertSurchargeSettingsModal
          isOpen={isOpen}
          onClose={onClose}
          onSave={onSave}
          surchargeValues={{
            chargeableWeightRatio:
              truckingSurchargeRate?.chargeableWeightRatio ??
              defaultChargeableWeightRatio,
            percentFuel:
              truckingSurchargeRate?.percentFuel ??
              defaultSurcharges.fuelSurcharge,
          }}
          countryId={countryId}
          transportMode={transportType}
        />
      </HStack>
    );
  };
