import { BracketWeightDto } from '../../../../types/api/BracketWeightDto';

export const EMPTY_BRACKET: BracketWeightDto = {
  bracketWeightID: '',
  isMinValue: false,
  order: 0,
  tariffTrucking: null,
  tariffTruckingID: '',
  value: 0,
};
