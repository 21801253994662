import { useToast, UseToastOptions } from '@chakra-ui/react';

export const useErrorToast = (options?: UseToastOptions) =>
  useToast({
    status: 'error',
    isClosable: true,
    position: 'top',
    variant: 'solid',
    ...options,
  });
