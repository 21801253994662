import type { FunctionComponent } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useErrorToast } from '../../hooks/useErrorToast';
import { useApi } from '../../providers/ApiProvider';
import type {
  Authentication,
  AuthenticationRequest,
  ThirdParty,
} from '../../types';
import { getHttpStatusDescription } from '../../utils/httpStatus';
import { routes } from '../../utils/routes';
import { ThirdPartyApiForm } from '../ThirdPartyApiPage/ThirdPartyApiForm';

const errorTitle = 'Saving your changes failed, please try again';

export const ThirdPartyApiEditPage: FunctionComponent = () => {
  const { id } = useParams();
  const { getApi, putApi } = useApi();
  const errorToast = useErrorToast();
  const navigate = useNavigate();
  const [statusCode, setStatusCode] = useState<number>(0);
  const [isLoadingAuthentication, setLoadingAuthentication] =
    useState<boolean>(true);
  const [authentication, setAuthentication] = useState<
    Authentication | undefined
  >();

  const fetchAuthentication = useCallback(async () => {
    setLoadingAuthentication(true);
    setAuthentication(undefined);
    const result = await getApi(`authentications/${id}`);
    setStatusCode(result.status);
    if (result.ok) setAuthentication(await result.json());
    setLoadingAuthentication(false);
  }, [getApi, id]);

  const { isLoading: isLoadingThirdParties, data: thirdParties } = useQuery<
    ThirdParty[]
  >(
    ['third-parties'],
    async () => {
      const result = await getApi(`third-parties`);
      setStatusCode(result.status);
      if (result.ok) return result.json();
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const saveChanges = useCallback(
    async (data: AuthenticationRequest) => {
      let response;

      try {
        response = await putApi(`authentications/${id}`, data);
      } catch (e) {
        errorToast({
          title: errorTitle,
        });
        return;
      }

      if (response.ok) {
        navigate(routes.thirdPartyApi.base);
      } else {
        errorToast({
          title:
            response.status === 409
              ? await response.text()
              : getHttpStatusDescription(response.status),
        });
      }
    },
    [putApi, id, navigate, errorToast],
  );

  useEffect(() => {
    fetchAuthentication();
  }, [fetchAuthentication]);

  return (
    <ThirdPartyApiForm
      authentication={authentication}
      thirdParties={thirdParties}
      isLoading={isLoadingAuthentication || isLoadingThirdParties}
      statusCode={statusCode}
      onSubmit={saveChanges}
    />
  );
};
