import { DeleteIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import {
  useForm,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { getMasterDataTypeSingular } from '..';
import { ActionModal } from '../../../components/ActionModal';
import { ChargeCode, Country, Currency, Port, PortType } from '../../../types';
import { MasterData, MasterDataType, SelectedSystem } from '../../../types/MasterData';
import { ChargeCodeFields } from './ChargeCodeFields';
import { CountryFields } from './CountryFields';
import { CurrencyFields } from './CurrencyFields';
import { PortFields } from './PortFields';

interface UpsertMasterDataModalProps {
  masterDataType: MasterDataType,
  isOpen: boolean,
  onClose: () => void,
  isCreate: boolean,
  editItem: MasterData | null,
  onDelete: (itemID: string, masterDataType: MasterDataType) => void,
  onCreate: (item: MasterData, type: MasterDataType) => Promise<boolean>,
  onUpdate: (
    newValues: MasterData,
    editItem: MasterData,
    type: MasterDataType,
  ) => Promise<boolean>,
  chargeCodesSelectedSystem?: SelectedSystem
}

export const UpsertMasterDataModal: FunctionComponent<UpsertMasterDataModalProps> =
  ({
     masterDataType,
     isOpen,
     onClose,
     isCreate,
     editItem,
     onDelete,
     onCreate,
     onUpdate,
     chargeCodesSelectedSystem,
   }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {
      isOpen: isDeleteOpen,
      onClose: onCloseDelete,
      onOpen: onOpenDelete,
    } = useDisclosure();

    const getModalHeader = () => {
      if (isCreate) {
        return `Add ${getMasterDataTypeSingular(masterDataType)}`;
      } else {
        return `Edit ${getMasterDataTypeSingular(masterDataType)}`;
      }
    };

    const getDefaultValues = useCallback(() => {
      switch (masterDataType) {
        case MasterDataType.COUNTRIES:
          const country = editItem as Country;
          return {
            code: country?.code ?? '',
            name: country?.name ?? '',
            isActive: country?.isActive ?? false,
            region: country?.region,
          } as Country;
        case MasterDataType.CURRENCIES:
          const currency = editItem as Currency;
          return {
            code: currency?.code ?? '',
            isConvertible: currency?.isConvertible ?? false,
            name: currency?.name ?? '',
          } as Currency;
        case MasterDataType.PORTS:
          const port = editItem as Port;
          return {
            name: port?.name ?? '',
            country: port?.country,
            iataCode: port?.iataCode ?? '',
            isActive: port?.isActive ?? false,
            locationCode: port?.locationCode ?? '',
            locationString: port?.locationString ?? '',
            city: port?.city ?? '',
            portType: port?.portType ?? PortType.Air,
            unloCode: port?.unloCode ?? '',
          } as Port;
        case MasterDataType.CHARGE_CODES:
          const chargeCode = editItem as ChargeCode;
          return {
            code: chargeCode?.code ?? '',
            name: chargeCode?.name ?? '',
            isActive: chargeCode?.isActive ?? false,
            chargeCodeMappings: chargeCode?.chargeCodeMappings ?? [],
          } as ChargeCode;
      }
    }, [editItem, masterDataType]);

    const {
      handleSubmit,
      register,
      formState: { errors },
      reset,
      setValue,
      control,
      watch,
    } = useForm<MasterData>({
      defaultValues: { ...getDefaultValues() },
    });

    useEffect(() => {
      reset({ ...getDefaultValues() });
    }, [getDefaultValues, reset, editItem]);

    const fields = () => {
      switch (masterDataType) {
        case MasterDataType.COUNTRIES:
          return (
            <CountryFields
              register={register as UseFormRegister<Country>}
              errors={errors}
              control={control}
              watch={watch as UseFormWatch<Country>}
            />
          );
        case MasterDataType.CURRENCIES:
          return (
            <CurrencyFields
              register={register as UseFormRegister<Currency>}
              errors={errors}
            />
          );
        case MasterDataType.PORTS:
          return (
            <PortFields
              control={control}
              errors={errors}
              register={register as UseFormRegister<Port>}
              setValue={setValue as UseFormSetValue<Port>}
              watch={watch as UseFormWatch<Port>}
            />
          );
        case MasterDataType.CHARGE_CODES:
          return (
            <ChargeCodeFields
              control={control}
              register={register as UseFormRegister<ChargeCode>}
              errors={errors}
              watch={watch as UseFormWatch<ChargeCode>}
              setValue={setValue as UseFormSetValue<ChargeCode>}
              selectedSystem={chargeCodesSelectedSystem}
            />
          );
      }
    };

    const onSubmit = async (item: MasterData) => {
      setIsSubmitting(true);
      let success: boolean = isCreate
        ? await onCreate(item, masterDataType)
        : await onUpdate(item, editItem!, masterDataType);
      setIsSubmitting(false);
      if (success) {
        onClose();
        reset();
      }
    };
    const handleClose = () => {
      onClose();
      reset();
    };
    const onClickDelete = () => {
      switch (masterDataType) {
        case MasterDataType.COUNTRIES:
          const country = editItem as Country;
          onDelete(country.countryID, masterDataType);
          break;
        case MasterDataType.CURRENCIES:
          const currency = editItem as Currency;
          onDelete(currency.currencyID, masterDataType);
          break;
        case MasterDataType.PORTS:
          const port = editItem as Port;
          onDelete(port.portID, masterDataType);
          break;
        case MasterDataType.CHARGE_CODES:
          const chargeCode = editItem as ChargeCode;
          onDelete(chargeCode.chargeCodeID, masterDataType);
          break;
      }
      handleClose();
    };

    return (
      <>
        <ActionModal
          onSuccess={onCloseDelete}
          onCancel={onCloseDelete}
          onActionClick={onClickDelete}
          isOpen={isDeleteOpen}
          modalText={`Do you really want to delete this
          ${getMasterDataTypeSingular(masterDataType)}?`}
          modalHeader={`Delete ${getMasterDataTypeSingular(masterDataType)}`}
        />
        <Modal isOpen={isOpen} onClose={handleClose}>
          <ModalOverlay />
          <ModalContent
            bg="grey.800"
            minWidth={{ base: '20rem', md: '37.5rem' }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <ModalCloseButton />
              <ModalHeader borderBottom="4px" borderColor="grey.900">
                {getModalHeader()}
              </ModalHeader>
              <ModalBody py="8" px={{ base: '7', md: '14' }}>
                <Stack spacing={{ base: 12, lg: 24 }}>
                  <Grid
                    gap={4}
                    templateColumns={{
                      base: 'repeat(1, 1fr)',
                      lg: 'repeat(2, 1fr)',
                    }}
                  >
                    {fields()}
                  </Grid>
                </Stack>
                {!isCreate && (
                  <Button
                    w="100%"
                    mt="8"
                    py="8"
                    variant="ghost"
                    _hover={{ bgColor: 'grey.600' }}
                    colorScheme="white"
                    color="grey.400"
                    borderTop="1px solid grey.900"
                    borderBottom="1px solid grey.900"
                    borderRadius="0"
                    onClick={onOpenDelete}
                  >
                    <DeleteIcon mr="2" />
                    <Box>Delete</Box>
                  </Button>
                )}
              </ModalBody>
              <ModalFooter>
                <Button
                  mr={3}
                  onClick={handleClose}
                  colorScheme="white"
                  variant="ghost"
                  _hover={{ bgColor: 'grey.600' }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  colorScheme="blue"
                  isLoading={isSubmitting}
                >
                  Save
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      </>
    );
  };
