import { Port, TransportMode } from '../../types';

export const getAssignedPorts = (ports: Port[], transportMode: TransportMode) => {
  if (!ports) return '';
  const portCodes: string[] = [];
  ports.forEach((port) => {
    if(transportMode === TransportMode.Air)
      portCodes.push(port.iataCode );
    else
      portCodes.push(port.unloCode );
    } 
  )
  return portCodes.join(', ');
};