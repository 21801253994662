export const colors = {
  red: {
    '50': '#FDF1EF',
    '100': '#F9DCD7',
    '200': '#F3BCB4',
    '300': '#ED9E93',
    '400': '#E7796A',
    '500': '#E0523F',
    '600': '#C13A29',
    '700': '#962618',
    '800': '#711A0F',
    '900': '#4C0F07',
  },
  yellow: {
    '50': '#FCF4EB',
    '100': '#F8E9D7',
    '200': '#F4DCBF',
    '300': '#EFC99E',
    '400': '#E9B375',
    '500': '#E0953F',
    '600': '#B47329',
    '700': '#855218',
    '800': '#5B360C',
    '900': '#412506',
  },
  blue: {
    '50': '#B3EEFF',
    '100': '#80E3FF',
    '200': '#4DD8FF',
    '300': '#19CDFF',
    '400': '#00C8FF',
    '500': '#0095C7',
    '600': '#31AFF7',
    '700': '#1695DE',
    '800': '#007CC4',
    '900': '#006CAB',
  },
  secondary: {
    '50': '#E0F7FF',
    '100': '#CADFE6',
    '200': '#B4C6CC',
    '300': '#9DADB3',
    '400': '#879499',
    '500': '#758185',
    '600': '#5A6366',
    '700': '#434A4D',
    '800': '#434A4D',
    '900': '#2D3133',
  },
  grey: {
    '50': '#FBFBFB',
    '100': '#F0F0ED',
    '200': '#D3D8DB',
    '300': '#9DA1A3',
    '400': '#636369',
    '500': '#353638',
    '600': '#36363B',
    '700': '#38383B',
    '750': '#242426',
    '800': '#1E1E21',
    '900': '#17171A',
  },
  grayAlpha: {
    50: 'rgba(245, 245, 245, 0.04)',
    100: 'rgba(245, 245, 245, 0.06)',
    200: 'rgba(245, 245, 245, 0.08)',
    300: 'rgba(245, 245, 245, 0.16)',
    400: 'rgba(245, 245, 245, 0.24)',
    500: 'rgba(245, 245, 245, 0.36)',
    600: 'rgba(245, 245, 245, 0.48)',
    700: 'rgba(245, 245, 245, 0.64)',
    800: 'rgba(245, 245, 245, 0.80)',
    900: 'rgba(245, 245, 245, 0.92)',
  },
};
