import { Button, Heading, Spacer, Stack, Text } from '@chakra-ui/react';
import { useCallback } from 'react';
import type { FunctionComponent } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { FormInput } from '../../components/Input';
import { HeadingCard, Card } from '../../components/Layout';
import { Result } from '../../components/Result';
import { useErrorToast } from '../../hooks/useErrorToast';
import { useApi } from '../../providers/ApiProvider';
import { useAuthentication } from '../../providers/AuthenticationProvider';
import { useAuthorization } from '../../providers/AuthorizationProvider';
import { ApprovalStatus } from '../../types';
import { getHttpStatusDescription } from '../../utils/httpStatus';

const errorTitle = 'Saving your changes failed, please try again';

export const OnboardingPage: FunctionComponent = () => {
  const { postApi } = useApi();
  const { client, claims, reloadUser } = useAuthentication();
  const { approvalStatus } = useAuthorization();
  const errorToast = useErrorToast();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  const submitRequest = useCallback(
    async (values: FieldValues) => {
      let response;
      try {
        response = await postApi('organizations/register', {
          name: values.organizationName,
          ownerIdentityProviderID: claims?.oid,
          ownerName: claims?.name,
          ownerEmail: claims?.email,
          ownerCountry: claims?.country,
        });
      } catch (e) {
        errorToast({ title: errorTitle });
        return false;
      }

      if (response.ok) {
        await reloadUser();
      } else {
        errorToast({
          title: getHttpStatusDescription(response.status),
        });
      }
    },
    [claims, postApi, reloadUser, errorToast],
  );

  return (
    <Stack>
      <HeadingCard
        heading="Onboarding"
        direction="row"
        align="center"
        justify="space-between"
      >
        <Button colorScheme="red" size="xs" onClick={() => client?.logout()}>
          Logout
        </Button>
      </HeadingCard>
      <Card spacing="2">
        {approvalStatus === ApprovalStatus.PENDING ? (
          <Result
            status="info"
            title="Pending Approval"
            description="Thank you for requesting access. We'll come back to you as soon as your account is approved."
          />
        ) : approvalStatus === ApprovalStatus.DECLINED ? (
          <Result
            status="error"
            title="Access Request Declined"
            description="We're sorry to inform you that your access request was declined. Contact support if you think this is a mistake."
          />
        ) : (
          <>
            <Heading as="h2" size="sm">
              Joining an Organization
            </Heading>
            <Text>
              In order to finish your sign-up process, you need to either join
              an organization or create one. If you already know which
              organization you belong to, then simply contact them and ask them
              to invite you to the organization. Otherwise, use the form below
              to register a new one.
            </Text>
            <Text>
              Please note that after registering an organization, you'll have to
              wait until the organization approved. You will be notified by
              email about the approval status.
            </Text>
            <Spacer />
            <form onSubmit={handleSubmit(submitRequest)}>
              <FormInput
                accessor="organizationName"
                label="Organization Name"
                register={register}
                errors={errors}
                defaultValue={claims?.extension_Company}
                isRequired={true}
              />
              <Button
                colorScheme="blue"
                isLoading={isSubmitting}
                type="submit"
                mt="1rem"
              >
                Submit
              </Button>
            </form>
          </>
        )}
      </Card>
    </Stack>
  );
};
