import create from 'zustand';
import { TransportMode } from './../types/Rate';

interface FilterStore {
  countryId: string | undefined;
  transportMode: TransportMode;
  category: 'Import' | 'Export';
  setCountry: (countryId: string) => void;
  setTransportMode: (transportMode: TransportMode) => void;
  setCategory: (category: 'Import' | 'Export') => void;
  reset: () => void;
}

export const useLocalFilters = create<FilterStore>((set) => ({
  countryId: undefined,
  transportMode: TransportMode.Air,
  category: 'Export',
  setCountry: (countryId) => set(() => ({ countryId })),
  setTransportMode: (transportMode) => set(() => ({ transportMode })),
  setCategory: (category) => set(() => ({ category })),
  reset: () =>
    set(() => ({
      countryId: undefined,
      transportMode: TransportMode.Air,
      category: 'Export',
    })),
}));
