import { ComponentStyleConfig } from '@chakra-ui/react';

export const Select: ComponentStyleConfig = {
  variants: {
    outline: {
      field: {
        borderRadius: '8px',
        _focus: {
          borderColor: 'blue.500',
          boxShadow: `0 0 0 1px blue.500`,
        },
      },
      _hover: {
        borderColor: 'grey.400',
      },
      fontSize: 'xs',
    },
  },
};
