import { Button, Flex, useDisclosure } from '@chakra-ui/react';
import type { TableProps } from '@chakra-ui/react';
import { ReactElement, useMemo, useState } from 'react';
import type { Column } from 'react-table';
import { TableActionButtonProps } from '../../theme/components';
import { Rate, RateType } from '../../types';
import { ActionModal } from '../ActionModal';
import { DataTable } from '../DataTable';
import { EditIcon } from '../Icons';
import { UpsertRateModal } from './UpsertRateModal';

interface RateTableProps<
  T extends Omit<Rate, 'organizationId'>,
  K extends object,
> extends TableProps {
  rateType: RateType;
  data?: T[];
  columns: Column<T>[];
  isLoading: boolean;
  fetchFailed: boolean;
  statusCode: number;
  onDelete: (rateId: string) => Promise<boolean>;
  onEdit: (submitData: K, rate?: T) => Promise<boolean>;
  empty?: ReactElement;
  showActionColumn?: boolean;
  createRateModalHeaderTitle?: string;
  updateRateModalHeaderTitle?: string;
  enableFilters?: boolean;
}

export const RateTable = <
  R extends Omit<Rate, 'organizationId'>,
  S extends object,
>({
  rateType,
  data,
  columns,
  isLoading,
  fetchFailed,
  statusCode,
  onDelete,
  onEdit,
  empty,
  showActionColumn = true,
  createRateModalHeaderTitle = 'Create Rate',
  updateRateModalHeaderTitle = 'Edit Rate',
  enableFilters = false,
}: RateTableProps<R, S>) => {
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [rate, setRate] = useState<R | null>(null);

  const actionColumn: Column<R> = useMemo(
    () => ({
      Header: '',
      id: 'action',
      accessor: (rowRate: R) => (
        <Flex w="100%" justifyContent="flex-end">
          <Button
            {...TableActionButtonProps}
            onClick={() => {
              setRate(rowRate);
              onOpen();
            }}
            rightIcon={<EditIcon w="4" h="4" />}
          >
            Edit
          </Button>
        </Flex>
      ),
      disableFilters: true,
    }),
    [onOpen],
  );

  const newColumns = useMemo(
    () => (showActionColumn ? [...columns, actionColumn] : columns),
    [showActionColumn, columns, actionColumn],
  );

  return (
    <>
      {rate && (
        <ActionModal
          onCancel={() => onDeleteClose()}
          onSuccess={() => {
            onDeleteClose();
            onClose();
          }}
          isOpen={isDeleteOpen}
          onActionClick={() => onDelete(rate.id)}
          modalText="Do you really want to delete this rate?"
          modalHeader="Delete Rate"
          align="center"
        />
      )}
      {rate && (
        <UpsertRateModal<R, S>
          rateType={rateType}
          isOpen={isOpen}
          onClose={onClose}
          onSave={onEdit}
          rate={rate}
          isCreate={rate.id === ''}
          onDeleteOpen={onDeleteOpen}
          createRateHeaderTitle={createRateModalHeaderTitle}
          updateRateHeaderTitle={updateRateModalHeaderTitle}
        />
      )}
      <DataTable
        data={data}
        columns={newColumns}
        isLoading={isLoading}
        enableFilters={enableFilters}
        fetchFailed={fetchFailed}
        statusCode={statusCode}
        empty={empty}
        variant={showActionColumn ? 'sticky' : 'default'}
        minHeight="20rem"
      />
    </>
  );
};
