import { DeleteIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Divider,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import { addDays } from 'date-fns';
import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { SelectInstance } from 'react-select';
import shallow from 'zustand/shallow';
import {
  mapCalculatorHeader,
  mapUnitHeader,
} from '../../../../components/IfApplicableTable';
import {
  DateFormInput,
  NumberFormInput,
  SelectFormInput,
  SelectInputOption,
  TextareaFormInput
} from '../../../../components/Input';
import { useLocalFilters } from '../../../../hooks/useLocalFilters';
import { useApi } from '../../../../providers/ApiProvider';
import { useData } from '../../../../providers/DataProvider';
import {
  Country,
  getPortTypeFromTransportMode,
  Port,
  TransportMode,
} from '../../../../types'; 
import {
  IfApplicable,
  IfApplicableCalculatorType,
  IfApplicableUnitType,
} from '../../../../types/IfApplicable';
import { Optional } from '../../../../types/Optional';

const ifApplicableAmountCalculators = [
  IfApplicableCalculatorType.FLT,
  IfApplicableCalculatorType.PER,
];  

export const isIfApplicableAmountCalculator = (
  calculator: IfApplicableCalculatorType,
) => ifApplicableAmountCalculators.includes(calculator);

const isUnitApplicableForTransportType = (
  unit: IfApplicableUnitType,
  transportType: TransportMode,
) => {
  const airApplicable = [
    IfApplicableUnitType.KG,
    IfApplicableUnitType.HB,
    IfApplicableUnitType.KG100,
    IfApplicableUnitType.Percent,
    IfApplicableUnitType.Package,
    IfApplicableUnitType.AdditionalLine,
  ];

  const lclApplicable = [
    IfApplicableUnitType.M3Chargeable,
    IfApplicableUnitType.HB,
    IfApplicableUnitType.Kg1000,
    IfApplicableUnitType.KG100,
    IfApplicableUnitType.Percent,
    IfApplicableUnitType.AdditionalLine,
  ];

  const fclApplicable = [
    IfApplicableUnitType.Container,
    IfApplicableUnitType.TEU,
    IfApplicableUnitType.Percent,
    IfApplicableUnitType.HB,
    IfApplicableUnitType.AdditionalLine,
  ];

  switch (transportType) {
    case TransportMode.Air:
      return airApplicable.includes(unit);
    case TransportMode.FCL:
      return fclApplicable.includes(unit);
    case TransportMode.LCL:
      return lclApplicable.includes(unit);
    default:
      return false;
  }
};

interface IfApplicableFormData
  extends Omit<
    Optional<IfApplicable, 'chargeCode' | 'currency' | 'unitType' | 'infoId'>,
    'isOrigin' | 'isDestination' | 'rateCountry' | 'shippingType'
  > {}

interface IUpsertIfApplicableRate {
  isOpen: boolean;
  isCreate: boolean;
  onSave: (data: IfApplicable) => Promise<boolean>;
  onClose: () => void;
  rate?: IfApplicable;
  onDeleteOpen: () => void;
}

export const UpsertIfApplicableRate: FunctionComponent<IUpsertIfApplicableRate> =
  ({ isOpen, isCreate, onSave, onClose, rate, onDeleteOpen }) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [previousCalulator, setPreviousCalculator] = useState<
      IfApplicableCalculatorType | undefined
    >();
    const { currencies, chargeCodes, countries } = useData();
    const { getApi } = useApi();
    const { countryId, transport, category } = useLocalFilters(
      (state) => ({
        countryId: state.countryId,
        transport: state.transportMode,
        category: state.category,
      }),
      shallow,
    );

    const { data: ports } = useQuery<Port[]>(['ports', transport], async () => {
      const result = await getApi(
        `ports?portType=${getPortTypeFromTransportMode(transport)}`,
      );

      if (result.ok) {
        return (await result.json()) as Port[];
      }

      return [];
    });

    const calculator = rate?.calculator ?? IfApplicableCalculatorType.FLT;

    const getDefaultValues = useCallback(
      (): IfApplicableFormData => ({
        infoId: rate?.infoId,
        chargeCode: rate?.chargeCode,
        origin: rate?.origin,
        originPort: rate?.originPort,
        destination: rate?.destination,
        destinationPort: rate?.destinationPort,
        comment: rate?.comment ?? '',
        breakpointFrom: rate?.breakpointFrom ?? undefined,
        breakpointTo: rate?.breakpointTo ?? undefined,
        minimum:
          calculator !== IfApplicableCalculatorType.FLT
            ? rate?.minimum ?? 0
            : undefined,
        amount: rate?.amount ?? 0,
        fourtyFeetContainerAmount: rate?.fourtyFeetContainerAmount ?? 0,
        fourtyFeetHCContainerAmount: rate?.fourtyFeetHCContainerAmount ?? 0,
        currency: rate?.currency,
        calculator: calculator,
        unitType: rate?.unitType,
        validFrom: rate?.validFrom ? new Date(rate?.validFrom) : new Date(),
        validTo: rate?.validTo
          ? new Date(rate?.validTo)
          : addDays(new Date(), 1),
      }),
      [calculator, rate],
    );

    const {
      control,
      handleSubmit,
      setValue,
      reset,
      register,
      formState: { errors },
      watch,
      getValues,
    } = useForm<IfApplicableFormData>({
      defaultValues: { ...getDefaultValues() },
    });

    const originPortRef = useRef<SelectInstance<Port[] | undefined> | null>(null);
    const destinationPortRef = useRef<SelectInstance<Port[] | undefined> | null>(null);

    const originCountry = watch('origin');
    const originPort = watch('originPort');
    const destinationCountry = watch('destination');
    const destinationPort = watch('destinationPort');

    useEffect(() => {
      reset({ ...getDefaultValues() });
    }, [getDefaultValues, reset, rate]);

    const currentCalculator = getValues('calculator');

    useEffect(() => {
      if (transport !== TransportMode.FCL) {
        return;
      }
      const prevCalculatorIsAmount = isIfApplicableAmountCalculator(
        previousCalulator ?? IfApplicableCalculatorType.FLT,
      );
      const currCalculatorIsAmount =
        isIfApplicableAmountCalculator(currentCalculator);

      if (prevCalculatorIsAmount !== currCalculatorIsAmount) {
        setValue('amount', 0);
      }

      setPreviousCalculator(currentCalculator);
      // we want to update the states only when the current state changes
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCalculator]);

    const onSubmit = async (submitData: IfApplicableFormData) => {
      setIsSubmitting(true);

      const data: Optional<IfApplicable, 'infoId'> = {
        infoId: submitData.infoId,
        amount: submitData.amount,
        fourtyFeetContainerAmount: submitData.fourtyFeetContainerAmount,
        fourtyFeetHCContainerAmount: submitData.fourtyFeetHCContainerAmount,
        breakpointFrom: submitData.breakpointFrom,
        breakpointTo: submitData.breakpointTo,
        calculator: submitData.calculator,
        chargeCode: submitData.chargeCode!,
        currency: submitData.currency!,
        minimum:
          calculator !== IfApplicableCalculatorType.FLT
            ? submitData?.minimum ?? 0
            : undefined,
        unitType: !isIfApplicableAmountCalculator(submitData.calculator)
          ? submitData.unitType
          : undefined,
        comment: submitData.comment,
        destination: submitData.destination?.countryID
          ? submitData.destination
          : undefined,
        destinationPort: submitData.destinationPort?.portID
          ? submitData.destinationPort
          : undefined,
        origin: submitData.origin?.countryID ? submitData.origin : undefined,
        originPort: submitData.originPort?.portID
          ? submitData.originPort
          : undefined,
        shippingType: transport,
        rateCountry: countries.data?.find(
          (country) => country.countryID === countryId,
        )!,
        isOrigin: category === 'Export',
        isDestination: category === 'Import',
        validFrom: submitData.validFrom,
        validTo: submitData.validTo,
      };

      const success = await onSave(data as IfApplicable);
      setIsSubmitting(false);
      if (success) {
        reset();
        onClose();
      }
    };

    const resetModal = () => {
      reset({ ...getDefaultValues() });
      onClose();
    };

    const handlePortChange = (input: SelectInputOption, fieldName: 'originPort' | 'destinationPort') => {
      let countryCode: string | undefined = undefined;
      let portCode: string | undefined = undefined;
      if(input?.value?.length === 5)
      {
        countryCode = input.value.substring(0,2);
        portCode = input.value.substring(2);
      }
        setValue(
          fieldName, ports?.find(
                (port) =>
                  port.unloCode === portCode && port.country.code === countryCode,
              ) ?? undefined );
    }

    return (
      <Modal isOpen={isOpen} onClose={resetModal}>
        <ModalOverlay />
        <ModalContent bg="grey.800" minWidth={{ base: '20rem', md: '37.5rem' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalCloseButton />
            <ModalHeader borderBottom="4px" borderColor="grey.900">
              <HStack justifyContent="center">
                <Heading fontSize="lg">
                  {isCreate ? 'Create Local Charge' : 'Edit Local Charge'}
                </Heading>
              </HStack>
            </ModalHeader>
            <ModalBody py="8" px={{ base: '7', md: '14' }}>
              <Stack spacing="6">
                <Stack w={{ base: 'full', lg: '50%' }}>
                  <SimpleGrid columns={2} rowGap="4">
                    <FormLabel mb="0">Country</FormLabel>
                    <Text fontSize="xs">
                      {
                        countries.data?.find(
                          (country) => country.countryID === countryId,
                        )?.name
                      }
                    </Text>
                    <FormLabel mb="0">Transport</FormLabel>
                    <Text fontSize="xs">{TransportMode[transport]}</Text>
                    <FormLabel mb="0">Category</FormLabel>
                    <Text fontSize="xs">
                      {category === 'Import' ? 'Destination' : 'Origin'}
                    </Text>
                  </SimpleGrid>
                </Stack>
                <Divider
                  borderColor="grey.900"
                  borderWidth="1px"
                  opacity="unset"
                />
                <Grid
                  gap={4}
                  templateColumns={{
                    base: 'repeat(1, 1fr)',
                    lg: 'repeat(2, 1fr)',
                  }}
                >
                  <GridItem>
                    <SelectFormInput
                      label="Charge Code"
                      accessor="chargeCode.code"
                      placeholder="Select Charge Code"
                      control={control}
                      isRequired={true}
                      defaultValue={
                        rate && rate.chargeCode.code !== ''
                          ? {
                              label: `${rate.chargeCode.code} - ${rate.chargeCode.name}`,
                              value: rate.chargeCode.code,
                            }
                          : undefined
                      }
                      onChange={(value) => {
                        setValue(
                          'chargeCode',
                          chargeCodes.data?.find(
                            (chargeCode) =>
                              (value as SelectInputOption).value ===
                              chargeCode.code,
                          ),
                        );
                      }}
                      options={
                        !chargeCodes.isLoading && chargeCodes.data
                          ? chargeCodes.data.map((chargeCode) => ({
                              label: `${chargeCode.code} - ${chargeCode.name}`,
                              value: chargeCode.code,
                            }))
                          : []
                      }
                    />
                  </GridItem>
                  <GridItem>
                    <SelectFormInput
                      label="Currency"
                      accessor="currency.code"
                      placeholder="Select Currency"
                      isRequired={true}
                      defaultValue={
                        rate && rate.currency.code !== ''
                          ? {
                              label: rate.currency.code,
                              value: rate.currency.code,
                            }
                          : undefined
                      }
                      controllerDefaultValue={rate?.currency.code}
                      onChange={(value) => {
                        setValue(
                          'currency',
                          currencies.data?.find(
                            (currency) =>
                              (value as SelectInputOption).value ===
                              currency.code,
                          ),
                        );
                      }}
                      control={control}
                      options={
                        !currencies.isLoading && currencies.data
                          ? currencies.data.map((currency) => ({
                              label: currency.code,
                              value: currency.code,
                            }))
                          : []
                      }
                    />
                  </GridItem>
                  <GridItem>
                    <SelectFormInput
                      label="Origin Country"
                      accessor="origin.code"
                      placeholder="Select Origin Country"
                      control={control}
                      isRequired={false}
                      isClearable={true}
                      defaultValue={
                        rate && rate.origin?.code != null
                          ? {
                              label: rate.origin?.name,
                              value: rate.origin?.code,
                            }
                          : undefined
                      }
                      onChange={(value) => {
                        if (value == null) {
                          setValue('origin', undefined);
                        }
                        setValue(
                          'origin',
                          countries.data?.find(
                            (country) =>
                              (value as SelectInputOption)?.value ===
                              country.code,
                          ),
                        );
                        if (
                          value !== null &&
                          originPort?.country?.countryID != null &&
                          (value as Country)?.countryID !==
                            originPort?.country?.countryID
                        ) {
                          originPortRef.current?.clearValue()
                        }
                      }}
                      options={
                        !countries.isLoading && countries.data
                          ? countries.data.map((country) => ({
                              label: country.name,
                              value: country.code,
                            }))
                          : []
                      }               
                    />
                  </GridItem>
                  <GridItem>
                    <SelectFormInput
                      label="Origin Port"
                      accessor="originPort.unloCode"
                      placeholder="Select Origin Port"
                      control={control}
                      isRequired={false}
                      isClearable={true}
                      defaultValue={
                        rate && rate.originPort?.unloCode != null
                          ? {
                              label: `${rate.originPort?.unloCode} - ${rate.originPort?.name}`,
                              value: rate.originPort?.unloCode,
                            }
                          : undefined
                      }
                      onChange={(value) => handlePortChange(value as SelectInputOption, 'originPort')}
                      options={
                        ports
                          ? ports
                              .filter(
                                (port) =>
                                  originCountry?.code == null ||
                                  port?.country == null ||
                                  port.country?.countryID ===
                                    originCountry?.countryID,
                              )
                              .map((port) => ({
                                label: `${port.unloCode} - ${port.name}`,
                                value: port.country.code+port.unloCode,
                              }))
                              .sort((a, b) => a.label < b.label ? -1 : 1)
                          : []
                      }
                      forwardRef={originPortRef}
                    />
                  </GridItem>
                  <GridItem>
                    <SelectFormInput
                      label="Destination Country"
                      accessor="destination.code"
                      placeholder="Select Destination Country"
                      control={control}
                      isRequired={false}
                      isClearable={true}
                      defaultValue={
                        rate && rate.destination?.code != null
                          ? {
                              label: rate.destination?.name,
                              value: rate.destination?.code,
                            }
                          : undefined
                      }
                      onChange={(value) => {
                        setValue(
                          'destination',
                          value
                            ? countries.data?.find(
                                (country) =>
                                  (value as SelectInputOption).value ===
                                  country.code,
                              )
                            : undefined,
                        );
                        if (
                          value !== null &&
                          destinationPort?.country?.countryID != null &&
                          (value as Country)?.countryID !==
                            destinationPort?.country?.countryID
                        ) {
                          destinationPortRef.current?.clearValue();
                        }
                      }}
                      options={
                        !countries.isLoading && countries.data
                          ? countries.data.map((country) => ({
                              label: country.name,
                              value: country.code,
                            }))
                          : []
                      }
                    />
                  </GridItem>
                  <GridItem>
                    <SelectFormInput
                      label="Destination Port"
                      accessor="destinationPort.unloCode"
                      placeholder="Select Destination Port"
                      control={control}
                      isRequired={false}
                      isClearable={true}
                      defaultValue={
                        rate && rate.destinationPort?.unloCode != null
                          ? {
                              label: `${rate.destinationPort?.unloCode} - ${rate.destinationPort?.name}`,
                              value: rate.destinationPort?.unloCode,
                            }
                          : undefined
                      }
                      onChange={(value) => handlePortChange(value as SelectInputOption, 'destinationPort')}
                      options={
                        ports
                          ? ports
                              .filter(
                                (port) =>
                                  destinationCountry?.code == null ||
                                  port.country == null ||
                                  port.country.countryID ===
                                    destinationCountry.countryID,
                              )
                              .map((port) => ({
                                label: `${port.unloCode} - ${port.name}`,
                                value: port.country.code+port.unloCode,
                              }))
                              .sort((a, b) => a.label < b.label ? -1 : 1)
                          : []
                      }
                      forwardRef={destinationPortRef}
                    />
                  </GridItem>
                  <GridItem>
                    <SelectFormInput
                      label="Calculator"
                      accessor="calculator"
                      placeholder="Select Calculator"
                      control={control}
                      isRequired={true}
                      defaultValue={
                        rate?.calculator
                          ? {
                              label: mapCalculatorHeader(rate.calculator),
                              value: rate.calculator,
                            }
                          : {
                              label: mapCalculatorHeader(
                                IfApplicableCalculatorType.FLT,
                              ),
                              value: IfApplicableCalculatorType.FLT,
                            }
                      }
                      onChange={(value: any) => {
                        setValue('calculator', value.value);
                      }}
                      options={Object.values(IfApplicableCalculatorType)
                        .filter((val) => !isNaN(Number(val)))
                        .map((val) => ({
                          label: mapCalculatorHeader(
                            val as IfApplicableCalculatorType,
                          ),
                          value: val,
                        }))}
                    />
                  </GridItem>
                  <GridItem>
                    {!isIfApplicableAmountCalculator(watch('calculator')) && (
                      <SelectFormInput
                        label="Unit"
                        accessor="unitType"
                        placeholder="Select Unit"
                        control={control}
                        isRequired={true}
                        defaultValue={
                          rate?.unitType
                            ? {
                                label: mapUnitHeader(rate.unitType),
                                value: rate.unitType,
                              }
                            : undefined
                        }
                        onChange={(value: any) => {
                          setValue('unitType', value?.value);
                        }}
                        options={Object.values(IfApplicableUnitType)
                          .filter(
                            (val) =>
                              !isNaN(Number(val)) &&
                              isUnitApplicableForTransportType(
                                val as IfApplicableUnitType,
                                transport,
                              ),
                          )
                          .map((val) => ({
                            label: mapUnitHeader(val as IfApplicableUnitType),
                            value: val,
                          }))
                          .sort((a, b) => a.label.localeCompare(b.label))}
                      />
                    )}
                  </GridItem>
                  {transport === TransportMode.Air && (
                    <>
                      <GridItem>
                        <NumberFormInput
                          label="Breakpoint from"
                          accessor="breakpointFrom"
                          control={control}
                          precision={2}
                          step={1.0}
                        />
                      </GridItem>
                      <GridItem>
                        <NumberFormInput
                          label="Breakpoint to"
                          accessor="breakpointTo"
                          control={control}
                          precision={2}
                          step={1.0}
                        />
                      </GridItem>
                    </>
                  )}
                  <GridItem>
                    {getValues('calculator') !==
                      IfApplicableCalculatorType.FLT && (
                      <NumberFormInput
                        label="Minimum"
                        accessor="minimum"
                        isRequired={true}
                        control={control}
                        precision={2}
                        step={1.0}
                      />
                    )}
                  </GridItem>

                  {transport === TransportMode.FCL &&
                  !isIfApplicableAmountCalculator(watch('calculator')) ? (
                    <>
                      <GridItem>
                        <NumberFormInput
                          label="20GP"
                          accessor="amount"
                          isRequired={true}
                          control={control}
                          precision={2}
                          step={1.0}
                        />
                      </GridItem>
                      <GridItem>
                        <NumberFormInput
                          label="40GP"
                          accessor="fourtyFeetContainerAmount"
                          isRequired={true}
                          control={control}
                          precision={2}
                          step={1.0}
                        />
                      </GridItem>
                      <GridItem>
                        <NumberFormInput
                          label="40HC"
                          accessor="fourtyFeetHCContainerAmount"
                          isRequired={true}
                          control={control}
                          precision={2}
                          step={1.0}
                        />
                      </GridItem>
                    </>
                  ) : (
                    <GridItem>
                      <NumberFormInput
                        label="Amount"
                        accessor="amount"
                        isRequired={true}
                        control={control}
                        precision={2}
                        step={1.0}
                        rightElement={
                          getValues('calculator') ===
                          IfApplicableCalculatorType.PER
                            ? '%'
                            : undefined
                        }
                      />
                    </GridItem>
                  )}
                  <GridItem>
                    <DateFormInput
                      label="Valid From"
                      accessor="validFrom"
                      isRequired={true}
                      onChange={(value) => {
                        const date = value ?? new Date();
                        setValue('validFrom', date);
                      }}
                      selected={watch('validFrom')}
                      control={control}
                    />
                  </GridItem>
                  <GridItem>
                    <DateFormInput
                      label="Valid To"
                      accessor="validTo"
                      validate={(value: number | string | Date) =>
                        watch('validFrom') === undefined ||
                        new Date(value).getTime() -
                          new Date(watch('validFrom')).getTime() >
                          0 ||
                        'Valid To must be after Valid From'
                      }
                      isRequired={true}
                      onChange={(value) => {
                        const date = value ?? new Date();
                        setValue('validTo', date);
                      }}
                      selected={watch('validTo')}
                      control={control}
                    />
                  </GridItem>

                  <GridItem colSpan={2}>
                    <TextareaFormInput
                      minHeight="20"
                      paddingY="2"
                      label="Comment"
                      register={register}
                      errors={errors}
                      accessor="comment"
                      registerOptions={{
                        maxLength: {
                          value: 1000,
                          message: 'Maximum of 1000 characters',
                        },
                      }}
                      />
                  </GridItem>
                </Grid>
              </Stack>
              {!isCreate && (
                <Button
                  w="100%"
                  mt="8"
                  py="8"
                  variant="ghost"
                  _hover={{ bgColor: 'grey.600' }}
                  colorScheme="white"
                  color="grey.400"
                  borderTop="1px solid grey.900"
                  borderBottom="1px solid grey.900"
                  borderRadius="0"
                  onClick={() => {
                    onDeleteOpen();
                  }}
                >
                  <DeleteIcon mr="2" />
                  <Box>Delete Rate</Box>
                </Button>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                mr={3}
                onClick={resetModal}
                colorScheme="white"
                variant="ghost"
                _hover={{ bgColor: 'grey.600' }}
              >
                Cancel
              </Button>
              <Button type="submit" colorScheme="blue" isLoading={isSubmitting}>
                Save
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    );
  };
