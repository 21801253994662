import { Column } from 'react-table';
import { LocalChargeFormValues } from '../../../components/RateTable/UpsertRateModal/LocalChargeFields';
import {
  SeaLclCharge,
  SeaFclCharge,
  AirCharge,
  LocalCharge,
  TransportMode,
} from '../../../types';
import {
  AirChargeFormValues,
  AirChargeResponse,
} from '../../ChargesPage/AirPage';
import {
  SeaFclChargeFormValues,
  SeaFclChargeResponse,
} from '../../ChargesPage/FclPage';
import {
  SeaLclChargeFormValues,
  SeaLclChargeResponse,
} from '../../ChargesPage/LclPage';
import { LocalChargeResponse } from '../../ChargesPage/LocalPage';
import { formatChargeDate } from '../../ChargesPage/utils/dateUtils';

/**
 * Columns
 */

export const columnsLcl: Column<SeaLclCharge>[] = [
  {
    Header: 'Origin',
    accessor: (rate) => `${rate.origin?.country?.code}${rate.origin?.unloCode}`,
  },
  {
    Header: 'Destination',
    accessor: (rate) =>
      `${rate.destination?.country?.code}${rate.destination?.unloCode}`,
  },
];

export const columnsFcl: Column<SeaFclCharge>[] = [
  {
    Header: 'Origin',
    accessor: (rate) => `${rate.origin?.country?.code}${rate.origin?.unloCode}`,
  },
  {
    Header: 'Destination',
    accessor: (rate) =>
      `${rate.destination?.country?.code}${rate.destination?.unloCode}`,
  },
];

export const columnsAir: Column<AirCharge>[] = [
  {
    Header: 'Origin',
    accessor: (rate) => `${rate.origin?.iataCode}`,
  },
  {
    Header: 'Destination',
    accessor: (rate) =>
      `${rate.destination?.iataCode}`,
  },
];

export const columnsLocal: Column<LocalCharge>[] = [
  {
    Header: 'Country',
    accessor: (rate) => rate.country?.code,
  },
  {
    Header: 'Transport Type',
    accessor: (rate) =>  TransportMode[rate.transportMode],
  },
  {
    Header: 'Category',
    accessor: (rate) => (rate.isOrigin ? 'Origin' : 'Destination'),
  },
  {
    Header: 'Port',
    accessor: (rate) => rate.port?.unloCode,
  },
  {
    Header: 'Code',
    accessor: (rate) => rate.chargeCode?.code,
  },
];

/**
 * Create Update Functions
 */

export const createUpdateRateLcl = (
  submitData: SeaLclChargeFormValues,
  rate?: SeaLclCharge,
): SeaLclChargeResponse => ({
  ...rate!,
  origin: submitData.origin!,
  destination: submitData.destination,
  currency: submitData.currency,
  minimumCharge: submitData.min,
  perCbm: submitData.perCbm,
  per1000kg: submitData.per1000kg,
  validFrom: formatChargeDate(submitData.validFrom),
  validTo: formatChargeDate(submitData.validTo),
});

export const createUpdateRateFcl = (
  submitData: SeaFclChargeFormValues,
  rate?: SeaFclCharge,
): SeaFclChargeResponse => ({
  ...rate!,
  origin: submitData.origin!,
  destination: submitData.destination,
  currency: submitData.currency,
  per20GP: submitData.per20GP,
  per40GP: submitData.per40GP,
  per40HC: submitData.per40HC,
  validFrom: formatChargeDate(submitData.validFrom),
  validTo: formatChargeDate(submitData.validTo),
});

export const createUpdateRateAir = (
  submitData: AirChargeFormValues,
  rate?: AirCharge,
): AirChargeResponse => ({
  ...rate!,
  origin: submitData.origin!,
  destination: submitData.destination,
  currency: submitData.currency,
  minimumCharge: submitData.min,
  above100kg: submitData.above100kg,
  above300kg: submitData.above300kg,
  above500kg: submitData.above500kg,
  above1000kg: submitData.above1000kg,
  below100kg: submitData.below100kg,
  validFrom: formatChargeDate(submitData.validFrom),
  validTo: formatChargeDate(submitData.validTo),
});

export const createUpdateRateLocal = (
  submitData: LocalChargeFormValues,
  rate?: LocalCharge,
): LocalChargeResponse => {
  const updatedRate: LocalChargeResponse = {
    ...rate!,
    transportMode: rate!.transportMode,
    chargeCode: submitData.chargeCode!,
    port: submitData.port && submitData.port.portID ? submitData.port : null,
    currency: submitData.currency,
    flat: submitData.flat ? parseFloat(submitData.flat) : null,
    validFrom: formatChargeDate(submitData.validFrom),
    validTo: formatChargeDate(submitData.validTo),
  };
  if (rate?.transportMode === TransportMode.FCL) {
    updatedRate.ratePer20FeetContainer = submitData.ratePer20FeetContainer
      ? parseFloat(submitData.ratePer20FeetContainer)
      : null;
    updatedRate.ratePer40FeetContainer = submitData.ratePer40FeetContainer
      ? parseFloat(submitData.ratePer40FeetContainer)
      : null;
    updatedRate.ratePer40HCFeetContainer = submitData.ratePer40HCFeetContainer
      ? parseFloat(submitData.ratePer40HCFeetContainer)
      : null;
  } else {
    updatedRate.minimumCharge = submitData.min
      ? parseFloat(submitData.min)
      : null;
    updatedRate.ratePerKg = submitData.ratePerKg
      ? parseFloat(submitData.ratePerKg)
      : null;
  }

  if (rate?.transportMode === TransportMode.LCL) {
    updatedRate.ratePerCbm = submitData.ratePerCbm
      ? parseFloat(submitData.ratePerCbm)
      : null;
  }
  return updatedRate;
};
