import { Button, Flex, Text } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import { HeadingCard } from '../../components/Layout/HeadingCard';
import { useAuthentication } from '../../providers/AuthenticationProvider';

export enum DeactivationType {
  Account = 'Account',
  Organization = 'Organization',
}

interface IDeactivatedPageProps {
  deactivationType?: DeactivationType;
}

export const DeactivatedPage: FunctionComponent<IDeactivatedPageProps> = ({
  deactivationType = DeactivationType.Account,
}) => {
  const { client } = useAuthentication();

  return (
    <Flex flexDir="column" justifyContent="center" alignItems="Center" h="full">
      <HeadingCard
        heading="Deactivated"
        display="flex"
        alignItems="center"
        maxW="30rem"
      >
        <Text py="8">
          Your {deactivationType} has been deactivated. If this shouldn't be the
          case, please contact your Region Manager, Organization Manager or
          Administrator.
        </Text>
        <Button colorScheme="red" onClick={() => client?.logout()}>
          Logout
        </Button>
      </HeadingCard>
    </Flex>
  );
};
