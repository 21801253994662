import { Heading, StackDivider, Stack, Box } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { QuoteNotification, QuoteNotificationType } from '../../types';
import { getNotificationColor } from '../../utils/model/getNotificationColor';
import { Card } from '../Layout';

interface NotificationBoxProps {
  type: QuoteNotificationType;
  typeNotifications: QuoteNotification[];
}

export const NotificationBox: FunctionComponent<NotificationBoxProps> = ({
  type,
  typeNotifications,
}) => (
  <Card
    border="1px solid"
    borderColor={`${getNotificationColor(type)} !important`}
    padding="8"
  >
    <Heading as="h2" fontSize="lg" flex="1" textAlign="left">
      Notifications
    </Heading>

    <Stack divider={<StackDivider />} spacing="4">
      {typeNotifications.map((notification) => (
        <Box key={notification.highlight}>{notification.message}</Box>
      ))}
    </Stack>
  </Card>
);
