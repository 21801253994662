import { GridItem, HStack, Stack } from '@chakra-ui/layout';
import { Button, Grid, Skeleton, Text } from '@chakra-ui/react';
import { FunctionComponent, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { EditIcon } from '../../components/Icons';
import { MainCard, HeadingCard } from '../../components/Layout';
import { ErrorResult } from '../../components/Result';
import { useApi } from '../../providers/ApiProvider';
import { useAuthentication } from '../../providers/AuthenticationProvider';
import type { User } from '../../types';
import { routes } from '../../utils/routes';
import { InfoItem } from './InfoItem';

export const AccountPage: FunctionComponent = () => {
  const { client } = useAuthentication();
  const { getApi } = useApi();
  const [statusCode, setStatusCode] = useState<number>(0);

  const { isLoading: isLoadingUser, data: user } = useQuery<User>(
    'user',
    async () => {
      const result = await getApi('users/me');
      setStatusCode(result.status);
      if (result.ok) return result.json();
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const content = useMemo(() => {
    if (isLoadingUser) {
      return <Skeleton height="200px" />;
    }

    if (user == null) {
      return <ErrorResult statusCode={statusCode} />;
    }

    return (
      <Grid
        templateColumns={{ base: 'repeat(1, auto)', md: 'repeat(2, 1fr)' }}
        gap={2}
      >
        <GridItem>
          <Grid templateColumns="repeat(2, 1fr)" gap={2}>
            <InfoItem title="Name" value={user?.name ?? '-'} />
            <InfoItem title="Email" value={user?.email ?? '-'} />
            <InfoItem title="Country" value={user?.userCountry?.name ?? '-'} />
            <InfoItem
              title="Organization"
              value={user?.organization?.name ?? '-'}
            />
          </Grid>
        </GridItem>
        <GridItem>
          <Grid templateColumns="repeat(2, 1fr)" gap={2}>
            <InfoItem
              title="Assigned countries"
              value={
                user?.assignedCountries.length === 0
                  ? 'None'
                  : user?.assignedCountries.map((country) => (
                      <Text key={country.code}>{country.name}</Text>
                    ))
              }
            />
            <InfoItem title="Role" value={user.role.name} />
          </Grid>
        </GridItem>
      </Grid>
    );
  }, [isLoadingUser, statusCode, user]);

  return (
    <Stack>
      <HeadingCard
        heading="Your Account"
        direction="row"
        align="center"
        justify="space-between"
      >
        <HStack>
          <Button
            as={Link}
            to={routes.userManagement.edit(user?.userID!)}
            state={{ isAccountEdit: true }}
            colorScheme="secondary"
            size="xs"
            rightIcon={<EditIcon w="5" h="5" />}
          >
            Edit
          </Button>
          <Button colorScheme="red" size="xs" onClick={() => client?.logout()}>
            Logout
          </Button>
        </HStack>
      </HeadingCard>
      <MainCard heading="Your Information" spacing="4">
        {content}
      </MainCard>
    </Stack>
  );
};
