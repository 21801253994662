import { ComponentStyleConfig } from '@chakra-ui/react';

export const Button: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: '4',
  },
  variants: {
    switch: {
      background: 'black',
      padding: '0 5px',
      _hover: { background: 'grey.600' },
      _focus: { background: 'grey.600' },
      color: 'white',
    },
    outline: {
      _hover: { background: 'grey.600' },
      _active: { background: 'grey.600' },
      color: 'blue.500',
      border: '1px',
      borderColor: 'blue.500',
    },
    showMore: {
      padding: '0px',
      height: '15px',
      margin: '0px',
      color: 'blue.500',
      fontSize: '0.875rem',
      _hover: { background: 'grey.600' },
      border: '0px',
      _active: { background: 'grey.600' },
    },
  },
};

export const TableActionButtonProps = {
  colorScheme: 'blue',
  variant: 'ghost',
  size: 'xs',
  height: 'full',
  _hover: { backgroundColor: 'none' },
  _active: { backgroundColor: 'none' },
};
