import { chakra, HTMLChakraProps } from '@chakra-ui/system';

export const CloseIcon = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg viewBox="0 0 16 16" {...props}>
    <g
      id="Icons/Small/Close"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M8.7765576,2.5 L8.784,7.213 L13.4974361,7.2054953 L13.5,8.7765576 L8.786,8.784 L8.7945047,13.4974361 L7.2234424,13.5 L7.215,8.786 L2.50256387,8.7945047 L2.5,7.2234424 L7.213,7.215 L7.2054953,2.50256387 L8.7765576,2.5 Z"
        id="Combined-Shape"
        fill="currentColor"
        fillRule="nonzero"
        transform="translate(8.000000, 8.000000) rotate(-315.000000) translate(-8.000000, -8.000000) "
      ></path>
    </g>
  </chakra.svg>
);
