import { Stack, Button, Flex, Heading } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { Column } from 'react-table';
import { DataTable } from '../../components/DataTable';
import { EditIcon, PlusIcon } from '../../components/Icons';
import { Card, HeadingCard } from '../../components/Layout';
import { useApi } from '../../providers/ApiProvider';
import { TableActionButtonProps } from '../../theme/components';
import { Authentication } from '../../types';
import { routes } from '../../utils/routes';

export const ThirdPartyApiPage: FunctionComponent = () => {
  const { getApi } = useApi();
  const [statusCode, setStatusCode] = useState<number>(0);
  const [fetchFailed, setFetchFailed] = useState<boolean>(false);

  const { isLoading, data } = useQuery<Authentication[]>(
    ['authentications'],
    async () => {
      setFetchFailed(false);
      const result = await getApi(`authentications`);
      setStatusCode(result.status);
      if (result.ok) return result.json();
      setFetchFailed(true);
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const columns = useMemo<Column<Authentication>[]>(
    () => [
      {
        Header: 'API Name',
        accessor: (auth) => auth.thirdParty.name,
      },
      {
        Header: 'Country',
        accessor: (auth) => auth.country?.name ?? 'World Wide',
      },
      {
        Header: '',
        id: 'action',
        accessor: (auth) => (
          <Flex w="100%" justifyContent="flex-end">
            <Button
              as={RouterLink}
              to={routes.thirdPartyApi.edit(auth.authenticationID)}
              {...TableActionButtonProps}
              rightIcon={<EditIcon w="4" h="4" />}
            >
              Edit
            </Button>
          </Flex>
        ),
      },
    ],
    [],
  );

  return (
    <Stack>
      <HeadingCard
        heading="Third Party API Management"
        direction="row"
        align="center"
        justify="space-between"
      >
        <Button
          as={RouterLink}
          to={routes.thirdPartyApi.add}
          colorScheme="secondary"
          size="xs"
          rightIcon={<PlusIcon w="5" h="5" />}
        >
          Add Third Party API
        </Button>
      </HeadingCard>
      <Card spacing="7">
        <Heading as="h2" variant="h2">
          Third Party APIs ({data?.length ?? 0})
        </Heading>
        <DataTable<Authentication>
          data={data ?? []}
          isLoading={isLoading}
          columns={columns}
          fetchFailed={fetchFailed}
          statusCode={statusCode}
          variant="sticky"
        />
      </Card>
    </Stack>
  );
};
