import { Text } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { WarningBox } from './WarningBox';

export const OrgSettingsWarningBox: FunctionComponent = () => (
  <WarningBox>
    <Text>
      Instant quotations are automatically generated up to these dimensions/
      weights. Anything larger or heavier remains a manual quotation.
    </Text>
  </WarningBox>
);
